import React from "react";
import {
  Box,
  Container,
  Typography,
  Grid,
  TextField,
  Button,
  Autocomplete,
} from "@mui/material";
import SideBars from "../../../components/SideBars";
import "../../../styles/report/transactionHistoryStyle/TransactionHistoryStyle.css";
import PaginationTrxHistory from "./components/PaginationTrxHistory";
import TableTrxHistory from "./components/TableTrxHistory";
import ModalTrxHistory from "./components/ModalTrxHistory";
import { TrxHistoryLogic } from "./logic/TrxHistoryLogic";

const TransactionHistory = () => {
  const {
    handleChange,
    handleChangePage,
    handleChangeTakePage,
    handleClose,
    handleExportToExcel,
    handleSearch,
    handleViewOddo,
    handleChangeEntity,
    handleChangeDiv,
    handleChangeDept,
    handleChangeSec,
    handleChangeCC,
    handleChangeVehicle,
    dataCostCenter,
    dataDepartment,
    dataDivision,
    dataEntity,
    dataOdo,
    dataSection,
    dataTrxHistory,
    dataVehicle,
    open,
    filterApi,
    takePage,
    page,
  } = TrxHistoryLogic();

  if (!dataTrxHistory.data) {
    return null;
  }

  return (
    <>
      <Box>
        <SideBars />

        <Container maxWidth="xl" sx={{ marginBottom: "150px" }}>
          <h3 className="title-page-fr">Transaction History</h3>
          <Box
            sx={{
              padding: "30px",
              borderRadius: "10px",
              border: "3px solid #e9e7e7",
            }}
          >
            <Grid container spacing={3}>
              <Grid item lg sx={{ minWidth: "200px" }}>
                <Typography sx={{ fontSize: "16px" }}>Entity</Typography>

                <Autocomplete
                  disablePortal
                  options={dataEntity}
                  getOptionLabel={(dataEntity) =>
                    dataEntity.name === undefined ? "" : dataEntity.name
                  }
                  onChange={(_, value) => handleChangeEntity(value)}
                  size="small"
                  fullWidth
                  renderInput={(params) => (
                    <TextField {...params} label="entity" />
                  )}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                />
              </Grid>
              <Grid item lg sx={{ minWidth: "200px" }}>
                <Typography sx={{ fontSize: "16px" }}>Division</Typography>

                <Autocomplete
                  disablePortal
                  options={dataDivision}
                  getOptionLabel={(dataDivision) =>
                    dataDivision.divisiNama === undefined
                      ? ""
                      : dataDivision.divisiNama
                  }
                  onChange={(_, value) => handleChangeDiv(value)}
                  size="small"
                  fullWidth
                  renderInput={(params) => (
                    <TextField {...params} label="division" />
                  )}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                />
              </Grid>

              <Grid item lg sx={{ minWidth: "200px" }}>
                <Typography sx={{ fontSize: "16px" }}>Department</Typography>

                <Autocomplete
                  disablePortal
                  options={dataDepartment}
                  getOptionLabel={(dataDepartment) =>
                    dataDepartment.deptName === undefined
                      ? ""
                      : dataDepartment.deptName
                  }
                  onChange={(_, value) => handleChangeDept(value)}
                  size="small"
                  fullWidth
                  renderInput={(params) => (
                    <TextField {...params} label="department" />
                  )}
                />
              </Grid>

              <Grid item lg sx={{ minWidth: "200px" }}>
                <Typography sx={{ fontSize: "16px" }}>Section</Typography>

                <Autocomplete
                  disablePortal
                  options={dataSection}
                  getOptionLabel={(dataSection) =>
                    dataSection.sectionName === undefined
                      ? ""
                      : dataSection.sectionName
                  }
                  onChange={(_, value) => handleChangeSec(value)}
                  size="small"
                  fullWidth
                  renderInput={(params) => (
                    <TextField {...params} label="section" />
                  )}
                />
              </Grid>

              <Grid item lg sx={{ minWidth: "200px" }}>
                <Typography sx={{ fontSize: "16px" }}>Cost Center</Typography>

                <Autocomplete
                  disablePortal
                  options={dataCostCenter}
                  getOptionLabel={(dataCostCenter) =>
                    dataCostCenter.costCenterName === undefined
                      ? ""
                      : dataCostCenter.costCenterName
                  }
                  onChange={(_, value) => handleChangeCC(value)}
                  size="small"
                  fullWidth
                  renderInput={(params) => (
                    <TextField {...params} label="cost center" />
                  )}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                />
              </Grid>
            </Grid>

            <Grid container spacing={3} sx={{ marginTop: "5px" }}>
              <Grid item lg sx={{ minWidth: "200px" }}>
                <Typography sx={{ fontSize: "16px" }}>Unit Number</Typography>

                <Autocomplete
                  disablePortal
                  options={dataVehicle}
                  getOptionLabel={(dataVehicle) =>
                    dataVehicle.unitNum === undefined ? "" : dataVehicle.unitNum
                  }
                  onChange={(_, value) => handleChangeVehicle(value)}
                  size="small"
                  fullWidth
                  renderInput={(params) => (
                    <TextField {...params} label="unit number" />
                  )}
                />
              </Grid>

              <Grid item lg sx={{ minWidth: "200px" }}>
                <Typography sx={{ fontSize: "16px" }}>
                  Start Date (Request)
                </Typography>

                <TextField
                  variant="outlined"
                  size="small"
                  type="date"
                  value={filterApi.startDate}
                  name="startDate"
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
              <Grid item lg sx={{ minWidth: "200px" }}>
                <Typography sx={{ fontSize: "16px" }}>
                  End Date (Request)
                </Typography>

                <TextField
                  variant="outlined"
                  size="small"
                  type="date"
                  value={filterApi.endDate}
                  name="endDate"
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>

              <Grid item lg sx={{ minWidth: "200px" }}>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "#084298",
                    fontSize: "16px",
                    textTransform: "none",
                    marginTop: 3,
                  }}
                  onClick={() =>
                    handleSearch(
                      filterApi.entity,
                      filterApi.divisi,
                      filterApi.dept,
                      filterApi.section,
                      filterApi.cost,
                      filterApi.unit,
                      filterApi.startDate,
                      filterApi.endDate
                    )
                  }
                  fullWidth
                >
                  Show Data
                </Button>
              </Grid>
              <Grid item lg sx={{ minWidth: "200px" }}>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "#4DD4AC",
                    fontSize: "16px",
                    textTransform: "none",
                    marginTop: 3,
                  }}
                  fullWidth
                  onClick={() => handleExportToExcel()}
                >
                  Export Excel
                </Button>
              </Grid>
            </Grid>
          </Box>

          <Box
            sx={{
              borderBottom: "1px solid #E0E0E0",
              marginTop: "20px",
              marginBottom: "20px",
            }}
          ></Box>

          <Box>
            <TableTrxHistory
              handleViewOddo={handleViewOddo}
              takePage={takePage}
              page={page}
              dataTrxHistory={dataTrxHistory}
            />
            <ModalTrxHistory
              dataOdo={dataOdo}
              handleClose={handleClose}
              open={open}
            />
          </Box>

          <Box sx={{ display: "flex", flexWrap: "wrap", marginTop: "20px" }}>
            <PaginationTrxHistory
              handleChangeTakePage={handleChangeTakePage}
              handleChangePage={handleChangePage}
              takePage={takePage}
              page={page}
              dataTrxHistory={dataTrxHistory}
            />
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default TransactionHistory;
