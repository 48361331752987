import axios from "axios";

const getAllRequest = async (limit = 10, page = 1, trxNum = "") => {
  const storedData = localStorage.getItem("userData");
  const userData = JSON.parse(storedData);
  const token = !userData ? "" : userData.token;

  const request = await axios({
    method: "get",
    url: `${process.env.REACT_APP_URL_TCM}/api/ebbm-request/dataFilters?limit=${limit}&page=${page}&TrxNum=${trxNum}`,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return request;
};

const newRequestService = async (form) => {
  const json = JSON.stringify(form);
  const storedData = localStorage.getItem("userData");
  const userData = JSON.parse(storedData);
  const token = !userData ? "" : userData.token;

  const request = await axios.post(
    `${process.env.REACT_APP_URL_TCM}/api/ebbm-request/new`,
    json,
    {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return request;
};

const sendNotifReqService = async (trxCode, unitNum, sections) => {
  const json = JSON.stringify();
  const storedData = localStorage.getItem("userData");
  const userData = JSON.parse(storedData);
  const token = !userData ? "" : userData.token;

  const request = await axios.post(
    `${process.env.REACT_APP_URL_TCM}/api/email/approve-request-spv?trxCode=${trxCode}&unitNum=${unitNum}&sections=${sections}`,
    json,
    {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return request;
};

const getEntityUserService = async () => {
  const storedData = localStorage.getItem("userData");
  const userData = JSON.parse(storedData);
  const token = !userData ? "" : userData.token;

  const request = await axios({
    method: "get",
    url: `${process.env.REACT_APP_URL_TCM}/api/user-authorization/entityGetFilter`,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  return request;
};

const getDivByEntityUserService = async (idEntity) => {
  const storedData = localStorage.getItem("userData");
  const userData = JSON.parse(storedData);
  const token = !userData ? "" : userData.token;

  const division = await axios({
    method: "get",
    url: `${process.env.REACT_APP_URL_TCM}/api/user-authorization/divisiFilterGet/${idEntity}`,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  return division;
};

const getDeptByDivUserService = async (idDiv) => {
  const storedData = localStorage.getItem("userData");
  const userData = JSON.parse(storedData);
  const token = !userData ? "" : userData.token;

  const department = await axios({
    method: "get",
    url: `${process.env.REACT_APP_URL_TCM}/api/user-authorization/departemenFilterGet/${idDiv}`,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  return department;
};

const getSectionByDeptUserService = async (idDept) => {
  const storedData = localStorage.getItem("userData");
  const userData = JSON.parse(storedData);
  const token = !userData ? "" : userData.token;

  const section = await axios({
    method: "get",
    url: `${process.env.REACT_APP_URL_TCM}/api/user-authorization/sectionFilterGet/${idDept}`,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  return section;
};

const getCostCenterBySectionUserService = async (idSection) => {
  const storedData = localStorage.getItem("userData");
  const userData = JSON.parse(storedData);
  const token = !userData ? "" : userData.token;

  const costCenter = await axios({
    method: "get",
    url: `${process.env.REACT_APP_URL_TCM}/api/cost-center/bySection/${idSection}`,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  return costCenter;
};

export {
  getAllRequest,
  newRequestService,
  sendNotifReqService,
  getEntityUserService,
  getDivByEntityUserService,
  getDeptByDivUserService,
  getSectionByDeptUserService,
  getCostCenterBySectionUserService
};
