const initialState = {
  dataDepartment: [],
};

const GetDepartment = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ALL_DEPARTMENT":
      return { ...state, dataDepartment: action.data };

    default:
      return state;
  }
};

export default GetDepartment;
