import { combineReducers } from "redux";
import GetUsersReducer from "./userReducer/index";
import GetProvidersReducer from "./providerReducer/index";
import GetCostCentersReducer from "./costCenterReducer/index";
import GetDepartmentReducer from "./departmentReducer/index";
import GetDivisionsReducer from "./divisionReducer/index";
import GetEntityReducer from "./entityReducer/index";
import GetLocationsReducer from "./locationReducer/index";
import GetVehiclesReducer from "./vehicleReducer/index";
import GetVehicleTypesReducer from "./vehicleTypeReducer/index";
import GetApprovalReducer from "./approvalReducer/index";
import GetSectionReducer from "./sectionReducer/index";
import GetMenusReducer from "./menuReducer/index";


const rootReducer = combineReducers({
  rGetAllUsers: GetUsersReducer,
  rGetAllProviders: GetProvidersReducer,
  rGetAllCostCenters: GetCostCentersReducer,
  rGetAllDepartments: GetDepartmentReducer,
  rGetAllDivisions: GetDivisionsReducer,
  rGetAllEntity: GetEntityReducer,
  rGetAllLocations: GetLocationsReducer,
  rGetAllVehicles: GetVehiclesReducer,
  rGetAllVehicleTypes: GetVehicleTypesReducer,
  rGetAllApproval: GetApprovalReducer,
  rGetAllSection: GetSectionReducer,
  rGetAllMenus: GetMenusReducer,
});

export default rootReducer;
