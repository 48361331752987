import axios from "axios";

const getAllLogAuthService = async (
  take = 10,
  page = 1,
  startDate = "",
  endDate = "",
  email = "",
  information = ""
) => {
  const storedData = localStorage.getItem("userData");
  const userData = JSON.parse(storedData);
  const token = !userData ? "" : userData.token;

  const request = await axios({
    method: "get",
    url: `${process.env.REACT_APP_URL_TCM}/api/LogAuth?take=${take}&page=${page}&startDate=${startDate}&endDate=${endDate}&email=${email}&information=${information}`,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return request;
};

export { getAllLogAuthService };
