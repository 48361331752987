import axios from "axios";

const getAllEntity = async (take = 10, page = 1, entityName = "") => {
  const storedData = localStorage.getItem("userData");
  const userData = JSON.parse(storedData);
  const token = !userData ? "" : userData.token;

  const entity = await axios({
    method: "get",
    url: `${process.env.REACT_APP_URL_TCM}/api/entity?take=${take}&page=${page}&entityName=${entityName}`,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return entity;
};

const newEntityService = async (form) => {
  const json = JSON.stringify(form);
  const storedData = localStorage.getItem("userData");
  const userData = JSON.parse(storedData);
  const token = !userData ? "" : userData.token;

  const providers = await axios.post(
    `${process.env.REACT_APP_URL_TCM}/api/entity/new`,
    json,
    {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return providers;
};

const deleteEntityService = async (id) => {
  const storedData = localStorage.getItem("userData");
  const userData = JSON.parse(storedData);
  const token = !userData ? "" : userData.token;

  const provider = await axios.delete(
    `${process.env.REACT_APP_URL_TCM}/api/entity/${id}`,
    {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return provider;
};

const updateEntityService = async (form, id) => {
  const json = JSON.stringify(form);
  const storedData = localStorage.getItem("userData");
  const userData = JSON.parse(storedData);
  const token = !userData ? "" : userData.token;

  const provider = await axios.put(
    `${process.env.REACT_APP_URL_TCM}/api/entity/update/${id}`,
    json,
    {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return provider;
};

export {
  getAllEntity,
  newEntityService,
  deleteEntityService,
  updateEntityService,
};
