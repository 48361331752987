import React from "react";
import RouterService from "./services/routerService/RouterService";
import FooterBar from "./components/FooterBar";

function App() {
  return (
    <>
      <RouterService />
      <FooterBar />
    </>
  );
}

export default App;
