import { useCallback, useEffect, useState } from "react";
import { getAllLogAuthService } from "../../../../services/apiService/apiReport/logAuth/ServiceApiLogAuth";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";

export const LogAuthLogic = () => {
  const navigation = useNavigate();
  const theme = useTheme();

  const fmt = new Intl.DateTimeFormat("fr-CA", {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  });

  const [currentDate, setCurrentDate] = useState(new Date());
  const [filterApi, setFilterApi] = useState({
    startDate: fmt.format(new Date(currentDate)),
    endDate: fmt.format(new Date(currentDate)),
    email: "",
    information: "",
  });
  const [takePage, setTakePage] = useState(10);
  const [page, setPage] = useState(1);
  const [dataLogAuth, setLogAuth] = useState([]);

  const handleChange = (event) => {
    setFilterApi({
      ...filterApi,
      [event.target.name]: event.target.value,
    });
  };

  const handleChangePage = (_, v) => {
    setPage(v);
  };

  const handleChangeTakePage = (e) => {
    setTakePage(e.target.value);
  };

  const loadData = useCallback(() => {
    getAllLogAuthService(
      takePage,
      page,
      filterApi.startDate,
      filterApi.endDate,
      filterApi.email,
      filterApi.information
    )
      .then((res) => {
        const result = res.data;
        setLogAuth(result);

        if (res.data.data.length === 0) {
          setPage(1);
        }
      })
      .catch((res) => {
        if (res.request.status === 401) {
          navigation("/");
        }
      });
  }, [filterApi, takePage, page, navigation]);

  useEffect(() => {
    loadData();
    setCurrentDate(new Date());
  }, [loadData]);

  const handleSearch = (startDate, endDate, email, information) => {
    setFilterApi((filterApi) => ({
      ...filterApi,
      startDate: startDate,
      endDate: endDate,
      email: email,
      information: information,
    }));
  };

  return {
    handleChange,
    handleChangePage,
    handleChangeTakePage,
    handleSearch,
    dataLogAuth,
    takePage,
    page,
    theme,
    filterApi,
  };
};
