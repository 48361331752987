import { useEffect, useState } from "react";
import {
  getAllParents,
  newMenuService,
} from "../../../../services/apiService/apiSetup/masterMenu/ServiceApiMenu";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

export const MenusCreateLogic = () => {
  const navigation = useNavigate();

  const storedData = localStorage.getItem("userData");
  const userData = JSON.parse(storedData);

  const [dataMenu, setDataMenu] = useState({
    menuName: "",
    menuDesc: "",
    menuSeq: 0,
    menuIdParent: "",
    slug: "",
    isParent: false,
    isActive: false,
    createdBy: userData.id,
  });
  const [isError, setIsError] = useState("");
  const [hideAlert, setHideAlert] = useState(false);
  const [disableBtn, setDisableBtn] = useState(false);
  const [dataParent, setDataParent] = useState([]);

  const handleChange = (event) => {
    setDataMenu({ ...dataMenu, [event.target.name]: event.target.value });
  };

  const handleChangeCheckbox = (event) => {
    setDataMenu({
      ...dataMenu,
      [event.target.name]: event.target.checked,
    });
  };

  const createMenus = () => {
    if (dataMenu.menuName === "") {
      setIsError("Menu name cannot be empty");
      setHideAlert(!hideAlert);
    } else if (dataMenu.menuSeq === "") {
      setIsError("Menu Index  cannot be empty");
      setHideAlert(!hideAlert);
    } else if (dataMenu.slug === "") {
      setIsError("URL cannot be empty");
      setHideAlert(!hideAlert);
    } else {
      dataMenu.isParent
        ? newMenuService({
            menuName: dataMenu.menuName,
            menuDesc: dataMenu.menuDesc,
            menuSeq: dataMenu.menuSeq,
            slug: dataMenu.slug,
            isParent: dataMenu.isParent,
            isActive: dataMenu.isActive,
            createdBy: dataMenu.createdBy,
          })
            .then(() => {
              Swal.fire("Successfully added data", "", "success");
              navigation("/master-menu");
            })
            .catch(() => {
              setIsError("Data was not added successfully");
              setHideAlert(!hideAlert);
            })
        : newMenuService({
            menuName: dataMenu.menuName,
            menuDesc: dataMenu.menuDesc,
            menuSeq: dataMenu.menuSeq,
            menuIdParent: dataMenu.menuIdParent,
            slug: dataMenu.slug,
            isParent: dataMenu.isParent,
            isActive: dataMenu.isActive,
            createdBy: dataMenu.createdBy,
          })
            .then(() => {
              Swal.fire("Successfully added data", "", "success");
              navigation("/master-menu");
            })
            .catch((res) => {
              if (res.response.data.statusCode === 422) {
                setIsError(res.response.data.message);
                setHideAlert(true);
              } else {
                setIsError("Data was not added successfully");
                setHideAlert(true);
              }
            });
    }
  };

  useEffect(() => {
    getAllParents()
      .then((res) => {
        const result = res.data;
        setDataParent(result);
      })
      .catch(() => {});

    if (
      dataMenu.menuName === "" ||
      dataMenu.slug === "" ||
      dataMenu.menuSeq === ""
    ) {
      setDisableBtn(true);
    } else {
      setDisableBtn(false);
    }
  }, [dataMenu]);

  return {
    disableBtn,
    dataMenu,
    dataParent,
    isError,
    hideAlert,
    navigation,
    createMenus,
    handleChangeCheckbox,
    handleChange,
  };
};
