import  { useEffect, useState } from "react";
import {
  getAllParents,
  updateMenusService,
} from "../../../../services/apiService/apiSetup/masterMenu/ServiceApiMenu";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

export const MenusUpdateLogic = (location) => {
  const navigation = useNavigate();

  const storedData = localStorage.getItem("userData");
  const userData = JSON.parse(storedData);

  const [dataMenu, setDataMenu] = useState({
    menuName: location.state.menuName,
    menuDesc: location.state.menuDesc,
    menuSeq: location.state.menuSeq,
    menuIdParent: location.state.menuIdParent,
    slug: location.state.slug,
    isParent: location.state.isParent,
    isActive: location.state.isActive,
    updatedBy: userData.id,
  });
  const [isError, setIsError] = useState("");
  const [hideAlert, setHideAlert] = useState(false);
  const [disableBtn, setDisableBtn] = useState(false);
  const [dataParent, setDataParent] = useState([]);

  const handleChange = (event) => {
    setDataMenu({
      ...dataMenu,
      [event.target.name]: event.target.value,
    });
  };

  const handleCheckbox = (event) => {
    setDataMenu({
      ...dataMenu,
      [event.target.name]: event.target.checked,
    });
  };

  const updateMenus = (
    {
      menuName,
      menuDesc,
      menuSeq,
      menuIdParent,
      slug,
      isParent,
      isActive,
      updatedBy,
    },
    id
  ) => {
    if (dataMenu.password !== dataMenu.passwordConfirmation) {
      setIsError("Password confirmation does not match the password");
      setHideAlert(!hideAlert);
    } else {
      dataMenu.isParent
        ? updateMenusService(
            {
              menuName: dataMenu.menuName === "" ? menuName : dataMenu.menuName,
              menuDesc: dataMenu.menuDesc === "" ? menuDesc : dataMenu.menuDesc,
              menuSeq: dataMenu.menuSeq === "" ? menuSeq : dataMenu.menuSeq,
              slug: dataMenu.slug === "" ? slug : dataMenu.slug,
              isParent: dataMenu.isParent === "" ? isParent : dataMenu.isParent,
              isActive: dataMenu.isActive === "" ? isActive : dataMenu.isActive,
              updatedBy:
                dataMenu.updatedBy === "" ? updatedBy : dataMenu.updatedBy,
            },
            id
          )
            .then(() => {
              Swal.fire("Data updated successfully", "", "success");
              navigation("/master-menu");
            })
            .catch(() => {
              setIsError("Data was not updated successfully");
              setHideAlert(!hideAlert);
            })
        : updateMenusService(
            {
              menuName: dataMenu.menuName === "" ? menuName : dataMenu.menuName,
              menuDesc: dataMenu.menuDesc === "" ? menuDesc : dataMenu.menuDesc,
              menuSeq: dataMenu.menuSeq === "" ? menuSeq : dataMenu.menuSeq,
              menuIdParent:
                dataMenu.menuIdParent === ""
                  ? menuIdParent
                  : dataMenu.menuIdParent,
              slug: dataMenu.slug === "" ? slug : dataMenu.slug,
              isParent: dataMenu.isParent === "" ? isParent : dataMenu.isParent,
              isActive: dataMenu.isActive === "" ? isActive : dataMenu.isActive,
              updatedBy:
                dataMenu.updatedBy === "" ? updatedBy : dataMenu.updatedBy,
            },
            id
          )
            .then(() => {
              Swal.fire("Data updated successfully", "", "success");
              navigation("/master-menu");
            })
            .catch((res) => {
              if (res.response.data.statusCode === 422) {
                setIsError(res.response.data.message);
                setHideAlert(true);
              } else {
                setIsError("Data was not updated successfully");
                setHideAlert(true);
              }
            });
    }
  };

  useEffect(() => {
    getAllParents()
      .then((res) => {
        const result = res.data;
        setDataParent(result);
      })
      .catch(() => {});

    if (
      dataMenu.menuName === "" ||
      dataMenu.slug === "" ||
      dataMenu.menuSeq === ""
    ) {
      setDisableBtn(true);
    } else {
      setDisableBtn(false);
    }
  }, [dataMenu]);

  return {
    disableBtn,
    dataMenu,
    dataParent,
    isError,
    hideAlert,
    navigation,
    updateMenus,
    handleChange,
    handleCheckbox,
  };
};
