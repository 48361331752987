const initialState = {
  dataApproval: [],
};

const GetApproval = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ALL_APPROVAL":
      return { ...state, dataApproval: action.data };

    default:
      return state;
  }
};

export default GetApproval;
