import axios from "axios";

const getAllMenu = async () => {
  const storedData = localStorage.getItem("userData");
  const userData = JSON.parse(storedData);
  const token = !userData ? "" : userData.token;

  const allMenu = await axios({
    method: "get",
    url: `${process.env.REACT_APP_URL_TCM}/api/menu/all`,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return allMenu;
};

const getAllParents = async (take = 10, page = 1, menuName = "") => {
  const storedData = localStorage.getItem("userData");
  const userData = JSON.parse(storedData);
  const token = !userData ? "" : userData.token;

  const allParents = await axios({
    method: "get",
    url: `${process.env.REACT_APP_URL_TCM}/api/menu/parent?take=${take}&page=${page}&menuName=${menuName}`,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return allParents;
};

const newMenuService = async (form) => {
  const json = JSON.stringify(form);
  const storedData = localStorage.getItem("userData");
  const userData = JSON.parse(storedData);
  const token = !userData ? "" : userData.token;

  const menus = await axios.post(
    `${process.env.REACT_APP_URL_TCM}/api/menu/new`,
    json,
    {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return menus;
};

const deleteMenusService = async (id) => {
  const storedData = localStorage.getItem("userData");
  const userData = JSON.parse(storedData);
  const token = !userData ? "" : userData.token;

  const menus = await axios.delete(
    `${process.env.REACT_APP_URL_TCM}/api/menu/${id}`,
    {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return menus;
};

const updateMenusService = async (form, id) => {
  const json = JSON.stringify(form);
  const storedData = localStorage.getItem("userData");
  const userData = JSON.parse(storedData);
  const token = !userData ? "" : userData.token;

  const menus = await axios.put(
    `${process.env.REACT_APP_URL_TCM}/api/menu/update/${id}`,
    json,
    {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return menus;
};

const getChildByParentService = async (id) => {
  const storedData = localStorage.getItem("userData");
  const userData = JSON.parse(storedData);
  const token = !userData ? "" : userData.token;

  const division = await axios({
    method: "get",
    url: `${process.env.REACT_APP_URL_TCM}/api/menu/byParent/${id}`,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return division;
};

const getMenuByIdRoleService = async (idRole) => {
  const storedData = localStorage.getItem("userData");
  const userData = JSON.parse(storedData);
  const token = !userData ? "" : userData.token;

  const menu = await axios({
    method: "get",
    url: `${process.env.REACT_APP_URL_TCM}/api/role-permission/role/${idRole}`,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  return menu;
};

export {
  getAllMenu,
  getAllParents,
  newMenuService,
  deleteMenusService,
  updateMenusService,
  getChildByParentService,
  getMenuByIdRoleService,
};
