import React, { useState, useEffect } from "react";
import {
  Container,
  Box,
  Typography,
  MenuItem,
  Select,
  FormControl,
  TextField,
  Checkbox,
  Button,
  Alert,
} from "@mui/material";
import { Row, Col } from "react-bootstrap";
import SideBars from "../../../components/SideBars";
import "../../../styles/setup/userStyle/UsernamesCreateFormStyle.css";
import { newUserService } from "../../../services/apiService/apiSetup/masterUser/ServiceApiUsername";
import { getAllRole } from "../../../services/apiService/apiRolePermission/ServiceRolePermission";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

const UsernamesCreateForm = () => {
  const navigation = useNavigate();

  const storedData = localStorage.getItem("userData");
  const userData = JSON.parse(storedData);

  const [newUser, setNewUser] = useState({
    nama: "",
    noTelp: "",
    email: "",
    role: "",
    password: "",
    confirmPassword: "",
    isAktif: false,
    isLdap: false,
    isSpv: false,
    createdBy: userData.id,
  });
  const [isError, setIsError] = useState("");
  const [isErrorPwd, setIsErrorPwd] = useState("");
  const [isErrorEmail, setIsErrorEmail] = useState("");
  const [hideAlert, setHideAlert] = useState(false);
  const [disableBtn, setDisableBtn] = useState(false);
  const [dataRole, setDataRole] = useState([]);

  const handleChange = (event) => {
    if (newUser.nama.includes(" ")) {
      setIsError("Usernames cannot contain spaces");
    }
    setNewUser({ ...newUser, [event.target.name]: event.target.value });
  };

  const handleChangeCheckbox = (event) => {
    setNewUser({
      ...newUser,
      [event.target.name]: event.target.checked,
    });
  };

  const handleChangePassword = (event) => {
    setNewUser({
      ...newUser,
      confirmPassword: event.target.value,
    });
    if (newUser.password !== event.target.value) {
      setIsErrorPwd("Password confirmation does not match the password");
    } else {
      setIsErrorPwd("");
    }
  };

  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }

  const handleChangeEmail = (event) => {
    if (!isValidEmail(event.target.value)) {
      setIsErrorEmail("Email invalid");
    } else {
      setIsErrorEmail("");
    }

    setNewUser({
      ...newUser,
      email: event.target.value,
    });
  };

  const createNewUsers = () => {
    if (newUser.password !== newUser.confirmPassword) {
      setIsError("Password confirmation does not match the password");
      setHideAlert(!hideAlert);
    } else if (newUser.nama === "") {
      setIsError("Username cannot be empty");
      setHideAlert(!hideAlert);
    } else if (newUser.nama.includes(" ")) {
      setIsError("Usernames cannot contain spaces");
      setHideAlert(!hideAlert);
    } else if (newUser.email === "" && newUser.isLdap === false) {
      setIsError("Email cannot be empty");
      setHideAlert(!hideAlert);
    } else if (newUser.password === "" && newUser.isLdap === false) {
      setIsError("Password cannot be empty");
      setHideAlert(!hideAlert);
    } else if (newUser.role === "") {
      setIsError("Role cannot be empty");
      setHideAlert(!hideAlert);
    } else if (isErrorEmail === "Email invalid") {
      setIsError("Enter your email correctly");
      setHideAlert(!hideAlert);
    } else {
      newUserService({
        nama: newUser.nama,
        noTelp: newUser.noTelp,
        email: newUser.isLdap
          ? `${newUser.nama}@banpuindo.co.id`
          : newUser.email,
        password: newUser.isLdap ? "-" : newUser.password,
        role: newUser.role,
        isAktif: newUser.isAktif,
        isLdap: newUser.isLdap,
        isSpv: newUser.isSpv,
        createdBy: newUser.createdBy,
      })
        .then(() => {
          Swal.fire("Successfully added data", "", "success");
          navigation("/master-user");
        })
        .catch((res) => {
          if (res.response.data.statusCode === 422) {
            setIsError(res.response.data.message);
            setHideAlert(true);
          } else {
            setIsError("Data was not added successfully");
            setHideAlert(true);
          }
        });
    }
  };

  useEffect(() => {
    getAllRole()
      .then((res) => {
        const result = res.data;
        setDataRole(result);
      })
      .catch(() => {});

    if (
      newUser.isLdap
        ? newUser.nama === "" || newUser.role === ""
        : newUser.nama === "" ||
          newUser.email === "" ||
          newUser.password === "" ||
          newUser.confirmPassword === "" ||
          newUser.role === ""
    ) {
      setDisableBtn(true);
    } else {
      setDisableBtn(false);
    }
  }, [newUser]);

  return (
    <>
      <Box>
        <SideBars />
        <Container maxWidth="lg" sx={{ marginBottom: "150px" }}>
          <h4 className="title-page-ccf">Form Master User</h4>
          {hideAlert && <Alert severity="error">{isError}</Alert>}
          <Box className="box-cost-center-form">
            <Box align="left">
              <Row>
                <Col sm={6}>
                  <Typography sx={{ fontSize: "16px", m: 1 }}>
                    Username <span style={{ color: "red" }}>*</span>
                  </Typography>
                </Col>
                <Col sm={6}>
                  <Typography sx={{ fontSize: "12px", color: "red" }}>
                    {isError}
                  </Typography>
                  <TextField
                    variant="outlined"
                    sx={{ width: "100%" }}
                    size="small"
                    name="nama"
                    onChange={handleChange}
                  />
                </Col>
              </Row>

              <br />

              <Row>
                <Col sm={6}>
                  <Typography sx={{ fontSize: "16px", m: 1 }}>
                    Phone Number
                  </Typography>
                </Col>
                <Col sm={6}>
                  <TextField
                    variant="outlined"
                    sx={{ width: "100%" }}
                    size="small"
                    name="noTelp"
                    type="number"
                    onChange={handleChange}
                  />
                </Col>
              </Row>

              <br />

              <Row
                style={newUser.isLdap ? { display: "none" } : { display: "" }}
              >
                <Col sm={6}>
                  <Typography sx={{ fontSize: "16px", m: 1 }}>
                    Email <span style={{ color: "red" }}>*</span>
                  </Typography>
                </Col>
                <Col sm={6}>
                  <Typography sx={{ fontSize: "12px", color: "red" }}>
                    {isErrorEmail}
                  </Typography>
                  <TextField
                    variant="outlined"
                    sx={{ width: "100%" }}
                    size="small"
                    name="email"
                    type="email"
                    onChange={handleChangeEmail}
                  />
                </Col>
              </Row>

              <br
                style={newUser.isLdap ? { display: "none" } : { display: "" }}
              />

              <Row
                style={newUser.isLdap ? { display: "none" } : { display: "" }}
              >
                <Col sm={6}>
                  <Typography sx={{ fontSize: "16px", m: 1 }}>
                    Password <span style={{ color: "red" }}>*</span>
                  </Typography>
                </Col>
                <Col sm={6}>
                  <TextField
                    variant="outlined"
                    sx={{ width: "100%" }}
                    size="small"
                    name="password"
                    type="password"
                    onChange={handleChange}
                  />
                </Col>
              </Row>

              <br
                style={newUser.isLdap ? { display: "none" } : { display: "" }}
              />

              <Row
                style={newUser.isLdap ? { display: "none" } : { display: "" }}
              >
                <Col sm={6}>
                  <Typography sx={{ fontSize: "16px", m: 1 }}>
                    Confirm Password <span style={{ color: "red" }}>*</span>
                  </Typography>
                </Col>
                <Col sm={6}>
                  <Typography sx={{ fontSize: "12px", color: "red" }}>
                    {isErrorPwd}
                  </Typography>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    sx={{ width: "100%" }}
                    size="small"
                    name="confirmPassword"
                    type="password"
                    onChange={handleChangePassword}
                  />
                </Col>
              </Row>

              <br
                style={newUser.isLdap ? { display: "none" } : { display: "" }}
              />

              <Row>
                <Col sm={6}>
                  <Typography sx={{ fontSize: "16px", m: 1 }}>
                    Role <span style={{ color: "red" }}>*</span>
                  </Typography>
                </Col>
                <Col sm={6}>
                  <FormControl fullWidth>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={newUser.role}
                      size="small"
                      name="role"
                      onChange={handleChange}
                    >
                      {dataRole.map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.roleName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Col>
              </Row>

              <br />

              <Row>
                <Col>
                  <Typography sx={{ fontSize: "16px", m: 1 }}>
                    Active
                  </Typography>
                </Col>
                <Col>
                  <Checkbox
                    sx={{ marginLeft: "-12px" }}
                    value={newUser.isAktif}
                    name="isAktif"
                    onChange={handleChangeCheckbox}
                  />
                </Col>
              </Row>

              <br />

              <Row>
                <Col>
                  <Typography sx={{ fontSize: "16px", m: 1 }}>Ldap</Typography>
                </Col>
                <Col>
                  <Checkbox
                    sx={{ marginLeft: "-12px" }}
                    value={newUser.isLdap}
                    name="isLdap"
                    onChange={handleChangeCheckbox}
                  />
                </Col>
              </Row>

              <br />

              <Row>
                <Col>
                  <Typography sx={{ fontSize: "16px", m: 1 }}>Approval</Typography>
                </Col>
                <Col>
                  <Checkbox
                    sx={{ marginLeft: "-12px" }}
                    value={newUser.isSpv}
                    name="isSpv"
                    onChange={handleChangeCheckbox}
                  />
                </Col>
              </Row>
            </Box>

            <br />

            <Box align="right">
              <Row>
                <Col>
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: "#436FB1",
                      marginTop: "10px",
                    }}
                    onClick={() => navigation("/master-user")}
                  >
                    Back To List
                  </Button>

                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: "#084298",
                      marginTop: "10px",
                      marginLeft: "10px",
                    }}
                    onClick={() => createNewUsers()}
                    disabled={disableBtn ? true : false}
                  >
                    Save
                  </Button>
                </Col>
              </Row>
            </Box>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default UsernamesCreateForm;
