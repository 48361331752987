import React from "react";
import {
  Grid,
  Box,
  Button,
  Table,
  styled,
  TableBody,
  TableRow,
  TableHead,
  TableContainer,
  Paper,
} from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Create";
import { useNavigate } from "react-router-dom";
import QRCode from "qrcode.react";
import * as htmlToImage from "html-to-image";
import BgQrCode from "../../../../assets/vehicleAssets/BgQrCode.png";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#8FD0EF",
    color: "#484848",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: "#F0F0F0",
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function TableVehicle(props) {
  const { deleteVehicle, dataVehicles, takePage, page } = props;
  const navigation = useNavigate();

  const downloadQrCode = (item) => {
    var image = document.getElementById(item.id);

    htmlToImage
      .toPng(image)
      .then((dataUrl) => {
        const link = document.createElement("a");
        link.download = "QrCode.png";
        link.href = dataUrl;
        link.click();
      })
      .catch(() => {});
  };

  const fmt = new Intl.DateTimeFormat("en-GB", {
    year: "2-digit",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  });

  return (
    <>
      <TableContainer component={Paper} className="table-container">
        <Table
          sx={{ minWidth: 700 }}
          aria-label="customized table"
          className="table-approval"
        >
          <TableHead>
            <TableRow>
              <StyledTableCell sx={{ fontWeight: "bold" }}>#</StyledTableCell>
              <StyledTableCell sx={{ fontWeight: "bold" }}>
                Actions
              </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: "bold" }} align="center">
                Qr Codes
              </StyledTableCell>
              <StyledTableCell></StyledTableCell>
              <StyledTableCell sx={{ fontWeight: "bold" }}>
                Entity
              </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: "bold" }}>
                Division
              </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: "bold" }}>
                Department
              </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: "bold" }}>
                Section
              </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: "bold" }}>
                Unit Number
              </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: "bold" }}>
                Type
              </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: "bold" }}>
                Plate Number
              </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: "bold" }}>
                Type of Unit
              </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: "bold" }}>
                Asset Number
              </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: "bold" }}>
                Active
              </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: "bold" }}>
                User Created
              </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: "bold" }}>
                User Updated
              </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: "bold" }}>
                Date Created
              </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: "bold" }}>
                Date Updated
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataVehicles.data.map((item, index) => (
              <StyledTableRow key={item.id}>
                <StyledTableCell>
                  {(page - 1) * takePage + index + 1}
                </StyledTableCell>
                <StyledTableCell sx={{ minWidth: "80px" }}>
                  <EditIcon
                    className="icon-edit"
                    onClick={() =>
                      navigation("/form-master-vehicle-update", {
                        state: item,
                      })
                    }
                  />
                  <DeleteIcon
                    className="icon-delete"
                    onClick={() => deleteVehicle(item.id)}
                  />
                </StyledTableCell>
                <StyledTableCell>
                  <Button
                    variant="contained"
                    size="small"
                    sx={{
                      backgroundColor: "#084298",
                      minWidth: "90px",
                      fontSize: "16px",
                      textTransform: "none",
                    }}
                    onClick={() => downloadQrCode(item)}
                  >
                    Download
                  </Button>
                </StyledTableCell>
                <StyledTableCell>
                  <Box
                    sx={{
                      position: "absolute",
                      top: "-9999px",
                    }}
                  >
                    <Grid
                      id={item.id}
                      sx={{
                        height: "320px",
                        width: "220px",
                        backgroundImage: `url(${BgQrCode})`,
                        backgroundSize: "cover",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <QRCode
                        value={`${item.id}, ${item.unitNum}, ${item.platNo}, ${item.assetNumber}`}
                        style={{ marginTop: "75px" }}
                      />
                      <div style={{ marginTop: "50px", textAlign: "center" }}>
                        <span
                          style={{
                            fontWeight: "bold",
                            fontSize: "16px",
                          }}
                        >
                          {item.unitNum}
                        </span>
                        <br />
                        <span
                          style={{
                            fontWeight: "bold",
                            fontSize: "16px",
                          }}
                        >
                          {item.assetNumber}
                        </span>
                      </div>
                    </Grid>
                  </Box>
                </StyledTableCell>
                <StyledTableCell>
                  {item.vehicleSectionId === null ||
                  item.vehicleSectionId.departemen.divisi.entity === null
                    ? "-"
                    : item.vehicleSectionId.departemen.divisi.entity.name}
                </StyledTableCell>
                <StyledTableCell>
                  {item.vehicleSectionId === null
                    ? "-"
                    : item.vehicleSectionId.departemen.divisi.divisiNama}
                </StyledTableCell>
                <StyledTableCell>
                  {item.vehicleSectionId === null
                    ? "-"
                    : item.vehicleSectionId.departemen.deptName}
                </StyledTableCell>
                <StyledTableCell>
                  {item.vehicleSectionId === null
                    ? "-"
                    : item.vehicleSectionId.sectionName}
                </StyledTableCell>
                <StyledTableCell>{item.unitNum}</StyledTableCell>
                <StyledTableCell>
                  {item.vehicleProviderId.providerName}
                </StyledTableCell>
                <StyledTableCell>{item.platNo}</StyledTableCell>
                <StyledTableCell>
                  {item.vehicleTypeId.vehicleTypeName}
                </StyledTableCell>
                <StyledTableCell>{item.assetNumber}</StyledTableCell>
                <StyledTableCell>
                  {item.isActive ? "Active" : "Not Active"}
                </StyledTableCell>
                <StyledTableCell>
                  {item.createdBy === null ? "-" : item.createdBy.nama}
                </StyledTableCell>
                <StyledTableCell>
                  {item.updatedBy === null ? "-" : item.updatedBy.nama}
                </StyledTableCell>
                <StyledTableCell>
                  {item.createdAt === null
                    ? "-"
                    : fmt.format(new Date(item.createdAt))}
                </StyledTableCell>
                <StyledTableCell>
                  {item.updatedAt === null
                    ? "-"
                    : fmt.format(new Date(item.updatedAt))}
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
