import React, { useState, useEffect } from "react";
import {
  Container,
  Box,
  Typography,
  MenuItem,
  Select,
  FormControl,
  TextField,
  Checkbox,
  Button,
  Alert,
} from "@mui/material";
import { Row, Col } from "react-bootstrap";
import SideBars from "../../../components/SideBars";
import "../../../styles/setup/userStyle/UsernamesUpdateFormStyle.css";
import { updateUserService } from "../../../services/apiService/apiSetup/masterUser/ServiceApiUsername";
import { getAllRole } from "../../../services/apiService/apiRolePermission/ServiceRolePermission";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

const UsernamesUpdateForm = () => {
  const navigation = useNavigate();
  const location = useLocation();

  const storedData = localStorage.getItem("userData");
  const userData = JSON.parse(storedData);

  const [dataUser, setDataUser] = useState({
    nama: location.state.nama,
    noTelp: location.state.noTelp,
    email: location.state.email,
    role: location.state.role.id,
    password: "",
    passwordConfirmation: "",
    isAktif: location.state.isAktif,
    isLdap: location.state.isLdap,
    isSpv: location.state.isSpv,
    updatedBy: userData.id,
  });
  const [isError, setIsError] = useState("");
  const [isErrorPwd, setIsErrorPwd] = useState("");
  const [isErrorEmail, setIsErrorEmail] = useState("");
  const [hideAlert, setHideAlert] = useState(false);
  const [hideFormPassword, setHideFormPassword] = useState(false);
  const [disableBtn, setDisableBtn] = useState(false);
  const [dataRole, setDataRole] = useState([]);

  const handleChange = (event) => {
    if (dataUser.nama.includes(" ")) {
      setIsError("Usernames cannot contain spaces");
    }
    setDataUser({
      ...dataUser,
      [event.target.name]: event.target.value,
    });
  };

  const handleCheckBox = (event) => {
    setDataUser({
      ...dataUser,
      [event.target.name]: event.target.checked,
    });
  };

  const handleChangePassword = (event) => {
    setHideFormPassword(event.target.checked);
  };

  const handlePasswordConfirmation = (event) => {
    setDataUser({
      ...dataUser,
      passwordConfirmation: event.target.value,
    });
    if (dataUser.password !== event.target.value) {
      setIsErrorPwd("Password confirmation does not match the password");
    } else {
      setIsErrorPwd("");
    }
  };

  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }

  const handleChangeEmail = (event) => {
    if (!isValidEmail(event.target.value)) {
      setIsErrorEmail("Email invalid");
    } else {
      setIsErrorEmail("");
    }

    setDataUser({
      ...dataUser,
      email: event.target.value,
    });
  };

  const updateUsers = (
    { nama, noTelp, email, role, isAktif, isLdap, isSpv, updatedBy },
    id
  ) => {
    if (dataUser.password !== dataUser.passwordConfirmation) {
      setIsError("Password confirmation does not match the password");
      setHideAlert(!hideAlert);
    } else if (isErrorEmail === "Email invalid") {
      setIsError("Enter your email correctly");
      setHideAlert(!hideAlert);
    } else if (dataUser.nama.includes(" ")) {
      setIsError("Usernames cannot contain spaces");
      setHideAlert(!hideAlert);
    } else {
      updateUserService(
        {
          nama: dataUser.nama === "" ? nama : dataUser.nama,
          noTelp: dataUser.noTelp === "" ? noTelp : dataUser.noTelp,
          email: dataUser.isLdap
            ? `${dataUser.nama}@banpuindo.co.id`
            : dataUser.email,
          role: dataUser.role === "" ? role : dataUser.role,
          password:
            dataUser.password === "" || dataUser.isLdap
              ? ""
              : dataUser.password,
          isAktif: dataUser.isAktif === "" ? isAktif : dataUser.isAktif,
          isLdap: dataUser.isLdap === "" ? isLdap : dataUser.isLdap,
          isSpv: dataUser.isSpv === "" ? isSpv : dataUser.isSpv,
          updatedBy: dataUser.updatedBy === "" ? updatedBy : dataUser.updatedBy,
        },
        id
      )
        .then(() => {
          Swal.fire("Data updated successfully", "", "success");
          navigation("/master-user");
        })
        .catch((res) => {
          if (res.response.data.statusCode === 422) {
            setIsError(res.response.data.message);
            setHideAlert(true);
          } else {
            setIsError("Data was not updated successfully");
            setHideAlert(true);
          }
        });
    }
  };

  useEffect(() => {
    getAllRole()
      .then((res) => {
        const result = res.data;
        setDataRole(result);
      })
      .catch(() => {});

    if (
      dataUser.isLdap
        ? dataUser.nama === "" || dataUser.role === ""
        : dataUser.nama === "" || dataUser.email === "" || dataUser.role === ""
    ) {
      setDisableBtn(true);
    } else {
      setDisableBtn(false);
    }
  }, [dataUser]);

  return (
    <>
      <Box>
        <SideBars />
        <Container maxWidth="lg" sx={{ marginBottom: "150px" }}>
          <h4 className="title-page-ccf">Form Master User</h4>
          {hideAlert && <Alert severity="error">{isError}</Alert>}

          <Box className="box-cost-center-form">
            <Box align="left">
              <Row>
                <Col sm={6}>
                  <Typography sx={{ fontSize: "16px", m: 1 }}>
                    Username <span style={{ color: "red" }}>*</span>
                  </Typography>
                </Col>
                <Col sm={6}>
                  <Typography sx={{ fontSize: "12px", color: "red" }}>
                    {isError}
                  </Typography>
                  <TextField
                    variant="outlined"
                    sx={{ width: "100%" }}
                    size="small"
                    value={dataUser.nama}
                    name="nama"
                    onChange={handleChange}
                  />
                </Col>
              </Row>

              <br />

              <Row>
                <Col sm={6}>
                  <Typography sx={{ fontSize: "16px", m: 1 }}>
                    Phone Number
                  </Typography>
                </Col>
                <Col sm={6}>
                  <TextField
                    variant="outlined"
                    sx={{ width: "100%" }}
                    size="small"
                    value={dataUser.noTelp}
                    name="noTelp"
                    type="number"
                    onChange={handleChange}
                  />
                </Col>
              </Row>

              <br />

              <Row
                style={dataUser.isLdap ? { display: "none" } : { display: "" }}
              >
                <Col sm={6}>
                  <Typography sx={{ fontSize: "16px", m: 1 }}>
                    Email <span style={{ color: "red" }}>*</span>
                  </Typography>
                </Col>
                <Col sm={6}>
                  <Typography sx={{ fontSize: "12px", color: "red" }}>
                    {isErrorEmail}
                  </Typography>
                  <TextField
                    variant="outlined"
                    sx={{ width: "100%" }}
                    size="small"
                    value={dataUser.email}
                    name="email"
                    type="email"
                    onChange={handleChangeEmail}
                  />
                </Col>
              </Row>

              <br
                style={dataUser.isLdap ? { display: "none" } : { display: "" }}
              />

              <Row
                style={dataUser.isLdap ? { display: "none" } : { display: "" }}
              >
                <Col>
                  <Typography sx={{ fontSize: "16px", m: 1 }}>
                    Change Password
                  </Typography>
                </Col>
                <Col>
                  <Checkbox
                    sx={{ marginLeft: "-12px" }}
                    value={dataUser.isAktif}
                    name="isAktif"
                    onChange={handleChangePassword}
                  />
                </Col>
              </Row>

              <br
                style={dataUser.isLdap ? { display: "none" } : { display: "" }}
              />

              {hideFormPassword && (
                <Box>
                  <Row>
                    <Col sm={6}>
                      <Typography sx={{ fontSize: "16px", m: 1 }}>
                        Password <span style={{ color: "red" }}>*</span>
                      </Typography>
                    </Col>
                    <Col sm={6}>
                      <TextField
                        variant="outlined"
                        sx={{ width: "100%" }}
                        size="small"
                        name="password"
                        type="password"
                        onChange={handleChange}
                      />
                    </Col>
                  </Row>

                  <br />

                  <Row>
                    <Col sm={6}>
                      <Typography sx={{ fontSize: "16px", m: 1 }}>
                        Confirm Password <span style={{ color: "red" }}>*</span>
                      </Typography>
                    </Col>
                    <Col sm={6}>
                      <Typography sx={{ fontSize: "12px", color: "red" }}>
                        {isErrorPwd}
                      </Typography>
                      <TextField
                        variant="outlined"
                        sx={{ width: "100%" }}
                        size="small"
                        name="passwordConfirmation"
                        type="password"
                        onChange={handlePasswordConfirmation}
                      />
                    </Col>
                  </Row>
                  <br />
                </Box>
              )}

              <Row>
                <Col sm={6}>
                  <Typography sx={{ fontSize: "16px", m: 1 }}>
                    Role <span style={{ color: "red" }}>*</span>
                  </Typography>
                </Col>
                <Col sm={6}>
                  <FormControl fullWidth>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={dataUser.role}
                      size="small"
                      name="role"
                      onChange={handleChange}
                    >
                      {dataRole.map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.roleName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Col>
              </Row>

              <br />

              <Row>
                <Col>
                  <Typography sx={{ fontSize: "16px", m: 1 }}>
                    Active
                  </Typography>
                </Col>
                <Col>
                  <Checkbox
                    sx={{ marginLeft: "-12px" }}
                    checked={dataUser.isAktif}
                    name="isAktif"
                    onChange={handleCheckBox}
                  />
                </Col>
              </Row>

              <br />

              <Row>
                <Col>
                  <Typography sx={{ fontSize: "16px", m: 1 }}>Ldap</Typography>
                </Col>
                <Col>
                  <Checkbox
                    sx={{ marginLeft: "-12px" }}
                    checked={dataUser.isLdap}
                    name="isLdap"
                    onChange={handleCheckBox}
                  />
                </Col>
              </Row>

              <br />

              <Row>
                <Col>
                  <Typography sx={{ fontSize: "16px", m: 1 }}>
                    Approval
                  </Typography>
                </Col>
                <Col>
                  <Checkbox
                    sx={{ marginLeft: "-12px" }}
                    checked={dataUser.isSpv}
                    name="isSpv"
                    onChange={handleCheckBox}
                  />
                </Col>
              </Row>
            </Box>

            <br />

            <Box align="right">
              <Row>
                <Col>
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: "#436FB1",
                      marginTop: "10px",
                    }}
                    onClick={() => navigation("/master-user")}
                  >
                    Back To List
                  </Button>

                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: "#084298",
                      marginTop: "10px",
                      marginLeft: "10px",
                    }}
                    onClick={() =>
                      updateUsers(location.state, location.state.id)
                    }
                    disabled={disableBtn ? true : false}
                  >
                    Save
                  </Button>
                </Col>
              </Row>
            </Box>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default UsernamesUpdateForm;
