const initialState = {
  dataDivisions: [],
};

const GetDivisions = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ALL_DIVISIONS":
      return { ...state, dataDivisions: action.data };
    default:
      return state;
  }
};

export default GetDivisions;
