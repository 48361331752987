import React from "react";
import {
  Box,
  Container,
  Typography,
  Grid,
  TextField,
  Button,
} from "@mui/material";
import SideBars from "../../../components/SideBars";
import "../../../styles/transaction/fuelingRequestStyle/FuelingRequestStyle.css";
import PaginationFuelingReq from "./components/PaginationFuelingReq";
import TableFuelingReq from "./components/TableFuelingReq";
import { FuelingReqLogic } from "./logic/FuelingReqLogic";

const FuelingRequest = () => {
  const {
    dataApproval,
    takePage,
    page,
    searchCodeTrx,
    navigation,
    handleChangePage,
    handleChangeSearch,
    handleChangeTakePage,
    handleSearch,
  } = FuelingReqLogic();

  if (!dataApproval.data) {
    return null;
  }

  return (
    <>
      <Box>
        <SideBars />
        <Container maxWidth="xl" sx={{ marginBottom: "150px" }}>
          <h3 className="title-page-fr">Fueling Request</h3>
          <Box
            sx={{
              padding: "30px",
              borderRadius: "10px",
              border: "3px solid #e9e7e7",
            }}
          >
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={2}
            >
              <Grid>
                <Typography
                  sx={{ fontSize: "16px", paddingTop: 1, marginRight: "10px" }}
                >
                  Search by Transaction Code
                </Typography>
              </Grid>
              <Grid>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  size="small"
                  sx={{ width: "300px" }}
                  onChange={handleChangeSearch}
                />
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={2}
              sx={{ marginTop: "10px" }}
            >
              <Grid>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "#6EA8FE",
                    minWidth: "200px",
                    fontSize: "16px",
                    textTransform: "none",
                  }}
                  size="small"
                  onClick={(e, v) => handleSearch(searchCodeTrx)}
                >
                  Find
                </Button>
              </Grid>
            </Grid>
          </Box>

          <Box align="right" className="box-new-transaction">
            <Button
              variant="contained"
              size="small"
              sx={{
                backgroundColor: "#084298",
                minWidth: "200px",
                marginBottom: "10px",
                fontSize: "16px",
                textTransform: "none",
              }}
              onClick={() => navigation("/form-fueling-request")}
            >
              New Transaction
            </Button>
          </Box>

          <Box>
            <TableFuelingReq
              dataApproval={dataApproval}
              takePage={takePage}
              page={page}
            />
          </Box>

          <Box sx={{ display: "flex", flexWrap: "wrap", marginTop: "20px" }}>
            <PaginationFuelingReq
              handleChangeTakePage={handleChangeTakePage}
              handleChangePage={handleChangePage}
              dataApproval={dataApproval}
              takePage={takePage}
              page={page}
            />
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default FuelingRequest;
