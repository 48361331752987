import axios from "axios";

const getAllSection = async (take = 10, page = 1, sectionName = "") => {
  const storedData = localStorage.getItem("userData");
  const userData = JSON.parse(storedData);
  const token = !userData ? "" : userData.token;

  const section = await axios({
    method: "get",
    url: `${process.env.REACT_APP_URL_TCM}/api/section?take=${take}&page=${page}&sectionName=${sectionName}`,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return section;
};

const newSectionService = async (form) => {
  const json = JSON.stringify(form);
  const storedData = localStorage.getItem("userData");
  const userData = JSON.parse(storedData);
  const token = !userData ? "" : userData.token;

  const section = await axios.post(
    `${process.env.REACT_APP_URL_TCM}/api/section/new`,
    json,
    {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return section;
};

const deleteSectionService = async (id) => {
  const storedData = localStorage.getItem("userData");
  const userData = JSON.parse(storedData);
  const token = !userData ? "" : userData.token;

  const section = await axios.delete(
    `${process.env.REACT_APP_URL_TCM}/api/section/${id}`,
    {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return section;
};

const updateSectionService = async (form, id) => {
  const json = JSON.stringify(form);
  const storedData = localStorage.getItem("userData");
  const userData = JSON.parse(storedData);
  const token = !userData ? "" : userData.token;

  const section = await axios.put(
    `${process.env.REACT_APP_URL_TCM}/api/section/update/${id}`,
    json,
    {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return section;
};

const getSectionByDeptService = async (id) => {
  const storedData = localStorage.getItem("userData");
  const userData = JSON.parse(storedData);
  const token = !userData ? "" : userData.token;

  const division = await axios({
    method: "get",
    url: `${process.env.REACT_APP_URL_TCM}/api/section/byDept/${id}`,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return division;
};

export {
  getAllSection,
  newSectionService,
  deleteSectionService,
  updateSectionService,
  getSectionByDeptService,
};
