import { useEffect, useState } from "react";
import { getDivisionByEntityService } from "../../../../services/apiService/apiSetup/masterDivision/ServiceApiDivision";
import { useNavigate } from "react-router-dom";
import { getDeptByDivService } from "../../../../services/apiService/apiSetup/masterDepartment/ServiceApiDepartment";
import { getSectionByDeptService } from "../../../../services/apiService/apiSetup/masterSection/ServiceApiSection";
import Swal from "sweetalert2";
import {
  getAllCompany,
  newCostCenterService,
} from "../../../../services/apiService/apiSetup/masterCostCenter/ServiceApiCostCenter";
import { getAllEntity } from "../../../../services/apiService/apiSetup/masterEntity/ServiceApiEntity";

export const LogicCreate = () => {
  const navigation = useNavigate();

  const storedData = localStorage.getItem("userData");
  const userData = JSON.parse(storedData);

  const [dataCostCenter, setDataCostCenter] = useState({
    entity: "",
    division: "",
    department: "",
    section: "",
    costCenterCode: "",
    costCenterName: "",
    costCenterEntityId: "",
    glDebit: "",
    glCredit: "",
    desc: "",
    isActive: false,
    createdBy: userData.id,
  });
  const [dataEntity, setDataEntity] = useState([]);
  const [dataDivision, setDataDivision] = useState([]);
  const [dataDepartment, setDataDepartment] = useState([]);
  const [dataSection, setDataSection] = useState([]);
  const [dataCompany, setDataCompany] = useState([]);
  const [isError, setIsError] = useState("");
  const [isErrorCode, setIsErrorCode] = useState("");
  const [hideAlert, setHideAlert] = useState(false);
  const [disableBtn, setDisableBtn] = useState(false);
  const page = 1;
  const takePage = 0;

  const handleChange = (event) => {
    setDataCostCenter({
      ...dataCostCenter,
      [event.target.name]: event.target.value,
    });
  };

  const handleChangeCode = (event) => {
    setDataCostCenter({
      ...dataCostCenter,
      costCenterCode: event.target.value.trim(),
    });

    if (dataCostCenter.costCenterCode.length >= 10) {
      setIsErrorCode("Input code with maximum 10 characters");
    } else {
      setIsErrorCode("");
    }
  };

  const handleChangeCheckbox = (event) => {
    setDataCostCenter({
      ...dataCostCenter,
      isActive: event.target.checked,
    });
  };

  const getDivByEntity = (id) => {
    getDivisionByEntityService(id)
      .then((res) => {
        const result = res.data;
        setDataDivision(result);
      })
      .catch((res) => {
        if (res.request.status === 404) {
          setIsError("There are no divisions under the entity");
          setHideAlert(!hideAlert);
          setDataDivision([]);
          setDataDepartment([]);
          setDataSection([]);
          setDataCostCenter((dataCostCenter) => ({
            ...dataCostCenter,
            division: "",
            department: "",
            section: "",
          }));
        }
      });
  };

  const getDeptByDiv = (id) => {
    getDeptByDivService(id)
      .then((res) => {
        const result = res.data;
        setDataDepartment(result);
      })
      .catch((res) => {
        if (res.request.status === 404) {
          setIsError("There are no department under the division");
          setHideAlert(!hideAlert);
          setDataDepartment([]);
          setDataSection([]);
          setDataCostCenter((dataCostCenter) => ({
            ...dataCostCenter,
            department: "",
            section: "",
          }));
        }
      });
  };

  const getSectionByDept = (id) => {
    getSectionByDeptService(id)
      .then((res) => {
        const result = res.data;
        setDataSection(result);
      })
      .catch((res) => {
        if (res.request.status === 404) {
          setIsError("There are no section under the department");
          setHideAlert(!hideAlert);
          setDataSection([]);
          setDataCostCenter((dataCostCenter) => ({
            ...dataCostCenter,
            section: "",
          }));
        }
      });
  };

  const createNewCostCenter = () => {
    newCostCenterService({
      costCenterCode: dataCostCenter.costCenterCode,
      costCenterName: dataCostCenter.costCenterName,
      section: dataCostCenter.section,
      desc: dataCostCenter.desc,
      isActive: dataCostCenter.isActive,
      createdBy: dataCostCenter.createdBy,
      glDebit: dataCostCenter.glDebit,
      glCredit: dataCostCenter.glCredit,
      costCenterEntityId: dataCostCenter.costCenterEntityId,
    })
      .then(() => {
        Swal.fire("Data added successfully", "", "success");
        navigation("/cost-center");
      })
      .catch((res) => {
        if (res.response.data.statusCode === 422) {
          setIsError(res.response.data.message);
          setHideAlert(true);
        } else {
          setIsError("Data was not added successfully");
          setHideAlert(true);
        }
      });
  };

  useEffect(() => {
    getAllEntity(takePage, page)
      .then((res) => {
        const result = res.data.data;
        setDataEntity(result);
      })
      .catch(() => {});

    getAllCompany()
      .then((res) => {
        const result = res.data;
        setDataCompany(result);
      })
      .catch(() => {});

    if (
      dataCostCenter.section === "" ||
      dataCostCenter.costCenterCode === "" ||
      dataCostCenter.costCenterName === "" ||
      dataCostCenter.glDebit === "" ||
      dataCostCenter.glCredit === "" ||
      dataCostCenter.costCenterCode.length > 10
    ) {
      setDisableBtn(true);
    } else {
      setDisableBtn(false);
    }
  }, [dataCostCenter]);

  const handleEntityChange = (value) => {
    if (value !== null) {
      getDivByEntity(value.id);
      setDataCostCenter((dataCostCenter) => ({
        ...dataCostCenter,
        entity: value.id,
        division: "",
        department: "",
        section: "",
      }));
    } else {
      setDataCostCenter((dataCostCenter) => ({
        ...dataCostCenter,
        entity: "",
        division: "",
        department: "",
        section: "",
      }));
      setDataDivision([]);
      setDataDepartment([]);
      setDataSection([]);
    }
  };

  const handleDivChange = (value) => {
    if (value !== null) {
      getDeptByDiv(value.id);
      setDataCostCenter((dataCostCenter) => ({
        ...dataCostCenter,
        division: value.id,
        department: "",
        section: "",
      }));
    } else {
      setDataCostCenter((dataCostCenter) => ({
        ...dataCostCenter,
        division: "",
        department: "",
        section: "",
      }));
      setDataDepartment([]);
      setDataSection([]);
    }
  };

  const handleDeptChange = (value) => {
    if (value !== null) {
      getSectionByDept(value.id);
      setDataCostCenter((dataCostCenter) => ({
        ...dataCostCenter,
        department: value.id,
        section: "",
      }));
    } else {
      setDataCostCenter((dataCostCenter) => ({
        ...dataCostCenter,
        department: "",
        section: "",
      }));
      setDataSection([]);
    }
  };

  const handleSectionChange = (value) => {
    if (value !== null) {
      setDataCostCenter((dataCostCenter) => ({
        ...dataCostCenter,
        section: value.id,
      }));
    } else {
      setDataCostCenter((dataCostCenter) => ({
        ...dataCostCenter,
        section: "",
      }));
    }
  };

  const handleSiteChange = (value) => {
    if (value !== null) {
      setDataCostCenter((dataCostCenter) => ({
        ...dataCostCenter,
        costCenterEntityId: value.id,
        glCredit: value.glCredit,
      }));
    } else {
      setDataCostCenter((dataCostCenter) => ({
        ...dataCostCenter,
        costCenterEntityId: "",
        glCredit: "",
      }));
    }
  };
  return {
    hideAlert,
    isError,
    dataEntity,
    dataCostCenter,
    dataDivision,
    dataDepartment,
    dataSection,
    isErrorCode,
    dataCompany,
    disableBtn,
    navigation,
    createNewCostCenter,
    handleChange,
    handleChangeCheckbox,
    handleChangeCode,
    handleDeptChange,
    handleDivChange,
    handleEntityChange,
    handleSectionChange,
    handleSiteChange,
  };
};
