import axios from "axios";

const userLoginService = async (form) => {
  const json = JSON.stringify(form);

  const users = await axios.post(
    `${process.env.REACT_APP_URL_TCM}/api/auth/login`,
    json,
    {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  return users;
};

const userLoginByPassService = async (email) => {
  const json = JSON.stringify(email);

  const users = await axios.post(
    `${process.env.REACT_APP_URL_TCM}/api/auth/login-bypass`,
    json,
    {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  return users;
};

const userLogoutService = async () => {
  const json = JSON.stringify();
  const storedData = localStorage.getItem("userData");
  const userData = JSON.parse(storedData);
  const token = !userData ? "" : userData.token;

  const users = await axios.post(
    `${process.env.REACT_APP_URL_TCM}/api/auth/logout`,
    json,
    {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return users;
};

export { userLoginService, userLoginByPassService, userLogoutService };
