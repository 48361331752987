import React from "react";
import {
  Container,
  Box,
  Typography,
  MenuItem,
  Select,
  FormControl,
  TextField,
  Checkbox,
  Button,
  Alert,
} from "@mui/material";
import { Row, Col } from "react-bootstrap";
import SideBars from "../../../components/SideBars";
import "../../../styles/setup/menusStyle/MenusCreateFormStyle.css";
import { MenusCreateLogic } from "./logic/MenusCreateLogic";

const MenusCreateForm = () => {
  const {
    disableBtn,
    dataMenu,
    dataParent,
    isError,
    hideAlert,
    navigation,
    createMenus,
    handleChangeCheckbox,
    handleChange,
  } = MenusCreateLogic();

  if (!dataParent.data) {
    return null;
  }

  return (
    <>
      <Box>
        <SideBars />
        <Container maxWidth="lg" sx={{ marginBottom: "150px" }}>
          <h4 className="title-page-ccf">Form Master Menu</h4>
          {hideAlert && <Alert severity="error">{isError}</Alert>}
          <Box className="box-cost-center-form">
            <Box align="left">
              <Row>
                <Col sm={6}>
                  <Typography sx={{ fontSize: "16px", m: 1 }}>
                    Menu Name <span style={{ color: "red" }}>*</span>
                  </Typography>
                </Col>
                <Col sm={6}>
                  <TextField
                    variant="outlined"
                    sx={{ width: "100%" }}
                    size="small"
                    name="menuName"
                    onChange={handleChange}
                  />
                </Col>
              </Row>

              <br />

              <Row>
                <Col sm={6}>
                  <Typography sx={{ fontSize: "16px", m: 1 }}>
                    URL <span style={{ color: "red" }}>*</span>
                  </Typography>
                </Col>
                <Col sm={6}>
                  <TextField
                    variant="outlined"
                    sx={{ width: "100%" }}
                    size="small"
                    name="slug"
                    onChange={handleChange}
                  />
                </Col>
              </Row>

              <br />

              <Row>
                <Col>
                  <Typography sx={{ fontSize: "16px", m: 1 }}>
                    Parent
                  </Typography>
                </Col>
                <Col>
                  <Checkbox
                    sx={{ marginLeft: "-12px" }}
                    value={dataMenu.isParent}
                    name="isParent"
                    onChange={handleChangeCheckbox}
                  />
                </Col>
              </Row>

              <br />

              <Row
                style={
                  dataMenu.isParent ? { display: "none" } : { display: "" }
                }
              >
                <Col sm={6}>
                  <Typography sx={{ fontSize: "16px", m: 1 }}>
                    Menu Parent <span style={{ color: "red" }}>*</span>
                  </Typography>
                </Col>
                <Col sm={6}>
                  <FormControl fullWidth>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={dataMenu.menuIdParent}
                      size="small"
                      name="menuIdParent"
                      onChange={handleChange}
                    >
                      {dataParent.data.map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.menuName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Col>
              </Row>

              <br
                style={
                  dataMenu.isParent ? { display: "none" } : { display: "" }
                }
              />

              <Row>
                <Col sm={6}>
                  <Typography sx={{ fontSize: "16px", m: 1 }}>
                    Menu Index <span style={{ color: "red" }}>*</span>
                  </Typography>
                </Col>
                <Col sm={6}>
                  <TextField
                    variant="outlined"
                    sx={{ width: "100%" }}
                    size="small"
                    name="menuSeq"
                    type="number"
                    onChange={handleChange}
                  />
                </Col>
              </Row>

              <br />

              <Row>
                <Col sm={6}>
                  <Typography sx={{ fontSize: "16px", m: 1 }}>
                    Description
                  </Typography>
                </Col>
                <Col sm={6}>
                  <TextField
                    variant="outlined"
                    sx={{ width: "100%" }}
                    size="small"
                    name="menuDesc"
                    onChange={handleChange}
                  />
                </Col>
              </Row>

              <br />

              <Row>
                <Col>
                  <Typography sx={{ fontSize: "16px", m: 1 }}>
                    Active
                  </Typography>
                </Col>
                <Col>
                  <Checkbox
                    sx={{ marginLeft: "-12px" }}
                    value={dataMenu.isActive}
                    name="isActive"
                    onChange={handleChangeCheckbox}
                  />
                </Col>
              </Row>
            </Box>

            <br />

            <Box align="right">
              <Row>
                <Col>
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: "#436FB1",
                      marginTop: "10px",
                    }}
                    onClick={() => navigation("/master-menu")}
                  >
                    Back To List
                  </Button>

                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: "#084298",
                      marginTop: "10px",
                      marginLeft: "10px",
                    }}
                    onClick={() => createMenus()}
                    disabled={disableBtn ? true : false}
                  >
                    Save
                  </Button>
                </Col>
              </Row>
            </Box>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default MenusCreateForm;
