import React from "react";
import {
  styled,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#8FD0EF",
    color: "#484848",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: "14",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: "#F0F0F0",
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function TableFuelingReq(props) {
  const { dataApproval, takePage, page } = props;

  const fmt = new Intl.DateTimeFormat("en-GB", {
    year: "2-digit",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  });

  const handleStatusTrx = (approve, done) => {
    if (approve === false && done === false) {
      return "Pending";
    } else if (approve === false && done === true) {
      return "Rejected";
    } else if (approve === true && done === false) {
      return "Approved";
    } else if (approve === true && done === true) {
      return "Done";
    }
  };
  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 900 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell sx={{ fontWeight: "bold" }}>#</StyledTableCell>
              <StyledTableCell sx={{ fontWeight: "bold" }}>
                Code
              </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: "bold" }}>
                Status
              </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: "bold" }}>
                Requester Name
              </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: "bold" }}>
                Unit Number
              </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: "bold" }}>
                Qty (Ltr)
              </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: "bold" }}>
                Mileage (KM)
              </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: "bold" }}>
                Remarks
              </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: "bold" }}>
                User Created
              </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: "bold" }}>
                User Updated
              </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: "bold" }}>
                Date Created
              </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: "bold" }}>
                Date Updated
              </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: "bold" }}>
                Date Approved
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataApproval.data.map((item, index) => (
              <StyledTableRow key={item.id}>
                <StyledTableCell>
                  {(page - 1) * takePage + index + 1}
                </StyledTableCell>
                <StyledTableCell>{item.trxCode}</StyledTableCell>
                <StyledTableCell>
                  {handleStatusTrx(item.isApproved, item.isDone)}
                </StyledTableCell>
                <StyledTableCell>{item.requesterName}</StyledTableCell>
                <StyledTableCell>{item.vehicleId.unitNum}</StyledTableCell>
                <StyledTableCell>{item.requestQty}</StyledTableCell>
                <StyledTableCell>{item.requestMileage}</StyledTableCell>
                <StyledTableCell>{item.remark}</StyledTableCell>
                <StyledTableCell>
                  {item.createdBy === null ? "-" : item.createdBy.nama}
                </StyledTableCell>
                <StyledTableCell>
                  {item.updatedBy === null ? "-" : item.updatedBy.nama}
                </StyledTableCell>
                <StyledTableCell>
                  {item.createdAt === null
                    ? "-"
                    : fmt.format(new Date(item.createdAt))}
                </StyledTableCell>
                <StyledTableCell>
                  {item.updatedAt === null
                    ? "-"
                    : fmt.format(new Date(item.updatedAt))}
                </StyledTableCell>
                <StyledTableCell>
                  {item.updatedAt === null
                    ? "-"
                    : fmt.format(new Date(item.approvedAt))}
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
