import React, { useState, useEffect } from "react";
import {
  Container,
  Box,
  Typography,
  Checkbox,
  Alert,
  TextField,
  Button,
} from "@mui/material";
import { Row, Col } from "react-bootstrap";
import SideBars from "../../../components/SideBars";
import "../../../styles/setup/vehicleTypeStyle/VehicleTypeUpdateFormStyle.css";
import Swal from "sweetalert2";
import { useLocation, useNavigate } from "react-router-dom";
import { updateVehicleTypeService } from "../../../services/apiService/apiSetup/masterVehicleType/ServiceApiVehicleType";

const VehicleTypeUpdateForm = () => {
  const navigation = useNavigate();
  const location = useLocation();

  const storedData = localStorage.getItem("userData");
  const userData = JSON.parse(storedData);

  const [dataVehicleType, setDataVehicleType] = useState({
    vehicleTypeCode: location.state.vehicleTypeCode,
    vehicleTypeName: location.state.vehicleTypeName,
    desc: "",
    isActive: location.state.isActive,
    updatedBy: userData.id,
  });
  const [isError, setIsError] = useState("");
  const [isErrorCode, setIsErrorCode] = useState("");
  const [hideAlert, setHideAlert] = useState(false);
  const [disableBtn, setDisableBtn] = useState(false);

  const handleChange = (event) => {
    setDataVehicleType({
      ...dataVehicleType,
      [event.target.name]: event.target.value,
    });
  };

  const handleChangeCode = (event) => {
    setDataVehicleType({
      ...dataVehicleType,
      vehicleTypeCode: event.target.value,
    });

    if (dataVehicleType.vehicleTypeCode.length >= 5) {
      setIsErrorCode("Input code with maximum 5 characters");
    } else {
      setIsErrorCode("");
    }
  };

  const handleChangeCheckbox = (event) => {
    setDataVehicleType({
      ...dataVehicleType,
      isActive: event.target.checked,
    });
  };

  const updateVehicleType = (
    { vehicleTypeCode, vehicleTypeName, desc, isActive, updatedBy },
    id
  ) => {
    updateVehicleTypeService(
      {
        vehicleTypeCode:
          dataVehicleType.vehicleTypeCode === ""
            ? vehicleTypeCode
            : dataVehicleType.vehicleTypeCode,
        vehicleTypeName:
          dataVehicleType.vehicleTypeName === ""
            ? vehicleTypeName
            : dataVehicleType.vehicleTypeName,
        desc: dataVehicleType.desc === "" ? desc : dataVehicleType.desc,
        isActive:
          dataVehicleType.isActive === "" ? isActive : dataVehicleType.isActive,
        updatedBy:
          dataVehicleType.updatedBy === ""
            ? updatedBy
            : dataVehicleType.updatedBy,
      },
      id
    )
      .then(() => {
        Swal.fire("Data updated successfully", "", "success");
        navigation("/master-vehicle-type");
      })
      .catch((res) => {
        if (res.response.data.statusCode === 422) {
          setIsError(res.response.data.message);
          setHideAlert(true);
        } else {
          setIsError("Data was not updated successfully");
          setHideAlert(!hideAlert);
        }
      });
  };

  useEffect(() => {
    if (
      dataVehicleType.vehicleTypeCode === "" ||
      dataVehicleType.vehicleTypeName === "" ||
      dataVehicleType.vehicleTypeCode.length > 5
    ) {
      setDisableBtn(true);
    } else {
      setDisableBtn(false);
    }
  }, [dataVehicleType]);

  return (
    <>
      <Box>
        <SideBars />

        <Container maxWidth="lg" sx={{ marginBottom: "150px" }}>
          <h4 className="title-page-vf">Form Master Vehicle Type</h4>
          {hideAlert && <Alert severity="error">{isError}</Alert>}
          <Box className="box-vehicle-form">
            <Box align="left">
              <Row>
                <Col sm={6}>
                  <Typography sx={{ fontSize: "16px", m: 1 }}>
                    Code <span style={{ color: "red" }}>*</span>
                  </Typography>
                </Col>
                <Col sm={6}>
                  <Typography sx={{ fontSize: "12px", color: "red" }}>
                    {isErrorCode}
                  </Typography>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    sx={{ width: "100%" }}
                    size="small"
                    defaultValue={location.state.vehicleTypeCode}
                    name="vehicleTypeCode"
                    disabled={true}
                    onChange={handleChangeCode}
                  />
                </Col>
              </Row>

              <br />

              <Row>
                <Col sm={6}>
                  <Typography sx={{ fontSize: "16px", m: 1 }}>
                    Name <span style={{ color: "red" }}>*</span>
                  </Typography>
                </Col>
                <Col sm={6}>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    sx={{ width: "100%" }}
                    size="small"
                    defaultValue={location.state.vehicleTypeName}
                    name="vehicleTypeName"
                    onChange={handleChange}
                  />
                </Col>
              </Row>

              <br />

              <Row>
                <Col sm={6}>
                  <Typography sx={{ fontSize: "16px", m: 1 }}>
                    Description
                  </Typography>
                </Col>
                <Col sm={6}>
                  <TextField
                    id="outlined-multiline-static"
                    multiline
                    rows={4}
                    defaultValue={location.state.desc}
                    name="desc"
                    onChange={handleChange}
                    sx={{ width: "100%" }}
                  />
                </Col>
              </Row>

              <br />

              <Row>
                <Col>
                  <Typography sx={{ fontSize: "16px", m: 1 }}>
                    Active
                  </Typography>
                </Col>
                <Col>
                  <Checkbox
                    sx={{ marginLeft: "-12px" }}
                    defaultChecked={location.state.isActive}
                    name="isActive"
                    onChange={handleChangeCheckbox}
                  />
                </Col>
              </Row>
            </Box>

            <br />

            <Box align="right">
              <Row>
                <Col>
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: "#436FB1",
                      marginTop: "10px",
                    }}
                    onClick={() => navigation("/master-vehicle-type")}
                  >
                    Back To List
                  </Button>

                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: "#084298",
                      marginTop: "10px",
                      marginLeft: "10px",
                    }}
                    onClick={() =>
                      updateVehicleType(location.state, location.state.id)
                    }
                    disabled={disableBtn ? true : false}
                  >
                    Save
                  </Button>
                </Col>
              </Row>
            </Box>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default VehicleTypeUpdateForm;
