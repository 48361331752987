import React, { useState } from "react";
import {
  Paper,
  TableRow,
  TableContainer,
  TableBody,
  Table,
  styled,
  Typography,
  Button,
} from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { getDetailTrxService } from "../../../services/apiService/apiDashboard/ServiceDashboard";
import ModalDetailTrx from "./ModalDetailTrx";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "white",
    color: "black",
    fontWeight: "bold",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 16,
    height: 100,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#8FD0EF",
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function TableTrx(props) {
  const { dataDashboard } = props;

  const [dataDetailTrx, setDataDetailTrx] = useState({});
  const [open, setOpen] = useState(false);

  const handleClose = () => setOpen(false);

  const getDetailTrx = (trxCode) => {
    getDetailTrxService(trxCode)
      .then((res) => {
        setOpen(true);
        const result = res.data;
        setDataDetailTrx(result);
      })
      .catch(() => {});
  };

  return (
    <>
      {open ? (
        <ModalDetailTrx
          handleClose={handleClose}
          open={open}
          dataDetailTrx={dataDetailTrx}
        />
      ) : (
        ""
      )}

      <TableContainer component={Paper} className="table-container">
        <Typography
          variant="h5"
          gutterBottom
          sx={{
            textAlign: "center",
            marginBottom: "30px",
            fontWeight: "bold",
          }}
        >
          Last Transaction
        </Typography>
        <Table aria-label="customized table">
          <TableBody>
            {dataDashboard.last_transaction &&
              dataDashboard.last_transaction.map((item) => (
                <StyledTableRow key={item.trx_code}>
                  <StyledTableCell align="center">
                    {item.vehicle_unit_number}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {item.trx_code}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {item.actual_qty}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <Button
                      variant="contained"
                      size="small"
                      sx={{
                        backgroundColor: "#084298",
                        marginBottom: "10px",
                        fontSize: "16px",
                        textTransform: "none",
                      }}
                      onClick={() => getDetailTrx(item.trx_code)}
                    >
                      View
                    </Button>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
