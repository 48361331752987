import axios from "axios";

const getAllVehicleType = async (take = 10, page = 1, vehicleTypeName = "") => {
  const storedData = localStorage.getItem("userData");
  const userData = JSON.parse(storedData);
  const token = !userData ? "" : userData.token;

  const vehicleType = await axios({
    method: "get",
    url: `${process.env.REACT_APP_URL_TCM}/api/vehicle-type?take=${take}&page=${page}&vehicleTypeName=${vehicleTypeName}`,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return vehicleType;
};

const newVehicleTypeService = async (form) => {
  const json = JSON.stringify(form);
  const storedData = localStorage.getItem("userData");
  const userData = JSON.parse(storedData);
  const token = !userData ? "" : userData.token;

  const vehicleType = await axios.post(
    `${process.env.REACT_APP_URL_TCM}/api/vehicle-type/new`,
    json,
    {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return vehicleType;
};

const deleteVehicleTypeService = async (id) => {
  const storedData = localStorage.getItem("userData");
  const userData = JSON.parse(storedData);
  const token = !userData ? "" : userData.token;

  const vehicleType = await axios.delete(
    `${process.env.REACT_APP_URL_TCM}/api/vehicle-type/${id}`,
    {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return vehicleType;
};

const updateVehicleTypeService = async (form, id) => {
  const json = JSON.stringify(form);
  const storedData = localStorage.getItem("userData");
  const userData = JSON.parse(storedData);
  const token = !userData ? "" : userData.token;

  const vehicleType = await axios.put(
    `${process.env.REACT_APP_URL_TCM}/api/vehicle-type/update/${id}`,
    json,
    {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return vehicleType;
};

export {
  getAllVehicleType,
  newVehicleTypeService,
  deleteVehicleTypeService,
  updateVehicleTypeService,
};
