import React from "react";
import {
  Container,
  Box,
  Typography,
  Grid,
  Button,
  TextField,
} from "@mui/material";
import SideBars from "../../../components/SideBars";
import "../../../styles/transaction/validationStyle/ValidationStyle.css";
import Modal from "@mui/material/Modal";
import Loading from "../../../assets/approvalAssets/loading.gif";
import PaginationValidation from "./components/PaginationValidation";
import TableValidation from "./components/TableValidation";
import { ValidationLogic } from "./logic/ValidationLogic";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "3px solid #e9e7e7",
  borderRadius: "10px",
  boxShadow: 24,
};

const Validations = () => {
  const {
    page,
    dataValidation,
    takePage,
    searchCodeTrx,
    open,
    handleChangePage,
    handleChangeSearch,
    handleChangeTakePage,
    handleSearch,
  } = ValidationLogic();

  if (!dataValidation.data) {
    return null;
  }

  return (
    <>
      <Box>
        <SideBars />

        <div>
          <Modal
            open={open}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{ opacity: 0.8 }}
          >
            <Box sx={style}>
              <img
                src={Loading}
                alt="loading"
                style={{
                  height: "100px",
                }}
              />
            </Box>
          </Modal>
        </div>

        <Container maxWidth="xl" sx={{ marginBottom: "150px" }}>
          <h3 className="title-page-approval">Validation</h3>
          <Box
            sx={{
              padding: "30px",
              borderRadius: "10px",
              border: "3px solid #e9e7e7",
            }}
          >
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={2}
            >
              <Grid>
                <Typography
                  sx={{ fontSize: "16px", paddingTop: 1, marginRight: "10px" }}
                >
                  Search by Transaction Code
                </Typography>
              </Grid>
              <Grid>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  size="small"
                  sx={{ width: "300px" }}
                  onChange={handleChangeSearch}
                />
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={2}
              sx={{ marginTop: "10px" }}
            >
              <Grid>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "#6EA8FE",
                    minWidth: "200px",
                    fontSize: "16px",
                    textTransform: "none",
                  }}
                  size="small"
                  onClick={() => handleSearch(searchCodeTrx)}
                >
                  Find
                </Button>
              </Grid>
            </Grid>
          </Box>

          <Box className="box-border-approval"></Box>

          <Box>
            <TableValidation
              takePage={takePage}
              page={page}
              dataValidation={dataValidation}
            />
          </Box>

          <Box sx={{ display: "flex", flexWrap: "wrap", marginTop: "20px" }}>
            <PaginationValidation
              handleChangeTakePage={handleChangeTakePage}
              takePage={takePage}
              dataValidation={dataValidation}
              page={page}
              handleChangePage={handleChangePage}
            />
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default Validations;
