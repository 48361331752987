import axios from "axios";

const getAllLogTransactionService = async (
  take = 10,
  page = 1,
  startDate = "",
  endDate = "",
  action = "",
  createdBy = ""
) => {
  const storedData = localStorage.getItem("userData");
  const userData = JSON.parse(storedData);
  const token = !userData ? "" : userData.token;

  const request = await axios({
    method: "get",
    url: `${process.env.REACT_APP_URL_TCM}/api/LogTransaction?take=${take}&page=${page}&startDate=${startDate}&endDate=${endDate}&action=${action}&createdBy=${createdBy}`,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return request;
};

export { getAllLogTransactionService };
