import React from "react";
import Chart from "react-apexcharts";

export default function ChartTrx(props) {
  const { dataChart } = props;

  const fmtDate = new Intl.DateTimeFormat("en-GB", {
    year: "2-digit",
    month: "numeric",
    day: "numeric",
  });

  const chartData = {
    options: {
      chart: {
        id: "basic-bar",
        toolbar: {
          show: false,
        },
      },
      xaxis: {
        categories:
          dataChart.qty_transaction === undefined
            ? null
            : dataChart.qty_transaction.map((item) =>
                fmtDate.format(new Date(item.trx_date))
              ),
      },
    },
    series: [
      {
        name: "Qty Transaction",
        data:
          dataChart.qty_transaction === undefined
            ? null
            : dataChart.qty_transaction.map((item) => item.total),
        color: "#6DC8A4",
      },
    ],
  };

  return (
    <>
      <Chart
        options={chartData.options}
        series={chartData.series}
        type="bar"
        width="100%"
        className="chart"
      />
    </>
  );
}
