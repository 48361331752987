import axios from "axios";

const getAuthEntityService = async (id) => {
  const storedData = localStorage.getItem("userData");
  const userData = JSON.parse(storedData);
  const token = !userData ? "" : userData.token;

  const getUsers = await axios({
    method: "get",
    url: `${process.env.REACT_APP_URL_TCM}/api/user-authorization/entitys/${id}`,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return getUsers;
};
const getAuthDivisionService = async (id) => {
  const storedData = localStorage.getItem("userData");
  const userData = JSON.parse(storedData);
  const token = !userData ? "" : userData.token;

  const getUsers = await axios({
    method: "get",
    url: `${process.env.REACT_APP_URL_TCM}/api/user-authorization/divisis/${id}`,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return getUsers;
};

const getAuthDeptService = async (id) => {
  const storedData = localStorage.getItem("userData");
  const userData = JSON.parse(storedData);
  const token = !userData ? "" : userData.token;

  const getUsers = await axios({
    method: "get",
    url: `${process.env.REACT_APP_URL_TCM}/api/user-authorization/departemens/${id}`,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return getUsers;
};

const getAuthSectionService = async (id) => {
  const storedData = localStorage.getItem("userData");
  const userData = JSON.parse(storedData);
  const token = !userData ? "" : userData.token;

  const getUsers = await axios({
    method: "get",
    url: `${process.env.REACT_APP_URL_TCM}/api/user-authorization/sections/${id}`,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return getUsers;
};

const newUserAuthorService = async (form) => {
  const json = JSON.stringify(form);
  const storedData = localStorage.getItem("userData");
  const userData = JSON.parse(storedData);
  const token = !userData ? "" : userData.token;

  const users = await axios.post(
    `${process.env.REACT_APP_URL_TCM}/api/user-authorization/new`,
    json,
    {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return users;
};

const getDivisionByEntityService = async (id) => {
  const storedData = localStorage.getItem("userData");
  const userData = JSON.parse(storedData);
  const token = !userData ? "" : userData.token;

  const division = await axios({
    method: "get",
    url: `${process.env.REACT_APP_URL_TCM}/api/divisi/byEntitys/${id}`,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  return division;
};

const getDeptByDivService = async (id) => {
  const storedData = localStorage.getItem("userData");
  const userData = JSON.parse(storedData);
  const token = !userData ? "" : userData.token;

  const department = await axios({
    method: "get",
    url: `${process.env.REACT_APP_URL_TCM}/api/departemen/bydivisi/${id}`,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return department;
};

const getSectionByDeptService = async (id) => {
  const storedData = localStorage.getItem("userData");
  const userData = JSON.parse(storedData);
  const token = !userData ? "" : userData.token;

  const section = await axios({
    method: "get",
    url: `${process.env.REACT_APP_URL_TCM}/api/section/bydepartemen/${id}`,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return section;
};

const deleteAuthEntityService = async (idUser, idEntity) => {
  const storedData = localStorage.getItem("userData");
  const userData = JSON.parse(storedData);
  const token = !userData ? "" : userData.token;

  const entity = await axios.delete(
    `${process.env.REACT_APP_URL_TCM}/api/user-authorization/${idUser}/entity/${idEntity}`,
    {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return entity;
};

const deleteAuthDivService = async (idUser, idDiv) => {
  const storedData = localStorage.getItem("userData");
  const userData = JSON.parse(storedData);
  const token = !userData ? "" : userData.token;

  const division = await axios.delete(
    `${process.env.REACT_APP_URL_TCM}/api/user-authorization/${idUser}/divisis/${idDiv}`,
    {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return division;
};

const deleteAuthDeptService = async (idUser, idDept) => {
  const storedData = localStorage.getItem("userData");
  const userData = JSON.parse(storedData);
  const token = !userData ? "" : userData.token;

  const department = await axios.delete(
    `${process.env.REACT_APP_URL_TCM}/api/user-authorization/${idUser}/departemens/${idDept}`,
    {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return department;
};

const deleteAuthSectionService = async (idUser, idSection) => {
  const storedData = localStorage.getItem("userData");
  const userData = JSON.parse(storedData);
  const token = !userData ? "" : userData.token;

  const section = await axios.delete(
    `${process.env.REACT_APP_URL_TCM}/api/user-authorization/${idUser}/sections/${idSection}`,
    {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return section;
};

export {
  getAuthEntityService,
  getAuthDivisionService,
  getAuthDeptService,
  getAuthSectionService,
  newUserAuthorService,
  getDivisionByEntityService,
  getDeptByDivService,
  getSectionByDeptService,
  deleteAuthEntityService,
  deleteAuthDivService,
  deleteAuthDeptService,
  deleteAuthSectionService
};
