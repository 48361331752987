import React from "react";
import {
  styled,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#8FD0EF",
    color: "#484848",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: "14",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: "#F0F0F0",
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function TableTrxHistory(props) {
  const { handleViewOddo, dataTrxHistory, page, takePage } = props;
  const fmt = new Intl.DateTimeFormat("en-GB", {
    year: "2-digit",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
  });

  const handleStatusTrx = (approve, done, expired) => {
    if (approve === false && done === false) {
      return "Pending";
    } else if (approve === false && done === true) {
      return "Rejected";
    } else if (approve === true && done === false) {
      return "Approved";
    } else if (approve === true && done === true) {
      return "Done";
    } else if (approve === true && expired === true) {
      return "Expired";
    }
  };


  return (
    <div>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 900 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell sx={{ fontWeight: "bold" }}>#</StyledTableCell>
              <StyledTableCell sx={{ fontWeight: "bold" }}>
                Transaction Code
              </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: "bold" }}>
                Status
              </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: "bold" }}>
                Action Date
              </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: "bold" }}>
                Entity
              </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: "bold" }}>
                Division
              </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: "bold" }}>
                Department
              </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: "bold" }}>
                Section
              </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: "bold" }}>
                Cost Center
              </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: "bold" }}>
                Fueling Location
              </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: "bold" }}>
                Unit Number
              </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: "bold" }}>
                Asset Number
              </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: "bold" }}>
                Request By
              </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: "bold" }}>
                Approved By
              </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: "bold" }}>
                Mileage (KM) Before
              </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: "bold" }}>
                View Photo
              </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: "bold" }}>
                Mileage (KM) After
              </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: "bold" }}>
                Qty Request
              </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: "bold" }}>
                Qty Issue
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataTrxHistory.data.map((item, index) => (
              <StyledTableRow key={item.id}>
                <StyledTableCell>
                  {(page - 1) * takePage + index + 1}
                </StyledTableCell>
                <StyledTableCell>{item.trxCode}</StyledTableCell>
                <StyledTableCell>
                  {handleStatusTrx(
                    item.isApproved,
                    item.isDone,
                    item.isExpired
                  )}
                </StyledTableCell>
                <StyledTableCell>
                  {item.issuedAt ? fmt.format(new Date(item.issuedAt)) : "-"}
                </StyledTableCell>
                <StyledTableCell>
                  {item.costCenterId.section
                    ? item.costCenterId.section.departemen.divisi.entity.name
                    : "-"}
                </StyledTableCell>
                <StyledTableCell>
                  {item.costCenterId.section
                    ? item.costCenterId.section.departemen.divisi.divisiNama
                    : "-"}
                </StyledTableCell>
                <StyledTableCell>
                  {item.costCenterId.section
                    ? item.costCenterId.section.departemen.deptName
                    : "-"}
                </StyledTableCell>
                <StyledTableCell>
                  {item.costCenterId.section
                    ? item.costCenterId.section.sectionName
                    : "-"}
                </StyledTableCell>
                <StyledTableCell>
                  {item.costCenterId.costCenterName}
                </StyledTableCell>
                <StyledTableCell>
                  {item.locationId ? item.locationId.lokasiAddress : "-"}
                </StyledTableCell>
                <StyledTableCell>{item.vehicleId.unitNum}</StyledTableCell>
                <StyledTableCell>{item.vehicleId.assetNumber}</StyledTableCell>
                <StyledTableCell>{item.requesterName}</StyledTableCell>
                <StyledTableCell>
                  {item.approvedBy === null ? "-" : item.approvedBy.nama}
                </StyledTableCell>
                <StyledTableCell>{item.requestMileage}</StyledTableCell>
                <StyledTableCell align="center">
                  {item.imgOddo === null ? (
                    ""
                  ) : (
                    <VisibilityIcon
                      sx={{
                        color: "#084298",
                      }}
                      onClick={() => handleViewOddo(item.imgOddo)}
                    />
                  )}
                </StyledTableCell>
                <StyledTableCell>{item.transactionMileage}</StyledTableCell>
                <StyledTableCell>{item.requestQty}</StyledTableCell>
                <StyledTableCell>{item.actualQty}</StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
