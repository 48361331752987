import React, { useEffect, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Fade from "@mui/material/Fade";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import ArrowDropDown from "@mui/icons-material/ArrowDropDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
// import DashboardIcon from "@mui/icons-material/Dashboard";
import Button from "@mui/material/Button";
import LogoEbbmTcm from "../assets/sideBarAsset/logoEbbmTcm.png";
import { useNavigate, useLocation } from "react-router-dom";
import SpaceDashboardIcon from "@mui/icons-material/SpaceDashboard";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import AssessmentIcon from "@mui/icons-material/Assessment";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import { userLogoutService } from "../services/apiService/apiLogin/ServiceApiLogin";
import PrivacyTipIcon from "@mui/icons-material/PrivacyTip";
import { getMenuByIdRoleService } from "../services/apiService/apiSetup/masterMenu/ServiceApiMenu";
import "../styles/component/SideBarStyle.css";

const drawerWidth = 240;

const SideBars = () => {
  const navigation = useNavigate();
  const locationPath = useLocation();

  const storedData = localStorage.getItem("userData");
  const storedDataMenu = localStorage.getItem("idParent");
  const storedMenuChild = localStorage.getItem("openMenuChild");
  const userData = JSON.parse(storedData);
  const idParent = JSON.parse(storedDataMenu);
  const stateMenuChild = storedMenuChild ? JSON.parse(storedMenuChild) : false;
  const idRole = userData.role.id;

  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const iconMenu = [
    <SpaceDashboardIcon sx={{ marginRight: "5px" }} />,
    <Brightness7Icon sx={{ marginRight: "5px" }} />,
    <AccountBalanceWalletIcon sx={{ marginRight: "5px" }} />,
    <AssessmentIcon sx={{ marginRight: "5px" }} />,
    <PrivacyTipIcon sx={{ marginRight: "5px" }} />,
  ];

  const [anchorElProfile, setAnchorElProfile] = useState(null);
  const [dataMenu, setDataMenu] = useState([]);
  const [openMenuChild, setOpenMenuChild] = useState(stateMenuChild);

  const openProfile = Boolean(anchorElProfile);

  const handleClickProfile = (event) => {
    setAnchorElProfile(event.currentTarget);
  };

  const handleCloseProfile = () => {
    setAnchorElProfile(null);
  };

  const handleBtnParent = (item) => {
    if (item.child.length > 0) {
      const newOpenMenuChild = !openMenuChild;
      setOpenMenuChild(newOpenMenuChild);
      localStorage.setItem("openMenuChild", JSON.stringify(newOpenMenuChild));
    } else {
      navigation(item.slug);
    }
    localStorage.setItem("idParent", JSON.stringify(item.id));
  };

  const handleBtnChild = (data) => {
    navigation(data.slug);
  };

  useEffect(() => {
    getMenuByIdRoleService(idRole)
      .then((res) => {
        const result = res.data;
        setDataMenu(result);
      })
      .catch(() => {});
  }, [idRole]);

  const userLogout = () => {
    userLogoutService().then(() => {
      localStorage.removeItem("userData");
      localStorage.removeItem("idParent");
      localStorage.removeItem("openMenuChild");
      navigation("/");
    });
  };

  const handleMenuShow = () => {
    return (
      <List sx={{ minWidth: "220px" }}>
        {dataMenu.map((item) => (
          <Box key={item.id}>
            <ListItem>
              <ListItemButton
                onClick={() => handleBtnParent(item)}
                sx={
                  locationPath.pathname === item.slug
                    ? {
                        backgroundColor: "white",
                        color: "#084298",
                        ":hover": {
                          background: "white",
                          color: "#084298",
                        },
                      }
                    : {
                        ":hover": {
                          background: "white",
                          color: "#084298",
                        },
                      }
                }
              >
                {iconMenu[item.menu_seq]}
                {item.menu_name}

                {idParent === item.id && openMenuChild ? (
                  <KeyboardArrowUpIcon
                    sx={
                      item.child.length > 0
                        ? { position: "absolute", right: "0" }
                        : { position: "absolute", right: "0", display: "none" }
                    }
                  />
                ) : (
                  <KeyboardArrowDownIcon
                    sx={
                      item.child.length > 0
                        ? { position: "absolute", right: "0" }
                        : { position: "absolute", right: "0", display: "none" }
                    }
                  />
                )}
              </ListItemButton>
            </ListItem>

            {item.child.map((data) => (
              <Box className="menu-setup" key={data.id}>
                {idParent === item.id && openMenuChild && (
                  <ListItem>
                    <ListItemButton
                      onClick={() => handleBtnChild(data)}
                      sx={
                        locationPath.pathname === data.slug
                          ? {
                              backgroundColor: "white",
                              color: "#084298",
                              ":hover": {
                                background: "white",
                                color: "#084298",
                              },
                            }
                          : {
                              ":hover": {
                                background: "white",
                                color: "#084298",
                              },
                            }
                      }
                    >
                      {data.menu_name}
                    </ListItemButton>
                  </ListItem>
                )}
              </Box>
            ))}
          </Box>
        ))}
      </List>
    );
  };

  const drawer = (
    <div>
      <Toolbar>
        <img
          src={LogoEbbmTcm}
          alt="logo ebbm-tcm"
          style={{ display: "block", margin: "auto" }}
        />
      </Toolbar>
      {handleMenuShow()}
    </div>
  );

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          backgroundColor: "#084298",
        }}
      >
        <Toolbar sx={{ justifyContent: "space-between" }}>
          <Box>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
            >
              <MenuIcon />
            </IconButton>
          </Box>

          <Box>
            <Button
              id="profile-button"
              aria-controls={openProfile ? "profile-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={openProfile ? "true" : undefined}
              onClick={handleClickProfile}
              color="inherit"
            >
              {userData.nama} <ArrowDropDown />
            </Button>

            <Menu
              id="basic-menu"
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
              anchorEl={anchorElProfile}
              open={openProfile}
              onClose={handleCloseProfile}
              TransitionComponent={Fade}
            >
              <Box className="menu-profile">
                <MenuItem onClick={() => navigation("/profile")}>
                  Profile
                </MenuItem>
                <MenuItem onClick={() => userLogout()}>Logout</MenuItem>
              </Box>
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{
          width: { sm: drawerWidth },
        }}
      >
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            "& .MuiDrawer-paper": {
              backgroundColor: "#084298",
              color: "white",
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>

      <Toolbar />
    </Box>
  );
};

export default SideBars;
