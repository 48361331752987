import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  deleteMenusService,
  getAllParents,
  getChildByParentService,
} from "../../../../services/apiService/apiSetup/masterMenu/ServiceApiMenu";
import Swal from "sweetalert2";

const MenuLogic = () => {
  const navigation = useNavigate();

  const [takePage, setTakePage] = useState(10);
  const [page, setPage] = useState(1);
  const [menuName, setMenuName] = useState("");
  const [open, setOpen] = useState({});
  const [menuChild, setMenuChild] = useState([]);
  const [dataAllMenu, setDataAllMenu] = useState([]);

  const loadData = useCallback(() => {
    getAllParents(takePage, page, menuName)
      .then((res) => {
        const result = res.data;
        setDataAllMenu(result);
        if (res.data.data.length === 0) {
          setPage(1);
        }
      })
      .catch((res) => {
        if (res.request.status === 401) {
          navigation("/");
        }
      });
  }, [takePage, page, menuName, navigation]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const deleteMenus = (idMenu, idParent) => {
    console.log("parent", idParent);
    Swal.fire({
      title: "Are you sure to deleted ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#084298",
      cancelButtonColor: "#d33",
      confirmButtonText: "Delete",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteMenusService(idMenu)
          .then(() => {
            Swal.fire("", "Data successfully deleted", "success");
            loadData();

            if (idParent) {
              getChildByParentService(idParent)
                .then((res) => {
                  setMenuChild(res.data);
                })
                .catch((res) => {
                  if (res.request.status === 404) {
                    setMenuChild([]);
                  }
                });
            }
          })
          .catch(() => {
            Swal.fire({
              icon: "error",
              title: "",
              text: "Data was not successfully deleted",
            });
          });
      }
    });
  };

  const handleChangePage = (_, v) => {
    setPage(v);
  };

  const handleChangeTakePage = (e) => {
    setTakePage(e.target.value);
  };

  const handleChangeSearch = (e) => {
    setMenuName(e.target.value);
  };

  const handleSearch = (userName) => {
    setMenuName(userName);
    loadData();
  };

  const handleBtnChild = (item) => {
    setOpen((prevOpen) => (prevOpen === item.id ? null : item.id));

    if (item.child.length > 0) {
      getChildByParentService(item.id)
        .then((res) => {
          setMenuChild(res.data);
        })
        .catch((res) => {
          if (res.request.status === 404) {
            setMenuChild([]);
          }
        });
    } else {
      setMenuChild([]);
    }
  };

  return {
    menuName,
    menuChild,
    takePage,
    page,
    dataAllMenu,
    open,
    navigation,
    handleChangeSearch,
    handleSearch,
    handleBtnChild,
    handleChangePage,
    handleChangeTakePage,
    deleteMenus,
  };
};

export { MenuLogic };
