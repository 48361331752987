import { useCallback, useEffect, useState } from "react";
import Swal from "sweetalert2";
import {
  deleteCostCenterService,
  getAllCostCenter,
} from "../../../../services/apiService/apiSetup/masterCostCenter/ServiceApiCostCenter";
import { useNavigate } from "react-router-dom";

export const LogicContainer = () => {
  const navigation = useNavigate();

  const [takePage, setTakePage] = useState(10);
  const [page, setPage] = useState(1);
  const [searchName, setSearchName] = useState("");
  const [dataCostCenter, setDataCostCenter] = useState([]);

  const handleChangePage = (_, v) => {
    setPage(v);
  };

  const handleChangeTakePage = (e) => {
    setTakePage(e.target.value);
  };

  const handleChangeSearch = (e) => {
    setSearchName(e.target.value);
  };

  const handleSearch = (costCenterName) => {
    setSearchName(costCenterName);
    loadData();
  };

  const loadData = useCallback(() => {
    getAllCostCenter(takePage, page, searchName)
      .then((res) => {
        const result = res.data;
        setDataCostCenter(result);
        if (res.data.data.length === 0) {
          setPage(1);
        }
      })
      .catch((res) => {
        if (res.request.status === 401) {
          navigation("/");
        }
      });
  }, [takePage, page, searchName, navigation]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const deleteCostCenter = (id) => {
    Swal.fire({
      title: "Are you sure to deleted ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#084298",
      cancelButtonColor: "#d33",
      confirmButtonText: "Delete",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteCostCenterService(id)
          .then(() => {
            loadData();
            Swal.fire("", "Data successfully deleted", "success");
          })
          .catch(() => {
            Swal.fire({
              icon: "error",
              title: "",
              text: "Data was not successfully deleted",
            });
          });
      }
    });
  };
  return {
    searchName,
    page,
    takePage,
    dataCostCenter,
    handleChangePage,
    handleChangeTakePage,
    deleteCostCenter,
    navigation,
    handleSearch,
    handleChangeSearch,
  };
};
