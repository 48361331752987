import { useCallback, useEffect, useState } from "react";
import {
  approveSendOtpService,
  getAllApproval,
  rejectSendNotifService,
  updateApproveService,
  updateRejectService,
} from "../../../../services/apiService/apiTransaction/approval/ServiceApiApproval";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

export const ApprovalsLogic = () => {
  const navigation = useNavigate();

  const storedData = localStorage.getItem("userData");
  const userData = JSON.parse(storedData);

  const [takePage, setTakePage] = useState(10);
  const [page, setPage] = useState(1);
  const [searchCodeTrx, setSearchCodeTrx] = useState("");
  const [open, setOpen] = useState(false);
  const [dataApproval, setDataApproval] = useState([]);

  const dataApproveOrReject = {
    approvedBy: userData.id,
    updatedBy: userData.id,
  };

  const handleChangePage = (_, v) => {
    setPage(v);
  };

  const handleChangeTakePage = (e) => {
    setTakePage(e.target.value);
  };

  const handleChangeSearch = (e) => {
    setSearchCodeTrx(e.target.value);
  };

  const handleSearch = (codeTrx) => {
    setSearchCodeTrx(codeTrx);
    loadData();
  };

  const loadData = useCallback(() => {
    getAllApproval(takePage, page, searchCodeTrx)
      .then((res) => {
        const result = res.data;
        setDataApproval(result);
        if (res.data.data.length === 0) {
          setPage(1);
        }
      })
      .catch((res) => {
        if (res.request.status === 401) {
          navigation("/");
        }
      });
  }, [takePage, page, searchCodeTrx, navigation]);

  useEffect(() => {
    loadData();
    const interval = setInterval(loadData, 7000);

    return () => clearInterval(interval);
  }, [loadData]);

  const updateApprove = (id, email, trxCode, unitNum) => {
    setOpen(true);
    updateApproveService(
      {
        approvedBy: dataApproveOrReject.approvedBy,
        updatedBy: dataApproveOrReject.updatedBy,
      },
      id
    )
      .then(() => {
        approveSendOtpService(email, trxCode, unitNum)
          .then(() => {
            setOpen(false);
            Swal.fire("", "Data successfully approve", "success");
            setTimeout(() => {
              loadData();
            }, 1000);
          })
          .catch(() => {
            setOpen(false);
            Swal.fire({
              icon: "error",
              title: "",
              text: "Failed to send otp code",
            });
          });
      })
      .catch(() => {
        setOpen(false);
        Swal.fire({
          icon: "error",
          title: "",
          text: "Data was not successfully approve",
        });
      });
  };

  const updateReject = (id, email, trxCode, unitNum) => {
    Swal.fire({
      title: "Are you sure to rejected ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#084298",
      cancelButtonColor: "#d33",
      confirmButtonText: "Reject",
    }).then((result) => {
      if (result.isConfirmed) {
        setOpen(true);
        updateRejectService(
          {
            approvedBy: dataApproveOrReject.approvedBy,
            updatedBy: dataApproveOrReject.updatedBy,
          },
          id
        )
          .then(() => {
            rejectSendNotifService(email, trxCode, unitNum)
              .then(() => {
                setOpen(false);
                Swal.fire("", "Data successfully rejected", "success");
                setTimeout(() => {
                  loadData();
                }, 1000);
              })
              .catch(() => {
                setOpen(false);
                Swal.fire({
                  icon: "error",
                  title: "",
                  text: "Failed to send notification",
                });
              });
          })
          .catch(() => {
            setOpen(false);
            Swal.fire({
              icon: "error",
              title: "",
              text: "Data was not successfully rejected",
            });
          });
      }
    });
  };

  return {
    dataApproval,
    takePage,
    page,
    open,
    searchCodeTrx,
    handleSearch,
    handleChangePage,
    handleChangeSearch,
    handleChangeTakePage,
    updateApprove,
    updateReject,
  };
};
