import React, { useState, useEffect } from "react";
import {
  Container,
  Box,
  Typography,
  Alert,
  TextField,
  Button,
  Checkbox,
} from "@mui/material";
import { Row, Col } from "react-bootstrap";
import SideBars from "../../../components/SideBars";
import "../../../styles/setup/providerStyle/ProvidersUpdateFormStyle.css";
import { useLocation, useNavigate } from "react-router-dom";
import { updateProviderService } from "../../../services/apiService/apiSetup/masterProvider/ServiceApiProvider";
import Swal from "sweetalert2";

const ProvidersUpdateForm = () => {
  const navigation = useNavigate();
  const location = useLocation();

  const storedData = localStorage.getItem("userData");
  const userData = JSON.parse(storedData);

  const [dataProvider, setDataProvider] = useState({
    providerCode: location.state.providerCode,
    providerName: location.state.providerName,
    desc: "",
    isActive: location.state.isActive,
    updatedBy: userData.id,
  });
  const [isError, setIsError] = useState("");
  const [isErrorCode, setIsErrorCode] = useState("");
  const [hideAlert, setHideAlert] = useState(false);
  const [disableBtn, setDisableBtn] = useState(false);

  const handleChange = (event) => {
    setDataProvider({
      ...dataProvider,
      [event.target.name]: event.target.value,
    });
  };

  const handleChangeCode = (event) => {
    setDataProvider({
      ...dataProvider,
      providerCode: event.target.value,
    });

    if (dataProvider.providerCode.length >= 10) {
      setIsErrorCode("Input code with maximum 10 characters");
    } else {
      setIsErrorCode("");
    }
  };

  const handleChangeCheckbox = (event) => {
    setDataProvider({
      ...dataProvider,
      isActive: event.target.checked,
    });
  };

  const updateProviders = (
    { providerCode, providerName, desc, isActive, updatedBy },
    id
  ) => {
    updateProviderService(
      {
        providerCode:
          dataProvider.providerCode === ""
            ? providerCode
            : dataProvider.providerCode,
        providerName:
          dataProvider.providerName === ""
            ? providerName
            : dataProvider.providerName,
        desc: dataProvider.desc === "" ? desc : dataProvider.desc,
        isActive:
          dataProvider.isActive === "" ? isActive : dataProvider.isActive,
        updatedBy:
          dataProvider.updatedBy === "" ? updatedBy : dataProvider.updatedBy,
      },
      id
    )
      .then(() => {
        Swal.fire("Data updated successfully", "", "success");
        navigation("/master-type");
      })
      .catch((res) => {
        if (res.response.data.statusCode === 422) {
          setIsError(res.response.data.message);
          setHideAlert(true);
        } else {
          setIsError("Data was not updated successfully");
          setHideAlert(true);
        }
      });
  };

  useEffect(() => {
    if (
      dataProvider.providerCode === "" ||
      dataProvider.providerName === "" ||
      dataProvider.providerCode.length > 10
    ) {
      setDisableBtn(true);
    } else {
      setDisableBtn(false);
    }
  }, [dataProvider]);

  return (
    <>
      <Box>
        <SideBars />

        <Container maxWidth="lg" sx={{ marginBottom: "150px" }}>
          <h4 className="title-page-ccf">Form Master Type</h4>
          {hideAlert && <Alert severity="error">{isError}</Alert>}
          <Box className="box-cost-center-form">
            <Box align="left">
              <Row>
                <Col sm={6}>
                  <Typography sx={{ fontSize: "16px", m: 1 }}>
                    Code <span style={{ color: "red" }}>*</span>
                  </Typography>
                </Col>
                <Col sm={6}>
                  <Typography sx={{ fontSize: "12px", color: "red" }}>
                    {isErrorCode}
                  </Typography>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    sx={{ width: "100%" }}
                    size="small"
                    defaultValue={location.state.providerCode}
                    name="providerCode"
                    disabled={true}
                    onChange={handleChangeCode}
                  />
                </Col>
              </Row>

              <br />

              <Row>
                <Col sm={6}>
                  <Typography sx={{ fontSize: "16px", m: 1 }}>
                    Type Name <span style={{ color: "red" }}>*</span>
                  </Typography>
                </Col>
                <Col sm={6}>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    sx={{ width: "100%" }}
                    size="small"
                    defaultValue={location.state.providerName}
                    name="providerName"
                    onChange={handleChange}
                  />
                </Col>
              </Row>

              <br />

              <Row>
                <Col sm={6}>
                  <Typography sx={{ fontSize: "16px", m: 1 }}>
                    Description
                  </Typography>
                </Col>
                <Col sm={6}>
                  <TextField
                    id="outlined-multiline-static"
                    multiline
                    rows={4}
                    defaultValue={location.state.desc}
                    name="desc"
                    onChange={handleChange}
                    sx={{ width: "100%" }}
                  />
                </Col>
              </Row>

              <br />

              <Row>
                <Col>
                  <Typography sx={{ fontSize: "16px", m: 1 }}>
                    Active
                  </Typography>
                </Col>
                <Col>
                  <Checkbox
                    sx={{ marginLeft: "-12px" }}
                    defaultChecked={location.state.isActive}
                    name="isActive"
                    onChange={handleChangeCheckbox}
                  />
                </Col>
              </Row>
            </Box>

            <br />

            <Box align="right">
              <Row>
                <Col>
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: "#436FB1",
                      marginTop: "10px",
                    }}
                    onClick={() => navigation("/master-type")}
                  >
                    Back To List
                  </Button>

                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: "#084298",
                      marginTop: "10px",
                      marginLeft: "10px",
                    }}
                    onClick={() =>
                      updateProviders(location.state, location.state.id)
                    }
                    disabled={disableBtn ? true : false}
                  >
                    Save
                  </Button>
                </Col>
              </Row>
            </Box>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default ProvidersUpdateForm;
