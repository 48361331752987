import axios from "axios";

const getAllApproval = async (limit = 10, page = 1, trxNum = "") => {
  const storedData = localStorage.getItem("userData");
  const userData = JSON.parse(storedData);
  const token = !userData ? "" : userData.token;

  const request = await axios({
    method: "get",
    url: `${process.env.REACT_APP_URL_TCM}/api/ebbm-request/dataFilters?limit=${limit}&page=${page}&TrxNum=${trxNum}&isApproved=false&isDone=false`,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return request;
};

const updateApproveService = async (form, id) => {
  const json = JSON.stringify(form);
  const storedData = localStorage.getItem("userData");
  const userData = JSON.parse(storedData);
  const token = !userData ? "" : userData.token;

  const request = await axios.put(
    `${process.env.REACT_APP_URL_TCM}/api/ebbm-request/approve/${id}`,
    json,
    {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return request;
};

const updateRejectService = async (form, id) => {
  const json = JSON.stringify(form);
  const storedData = localStorage.getItem("userData");
  const userData = JSON.parse(storedData);
  const token = !userData ? "" : userData.token;

  const request = await axios.put(
    `${process.env.REACT_APP_URL_TCM}/api/ebbm-request/reject/${id}`,
    json,
    {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return request;
};

const approveSendOtpService = async (email, trxCode, unitNum) => {
  const json = JSON.stringify();
  const storedData = localStorage.getItem("userData");
  const userData = JSON.parse(storedData);
  const token = !userData ? "" : userData.token;

  const request = await axios.post(
    `${process.env.REACT_APP_URL_TCM}/api/email/send-otp?toEmail=${email}&trxCode=${trxCode}&unitNum=${unitNum}`,
    json,
    {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return request;
};

const rejectSendNotifService = async (email, trxCode, unitNum) => {
  const json = JSON.stringify();
  const storedData = localStorage.getItem("userData");
  const userData = JSON.parse(storedData);
  const token = !userData ? "" : userData.token;

  const request = await axios.post(
    `${process.env.REACT_APP_URL_TCM}/api/email/reject-request?toEmail=${email}&trxCode=${trxCode}&unitNum=${unitNum}`,
    json,
    {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return request;
};

export {
  getAllApproval,
  updateApproveService,
  updateRejectService,
  approveSendOtpService,
  rejectSendNotifService,
};
