import React from "react";
import {
  Box,
  Container,
  Typography,
  Grid,
  TextField,
  Button,
} from "@mui/material";
import SideBars from "../../../components/SideBars";
import "../../../styles/report/logMasterData/LogMasterDataStyle.css";
import TableLogMasterData from "./components/TableLogMasterData";
import PaginationLogMasterData from "./components/PaginationLogMasterData";
import { LogMasterDataLogic } from "./logic/LogMasterDataLogic";

const LogMasterData = () => {
  const {
    handleChange,
    handleChangePage,
    handleChangeTakePage,
    handleSearch,
    dataLogMasterData,
    takePage,
    page,
    theme,
    filterApi,
  } = LogMasterDataLogic();

  if (!dataLogMasterData.data) {
    return null;
  }

  return (
    <>
      <Box>
        <SideBars />

        <Container maxWidth="xl" sx={{ marginBottom: "150px" }}>
          <h3 className="title-page-fr">Log Activity - Master Data</h3>
          <Box
            sx={{
              padding: "30px",
              borderRadius: "10px",
              border: "3px solid #e9e7e7",
            }}
          >
            <Grid container spacing={3}>
              <Grid item lg sx={{ minWidth: "200px" }}>
                <Typography sx={{ fontSize: "16px" }}>Start Date</Typography>

                <TextField
                  variant="outlined"
                  size="small"
                  type="date"
                  value={filterApi.startDate}
                  name="startDate"
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>

              <Grid item lg sx={{ minWidth: "200px" }}>
                <Typography sx={{ fontSize: "16px" }}>End Date</Typography>

                <TextField
                  variant="outlined"
                  size="small"
                  type="date"
                  value={filterApi.endDate}
                  name="endDate"
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
              <Grid item lg />
              <Grid item lg />
            </Grid>

            <Grid container spacing={3} sx={{ marginTop: "5px" }}>
              <Grid item lg sx={{ minWidth: "200px" }}>
                <Typography sx={{ fontSize: "16px" }}>User</Typography>

                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  value={filterApi.createdBy}
                  name="createdBy"
                  size="small"
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>

              <Grid item lg sx={{ minWidth: "200px" }}>
                <Typography sx={{ fontSize: "16px" }}>Information</Typography>

                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  value={filterApi.action}
                  name="action"
                  size="small"
                  onChange={handleChange}
                  fullWidth
                  sx={{
                    [theme.breakpoints.up("lg")]: {
                      width: "500px",
                    },
                  }}
                />
              </Grid>

              <Grid item lg />
              <Grid item lg />
            </Grid>

            <Grid container spacing={3}>
              <Grid item lg sx={{ minWidth: "200px" }}>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "#084298",
                    fontSize: "16px",
                    textTransform: "none",
                    marginTop: 3,
                  }}
                  onClick={() =>
                    handleSearch(
                      filterApi.startDate,
                      filterApi.endDate,
                      filterApi.action,
                      filterApi.createdBy
                    )
                  }
                  fullWidth
                >
                  Find
                </Button>
              </Grid>
              <Grid item lg />
              <Grid item lg />
              <Grid item lg />
            </Grid>
          </Box>

          <Box
            sx={{
              borderBottom: "1px solid #E0E0E0",
              marginTop: "20px",
              marginBottom: "20px",
            }}
          ></Box>

          <Box>
            <TableLogMasterData
              takePage={takePage}
              page={page}
              dataLogMasterData={dataLogMasterData}
            />
          </Box>

          <Box sx={{ display: "flex", flexWrap: "wrap", marginTop: "20px" }}>
            <PaginationLogMasterData
              handleChangeTakePage={handleChangeTakePage}
              handleChangePage={handleChangePage}
              takePage={takePage}
              page={page}
              dataLogMasterData={dataLogMasterData}
            />
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default LogMasterData;
