const initialState = {
  dataVehicleType: [],
};

const GetVehicleTypes = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ALL_VEHICLE_TYPE":
      return { ...state, dataVehicleType: action.data };

    default:
      return state;
  }
};

export default GetVehicleTypes;
