import React, { Fragment } from "react";
import {
  Grid,
  Typography,
  Box,
  Button,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  Paper,
  styled,
  createTheme,
  ThemeProvider,
  TextField,
  TableRow,
  Tab,
  Autocomplete,
} from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import EditIcon from "@mui/icons-material/Create";
import DeleteIcon from "@mui/icons-material/Delete";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#8FD0EF",
    color: "#484848",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: "#F0F0F0",
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const theme2 = createTheme({
  components: {
    MuiTab: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            color: "#4DD4AC",
          },
        },
      },
    },
  },
});

export default function TableUserAuthor(props) {
  const {
    createNewUserAuthor,
    deleteAuthEntity,
    deleteAuthDivision,
    deleteAuthDept,
    deleteAuthSection,
    handleChangeEntity,
    handleChangeDiv,
    handleChangeDept,
    handleChangeSection,
    handleBtnPermission,
    handleTabList,
    valueTabList,
    getAuthDiv,
    getAuthDept,
    getAuthSection,
    authEntity,
    authDept,
    authDivision,
    authSection,
    dataUsers,
    dataEntity,
    dataDivision,
    dataDepartment,
    dataSection,
    disableBtn,
    open,
    takePage,
    page,
    selectValue,
  } = props;

  const fmt = new Intl.DateTimeFormat("en-GB", {
    year: "2-digit",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  });

  return (
    <>
      <TableContainer component={Paper} className="table-container">
        <Table
          sx={{ minWidth: 700 }}
          aria-label="customized table"
          className="table-approval"
        >
          <TableHead>
            <TableRow>
              <StyledTableCell sx={{ fontWeight: "bold" }}>#</StyledTableCell>
              <StyledTableCell sx={{ fontWeight: "bold" }}>
                Actions
              </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: "bold" }}>
                Username
              </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: "bold" }}>
                Phone Number
              </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: "bold" }}>
                Email
              </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: "bold" }}>
                Role
              </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: "bold" }}>
                Active
              </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: "bold" }}>
                Date Created
              </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: "bold" }}>
                Date Updated
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataUsers.data.map((item, index) => (
              <Fragment key={item.id}>
                <StyledTableRow>
                  <StyledTableCell>
                    {(page - 1) * takePage + index + 1}
                  </StyledTableCell>
                  <StyledTableCell sx={{ minWidth: "80px" }}>
                    <Button
                      variant="contained"
                      sx={{
                        backgroundColor: "#DBFFF4",
                        color: "#484848",
                        textTransform: "none",
                        borderRadius: "10px",
                        ":hover": {
                          backgroundColor: "white",
                        },
                      }}
                      onClick={() => handleBtnPermission(item)}
                    >
                      <EditIcon className="icon-edit" />
                      Permissions
                    </Button>
                  </StyledTableCell>
                  <StyledTableCell>{item.nama}</StyledTableCell>
                  <StyledTableCell>{item.noTelp}</StyledTableCell>
                  <StyledTableCell>{item.email}</StyledTableCell>
                  <StyledTableCell>{item.role.roleName}</StyledTableCell>
                  <StyledTableCell>
                    {item.isAktif ? "Active" : "Not Active"}
                  </StyledTableCell>
                  <StyledTableCell>
                    {item.createdAt === null
                      ? "-"
                      : fmt.format(new Date(item.createdAt))}
                  </StyledTableCell>
                  <StyledTableCell>
                    {item.updatedAt === null
                      ? "-"
                      : fmt.format(new Date(item.updatedAt))}
                  </StyledTableCell>
                </StyledTableRow>

                {open === item.id && (
                  <StyledTableRow>
                    <StyledTableCell
                      style={{
                        backgroundColor: "white",
                      }}
                      colSpan={9}
                    >
                      <Box sx={{ margin: 2 }}>
                        <Typography variant="h6" gutterBottom>
                          Data Detail
                        </Typography>

                        <TabContext value={valueTabList}>
                          <Box
                            sx={{
                              border: 1,
                              borderColor: "divider",
                            }}
                          >
                            <ThemeProvider theme={theme2}>
                              <TabList
                                onChange={handleTabList}
                                aria-label="lab API tabs example"
                                TabIndicatorProps={{
                                  style: {
                                    backgroundColor: "#4DD4AC",
                                  },
                                }}
                              >
                                <Tab
                                  label="Entity"
                                  value="Entity"
                                  sx={{
                                    textTransform: "none",
                                  }}
                                />
                                <Tab
                                  label="Division"
                                  value="Division"
                                  sx={{
                                    textTransform: "none",
                                  }}
                                  onClick={() => getAuthDiv()}
                                />
                                <Tab
                                  label="Department"
                                  value="Department"
                                  sx={{
                                    textTransform: "none",
                                  }}
                                  onClick={() => getAuthDept()}
                                />
                                <Tab
                                  label="Section"
                                  value="Section"
                                  sx={{
                                    textTransform: "none",
                                  }}
                                  onClick={() => getAuthSection()}
                                />
                              </TabList>
                            </ThemeProvider>
                          </Box>
                          <Box
                            sx={{
                              border: 1,
                              borderColor: "divider",
                            }}
                          >
                            <TabPanel value="Entity" sx={{ margin: 2 }}>
                              <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                                spacing={2}
                              >
                                <Typography
                                  sx={{
                                    fontSize: "16px",
                                    paddingTop: 1,
                                    marginRight: "10px",
                                  }}
                                >
                                  Entity
                                </Typography>

                                <Autocomplete
                                  disablePortal
                                  options={dataEntity}
                                  getOptionLabel={(dataEntity) =>
                                    dataEntity.name === undefined
                                      ? ""
                                      : dataEntity.name
                                  }
                                  onChange={handleChangeEntity}
                                  size="small"
                                  value={selectValue}
                                  sx={{
                                    marginRight: "10px",
                                    width: "300px",
                                  }}
                                  renderInput={(params) => (
                                    <TextField {...params} />
                                  )}
                                  isOptionEqualToValue={(option, value) =>
                                    option.id === value.id
                                  }
                                />

                                <Button
                                  variant="contained"
                                  sx={{
                                    backgroundColor: "#084298",
                                    fontSize: "16px",
                                    textTransform: "none",
                                    paddingTop: 1,
                                  }}
                                  size="small"
                                  disabled={disableBtn}
                                  onClick={() => createNewUserAuthor()}
                                >
                                  Add
                                </Button>
                              </Grid>

                              <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                                spacing={2}
                                sx={{ marginTop: "10px" }}
                              >
                                <Table
                                  size="small"
                                  aria-label="purchases"
                                  sx={{
                                    marginTop: "20px",
                                    border: 1,
                                    borderColor: "#e0e0e0",
                                  }}
                                >
                                  <TableHead>
                                    <TableRow>
                                      <TableCell width="30px">No</TableCell>
                                      <TableCell>Entity</TableCell>
                                      <TableCell align="right">
                                        Action
                                      </TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {authEntity.map((data, index) => (
                                      <TableRow key={data.id}>
                                        <TableCell width="30px">
                                          {index + 1}
                                        </TableCell>
                                        <TableCell>{data.name}</TableCell>
                                        <TableCell align="right">
                                          <DeleteIcon
                                            className="icon-delete"
                                            onClick={() =>
                                              deleteAuthEntity(item.id, data.id)
                                            }
                                          />
                                        </TableCell>
                                      </TableRow>
                                    ))}
                                  </TableBody>
                                </Table>
                              </Grid>
                            </TabPanel>

                            <TabPanel value="Division" sx={{ margin: 2 }}>
                              <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                                spacing={2}
                              >
                                <Typography
                                  sx={{
                                    fontSize: "16px",
                                    paddingTop: 1,
                                    marginRight: "10px",
                                  }}
                                >
                                  Division
                                </Typography>

                                <Autocomplete
                                  disablePortal
                                  options={dataDivision}
                                  getOptionLabel={(dataDivision) =>
                                    dataDivision.divisiNama === undefined
                                      ? ""
                                      : dataDivision.divisiNama
                                  }
                                  onChange={handleChangeDiv}
                                  size="small"
                                  value={selectValue}
                                  sx={{
                                    marginRight: "10px",
                                    width: "300px",
                                  }}
                                  renderInput={(params) => (
                                    <TextField {...params} />
                                  )}
                                  isOptionEqualToValue={(option, value) =>
                                    option.id === value.id
                                  }
                                />

                                <Button
                                  variant="contained"
                                  sx={{
                                    backgroundColor: "#084298",
                                    fontSize: "16px",
                                    textTransform: "none",
                                    paddingTop: 1,
                                  }}
                                  size="small"
                                  disabled={disableBtn}
                                  onClick={() => createNewUserAuthor()}
                                >
                                  Add
                                </Button>
                              </Grid>

                              <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                                spacing={2}
                                sx={{ marginTop: "10px" }}
                              >
                                <Table
                                  size="small"
                                  aria-label="purchases"
                                  sx={{
                                    marginTop: "20px",
                                    border: 1,
                                    borderColor: "#e0e0e0",
                                  }}
                                >
                                  <TableHead>
                                    <TableRow>
                                      <TableCell width="30px">No</TableCell>
                                      <TableCell>Division</TableCell>
                                      <TableCell align="right">
                                        Action
                                      </TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {authDivision.map((data, index) => (
                                      <TableRow key={data.id}>
                                        <TableCell width="30px">
                                          {index + 1}
                                        </TableCell>
                                        <TableCell>{data.divisiNama}</TableCell>
                                        <TableCell align="right">
                                          <DeleteIcon
                                            className="icon-delete"
                                            onClick={() =>
                                              deleteAuthDivision(
                                                item.id,
                                                data.id
                                              )
                                            }
                                          />
                                        </TableCell>
                                      </TableRow>
                                    ))}
                                  </TableBody>
                                </Table>
                              </Grid>
                            </TabPanel>

                            <TabPanel value="Department" sx={{ margin: 2 }}>
                              <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                                spacing={2}
                              >
                                <Typography
                                  sx={{
                                    fontSize: "16px",
                                    paddingTop: 1,
                                    marginRight: "10px",
                                  }}
                                >
                                  Department
                                </Typography>
                                <Autocomplete
                                  disablePortal
                                  options={dataDepartment}
                                  getOptionLabel={(dataDepartment) =>
                                    dataDepartment.deptName === undefined
                                      ? ""
                                      : dataDepartment.deptName
                                  }
                                  size="small"
                                  value={selectValue}
                                  onChange={handleChangeDept}
                                  sx={{
                                    marginRight: "10px",
                                    width: "300px",
                                  }}
                                  renderInput={(params) => (
                                    <TextField {...params} />
                                  )}
                                  isOptionEqualToValue={(option, value) =>
                                    option.id === value.id
                                  }
                                />
                                <Button
                                  variant="contained"
                                  sx={{
                                    backgroundColor: "#084298",
                                    fontSize: "16px",
                                    textTransform: "none",
                                    paddingTop: 1,
                                  }}
                                  size="small"
                                  disabled={disableBtn}
                                  onClick={() => createNewUserAuthor()}
                                >
                                  Add
                                </Button>
                              </Grid>

                              <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                                spacing={2}
                                sx={{ marginTop: "10px" }}
                              >
                                <Table
                                  size="small"
                                  aria-label="purchases"
                                  sx={{
                                    marginTop: "20px",
                                    border: 1,
                                    borderColor: "#e0e0e0",
                                  }}
                                >
                                  <TableHead>
                                    <TableRow>
                                      <TableCell width="30px">No</TableCell>
                                      <TableCell>Department</TableCell>
                                      <TableCell align="right">
                                        Action
                                      </TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {authDept.map((data, index) => (
                                      <TableRow key={data.id}>
                                        <TableCell width="30px">
                                          {index + 1}
                                        </TableCell>
                                        <TableCell>{data.deptName}</TableCell>
                                        <TableCell align="right">
                                          <DeleteIcon
                                            className="icon-delete"
                                            onClick={() =>
                                              deleteAuthDept(item.id, data.id)
                                            }
                                          />
                                        </TableCell>
                                      </TableRow>
                                    ))}
                                  </TableBody>
                                </Table>
                              </Grid>
                            </TabPanel>

                            <TabPanel value="Section" sx={{ margin: 2 }}>
                              <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                                spacing={2}
                              >
                                <Typography
                                  sx={{
                                    fontSize: "16px",
                                    paddingTop: 1,
                                    marginRight: "10px",
                                  }}
                                >
                                  Section
                                </Typography>
                                <Autocomplete
                                  disablePortal
                                  options={dataSection}
                                  getOptionLabel={(dataSection) =>
                                    dataSection.sectionName === undefined
                                      ? ""
                                      : dataSection.sectionName
                                  }
                                  size="small"
                                  value={selectValue}
                                  onChange={handleChangeSection}
                                  sx={{
                                    marginRight: "10px",
                                    width: "300px",
                                  }}
                                  renderInput={(params) => (
                                    <TextField {...params} />
                                  )}
                                  isOptionEqualToValue={(option, value) =>
                                    option.id === value.id
                                  }
                                />
                                <Button
                                  variant="contained"
                                  sx={{
                                    backgroundColor: "#084298",
                                    fontSize: "16px",
                                    textTransform: "none",
                                    paddingTop: 1,
                                  }}
                                  size="small"
                                  disabled={disableBtn}
                                  onClick={() => createNewUserAuthor()}
                                >
                                  Add
                                </Button>
                              </Grid>

                              <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                                spacing={2}
                                sx={{ marginTop: "10px" }}
                              >
                                <Table
                                  size="small"
                                  aria-label="purchases"
                                  sx={{
                                    marginTop: "20px",
                                    border: 1,
                                    borderColor: "#e0e0e0",
                                  }}
                                >
                                  <TableHead>
                                    <TableRow>
                                      <TableCell width="30px">No</TableCell>
                                      <TableCell>Section</TableCell>
                                      <TableCell align="right">
                                        Action
                                      </TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {authSection.map((data, index) => (
                                      <TableRow key={data.id}>
                                        <TableCell width="30px">
                                          {index + 1}
                                        </TableCell>
                                        <TableCell>
                                          {data.sectionName}
                                        </TableCell>
                                        <TableCell align="right">
                                          <DeleteIcon
                                            className="icon-delete"
                                            onClick={() =>
                                              deleteAuthSection(
                                                item.id,
                                                data.id
                                              )
                                            }
                                          />
                                        </TableCell>
                                      </TableRow>
                                    ))}
                                  </TableBody>
                                </Table>
                              </Grid>
                            </TabPanel>
                          </Box>
                        </TabContext>
                      </Box>
                    </StyledTableCell>
                  </StyledTableRow>
                )}
              </Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
