import React from "react";
import Modal from "@mui/material/Modal";
import CancelIcon from "@mui/icons-material/Cancel";
import { Box, Grid } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "3px solid #e9e7e7",
  borderRadius: "10px",
  boxShadow: 24,
};

export default function ModalIssuedTrx(props) {
  const { handleClose, dataOdo, open } = props;
  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="flex-start"
          >
            <CancelIcon
              sx={{
                textAlign: "right",
              }}
              onClick={handleClose}
            />
          </Grid>
          <img
            src={dataOdo}
            alt="Oddo"
            style={{
              width: "100%",
              height: "100%",
            }}
          />
        </Box>
      </Modal>
    </>
  );
}
