import React from "react";
import { Container, Box, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Alert from "@mui/material/Alert";
import SideBars from "../../components/SideBars";
import "../../styles/profile/ProfileUpdateFormStyle.css";
import { useState } from "react";
import { updatePasswordService } from "../../services/apiService/apiSetup/masterUser/ServiceApiUsername";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

const ProfileUpdateForm = (props) => {
  const navigation = useNavigate();

  const storedData = localStorage.getItem("userData");
  const userData = JSON.parse(storedData);

  const [dataUser, setDataUser] = useState({
    password: "",
    passwordConfirmation: "",
  });
  const [isError, setIsError] = useState("");
  const [isErrorPwd, setIsErrorPwd] = useState("");
  const [hideAlert, setHideAlert] = useState(false);
  const [hideFormPassword, setHideFormPassword] = useState(false);

  const handleChange = (event) => {
    setDataUser({
      ...dataUser,
      [event.target.name]: event.target.value,
    });
  };

  const handleChangePassword = (event) => {
    setHideFormPassword(event.target.checked);
  };

  const handlePasswordConfirmation = (event) => {
    setDataUser({
      ...dataUser,
      passwordConfirmation: event.target.value,
    });
    if (dataUser.password !== event.target.value) {
      setIsErrorPwd("Password confirmation does not match the password");
    } else {
      setIsErrorPwd("");
    }
  };

  const updatePassword = (id) => {
    if (dataUser.password !== dataUser.passwordConfirmation) {
      setIsError("Password confirmation does not match the password");
      setHideAlert(!hideAlert);
    } else if (
      dataUser.password === "" ||
      dataUser.passwordConfirmation === ""
    ) {
      setIsError("Password & password confirmation cannot be empty");
      setHideAlert(!hideAlert);
    } else {
      updatePasswordService(
        {
          password: dataUser.password,
          passwordConfirmation: dataUser.passwordConfirmation,
        },
        id
      )
        .then(() => {
          Swal.fire("Password updated successfully", "", "success");
        })
        .catch(() => {
          setIsError("Password was not updated successfully");
          setHideAlert(!hideAlert);
        });
    }
  };

  return (
    <>
      <Box>
        <SideBars />
        <Container maxWidth="lg" sx={{ marginBottom: "150px" }}>
          <h4 className="title-page-ccf">Profile</h4>
          {hideAlert && <Alert severity="error">{isError}</Alert>}

          <Box className="box-cost-center-form">
            <Box align="left">
              <Row>
                <Col sm={6}>
                  <Typography sx={{ fontSize: "16px", m: 1 }}>
                    Username
                  </Typography>
                </Col>
                <Col sm={6}>
                  <TextField
                    variant="outlined"
                    sx={{ width: "100%" }}
                    size="small"
                    defaultValue={userData.nama}
                    name="nama"
                    disabled={true}
                  />
                </Col>
              </Row>

              <br />

              <Row>
                <Col sm={6}>
                  <Typography sx={{ fontSize: "16px", m: 1 }}>
                    Phone Number
                  </Typography>
                </Col>
                <Col sm={6}>
                  <TextField
                    variant="outlined"
                    sx={{ width: "100%" }}
                    size="small"
                    defaultValue={userData.noTlp}
                    name="noTlp"
                    type="number"
                    disabled={true}
                  />
                </Col>
              </Row>

              <br />

              <Row>
                <Col sm={6}>
                  <Typography sx={{ fontSize: "16px", m: 1 }}>Email</Typography>
                </Col>
                <Col sm={6}>
                  <TextField
                    variant="outlined"
                    sx={{ width: "100%" }}
                    size="small"
                    defaultValue={userData.email}
                    name="email"
                    type="email"
                    disabled={true}
                  />
                </Col>
              </Row>

              <br />

              <Row>
                <Col>
                  <Typography sx={{ fontSize: "16px", m: 1 }}>
                    Change Password
                  </Typography>
                </Col>
                <Col>
                  <Checkbox
                    sx={{ marginLeft: "-12px" }}
                    name="isAktif"
                    onChange={handleChangePassword}
                  />
                </Col>
              </Row>

              <br />
              {hideFormPassword && (
                <Box>
                  <Row>
                    <Col sm={6}>
                      <Typography sx={{ fontSize: "16px", m: 1 }}>
                        Password
                      </Typography>
                    </Col>
                    <Col sm={6}>
                      <TextField
                        variant="outlined"
                        sx={{ width: "100%" }}
                        size="small"
                        name="password"
                        type="password"
                        onChange={handleChange}
                      />
                    </Col>
                  </Row>

                  <br />

                  <Row>
                    <Col sm={6}>
                      <Typography sx={{ fontSize: "16px", m: 1 }}>
                        Confirm Password
                      </Typography>
                    </Col>
                    <Col sm={6}>
                      <Typography sx={{ fontSize: "12px", color: "red" }}>
                        {isErrorPwd}
                      </Typography>
                      <TextField
                        variant="outlined"
                        sx={{ width: "100%" }}
                        size="small"
                        name="passwordConfirmation"
                        type="password"
                        onChange={handlePasswordConfirmation}
                      />
                    </Col>
                  </Row>
                </Box>
              )}
            </Box>

            <br />

            <Box align="right">
              <Row>
                <Col>
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: "#436FB1",
                      marginTop: "10px",
                    }}
                    onClick={() => navigation("/dashboard")}
                  >
                    Back To List
                  </Button>

                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: "#084298",
                      marginTop: "10px",
                      marginLeft: "10px",
                    }}
                    onClick={() => updatePassword(userData.id)}
                  >
                    Save
                  </Button>
                </Col>
              </Row>
            </Box>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default ProfileUpdateForm;
