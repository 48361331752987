import React from "react";
import {
  styled,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#8FD0EF",
    color: "#484848",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: "14",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: "#F0F0F0",
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function TableLogAuth(props) {
  const { dataLogAuth, takePage, page } = props;

  return (
    <div>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 900 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell sx={{ fontWeight: "bold" }}>#</StyledTableCell>
              <StyledTableCell sx={{ fontWeight: "bold" }}>
                Email
              </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: "bold" }}>
                Date
              </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: "bold" }}>
                Time
              </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: "bold" }}>
                Device
              </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: "bold" }}>
                IP Address
              </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: "bold" }}>
                Browser
              </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: "bold" }}>
                Information
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataLogAuth.data.map((item, index) => (
              <StyledTableRow key={item.id}>
                <StyledTableCell>
                  {(page - 1) * takePage + index + 1}
                </StyledTableCell>
                <StyledTableCell>{item.email}</StyledTableCell>
                <StyledTableCell>{item.dateLogin}</StyledTableCell>
                <StyledTableCell>{item.timeLogin}</StyledTableCell>
                <StyledTableCell>{item.device}</StyledTableCell>
                <StyledTableCell>{item.ip_address}</StyledTableCell>
                <StyledTableCell>{item.browser}</StyledTableCell>
                <StyledTableCell>{item.information}</StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
