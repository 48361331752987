import React, { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import SideBars from "../../../components/SideBars";
import "../../../styles/setup/vehicleStyle/VehicleStyle.css";
import {
  Container,
  Grid,
  Typography,
  Box,
  TextField,
  Button,
} from "@mui/material";
import {
  getAllVehicle,
  deleteVehicleService,
} from "../../../services/apiService/apiSetup/masterVehicle/ServiceApiVehicle";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import PaginationVehicle from "./components/PaginationVehicle";
import TableVehicle from "./components/TableVehicle";

const Vehicles = (props) => {
  const { GetVehicles, dataVehicles } = props;
  const navigation = useNavigate();

  const [takePage, setTakePage] = useState(10);
  const [page, setPage] = useState(1);
  const [searchUnitNum, setSearchUnitNum] = useState("");

  const handleChangePage = (_, v) => {
    setPage(v);
  };

  const handleChangeTakePage = (e) => {
    setTakePage(e.target.value);
  };

  const handleChangeSearch = (e) => {
    setSearchUnitNum(e.target.value);
  };

  const handleSearch = (unitNum) => {
    setSearchUnitNum(unitNum);
    loadData();
  };

  const loadData = useCallback(() => {
    getAllVehicle(takePage, page, searchUnitNum)
      .then((res) => {
        const result = res.data;
        GetVehicles(result);
        if (res.data.data.length === 0) {
          setPage(1);
        }
      })
      .catch((res) => {
        if (res.request.status === 401) {
          navigation("/");
        }
      });
  }, [takePage, page, searchUnitNum, GetVehicles, navigation]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const deleteVehicle = (id) => {
    Swal.fire({
      title: "Are you sure to deleted ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#084298",
      cancelButtonColor: "#d33",
      confirmButtonText: "Delete",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteVehicleService(id)
          .then(() => {
            loadData();
            Swal.fire("", "Data successfully deleted", "success");
          })
          .catch(() => {
            Swal.fire({
              icon: "error",
              title: "",
              text: "Data was not successfully deleted",
            });
          });
      }
    });
  };

  if (!dataVehicles.data) {
    return null;
  }


  return (
    <>
      <Box>
        <SideBars />
        <Container maxWidth="xl" sx={{ marginBottom: "150px" }}>
          <h3 className="title-page-vehicle">Master Vehicle</h3>
          <Box
            sx={{
              padding: "30px",
              borderRadius: "10px",
              border: "3px solid #e9e7e7",
            }}
          >
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={2}
            >
              <Grid>
                <Typography
                  sx={{ fontSize: "16px", paddingTop: 1, marginRight: "10px" }}
                >
                  Search by Unit Number
                </Typography>
              </Grid>
              <Grid>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  size="small"
                  sx={{ width: "300px" }}
                  onChange={handleChangeSearch}
                />
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={2}
              sx={{ marginTop: "10px" }}
            >
              <Grid>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "#6EA8FE",
                    minWidth: "200px",
                    fontSize: "16px",
                    textTransform: "none",
                  }}
                  size="small"
                  onClick={() => handleSearch(searchUnitNum)}
                >
                  Find
                </Button>
              </Grid>
            </Grid>
          </Box>

          <Box align="right" className="box-new-vehicle">
            <Button
              variant="contained"
              size="small"
              sx={{
                backgroundColor: "#084298",
                minWidth: "200px",
                marginBottom: "10px",
                fontSize: "16px",
                textTransform: "none",
              }}
              onClick={() => navigation("/form-master-vehicle")}
            >
              New Vehicle
            </Button>
          </Box>

          <Box>
            <TableVehicle
              deleteVehicle={deleteVehicle}
              dataVehicles={dataVehicles}
              takePage={takePage}
              page={page}
            />
          </Box>

          <Box sx={{ display: "flex", flexWrap: "wrap", marginTop: "20px" }}>
            <PaginationVehicle
              handleChangeTakePage={handleChangeTakePage}
              handleChangePage={handleChangePage}
              dataVehicles={dataVehicles}
              takePage={takePage}
              page={page}
            />
          </Box>
        </Container>
      </Box>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    dataVehicles: state.rGetAllVehicles.GetVehicles.dataVehicles,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    GetVehicles: (result) =>
      dispatch({ type: "GET_ALL_VEHICLE", data: result }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Vehicles);
