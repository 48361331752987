import axios from "axios";

const getAllCostCenter = async (take = 10, page = 1, costCenterName = "") => {
  const storedData = localStorage.getItem("userData");

  const userData = JSON.parse(storedData);
  const token = !userData ? "" : userData.token;

  const costCenters = await axios({
    method: "get",
    url: `${process.env.REACT_APP_URL_TCM}/api/cost-center?take=${take}&page=${page}&costCenterName=${costCenterName}`,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return costCenters;
};

const newCostCenterService = async (form) => {
  const json = JSON.stringify(form);
  const storedData = localStorage.getItem("userData");
  const userData = JSON.parse(storedData);
  const token = !userData ? "" : userData.token;

  const costCenter = await axios.post(
    `${process.env.REACT_APP_URL_TCM}/api/cost-center/new`,
    json,
    {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return costCenter;
};

const deleteCostCenterService = async (id) => {
  const storedData = localStorage.getItem("userData");
  const userData = JSON.parse(storedData);
  const token = !userData ? "" : userData.token;

  const costCenter = await axios.delete(
    `${process.env.REACT_APP_URL_TCM}/api/cost-center/${id}`,
    {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return costCenter;
};

const updateCostCenterService = async (form, id) => {
  const json = JSON.stringify(form);
  const storedData = localStorage.getItem("userData");
  const userData = JSON.parse(storedData);
  const token = !userData ? "" : userData.token;

  const costCenter = await axios.put(
    `${process.env.REACT_APP_URL_TCM}/api/cost-center/update/${id}`,
    json,
    {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return costCenter;
};

const getAllCompany = async () => {
  const storedData = localStorage.getItem("userData");

  const userData = JSON.parse(storedData);
  const token = !userData ? "" : userData.token;

  const costCenters = await axios({
    method: "get",
    url: `${process.env.REACT_APP_URL_TCM}/api/cost-center-entity/entity`,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return costCenters;
};

export {
  getAllCostCenter,
  newCostCenterService,
  deleteCostCenterService,
  updateCostCenterService,
  getAllCompany,
};
