import React, { useState, useEffect } from "react";
import {
  Container,
  Box,
  Typography,
  Alert,
  Button,
  Checkbox,
  TextField,
} from "@mui/material";
import { Row, Col } from "react-bootstrap";
import SideBars from "../../../components/SideBars";
import "../../../styles/setup/entityStyle/EntityCreateFormStyle.css";
import { newEntityService } from "../../../services/apiService/apiSetup/masterEntity/ServiceApiEntity";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

const EntityCreateForm = () => {
  const navigation = useNavigate();

  const storedData = localStorage.getItem("userData");
  const userData = JSON.parse(storedData);

  const [dataEntity, setDataEntity] = useState({
    code: "",
    name: "",
    desc: "",
    isActive: false,
    createdBy: userData.id,
  });
  const [isError, setIsError] = useState("");
  const [isErrorCode, setIsErrorCode] = useState("");
  const [hideAlert, setHideAlert] = useState(false);
  const [disableBtn, setDisableBtn] = useState(false);

  const handleChange = (event) => {
    setDataEntity({
      ...dataEntity,
      [event.target.name]: event.target.value,
    });
  };

  const handleChangeCode = (event) => {
    setDataEntity({
      ...dataEntity,
      code: event.target.value.trim(),
    });

    if (dataEntity.code.length >= 10) {
      setIsErrorCode("Input code with maximum 10 characters");
    } else {
      setIsErrorCode("");
    }
  };

  const handleChangeCheckbox = (event) => {
    setDataEntity({
      ...dataEntity,
      isActive: event.target.checked,
    });
  };

  const createNewEntity = () => {
    if (dataEntity.code === "") {
      setIsError("Entity code cannot be empty");
      setHideAlert(!hideAlert);
    } else if (dataEntity.name === "") {
      setIsError("Entity name cannot be empty");
      setHideAlert(!hideAlert);
    } else {
      newEntityService({
        code: dataEntity.code,
        name: dataEntity.name,
        desc: dataEntity.desc,
        isActive: dataEntity.isActive,
        createdBy: dataEntity.createdBy,
      })
        .then(() => {
          Swal.fire("Data added successfully", "", "success");
          navigation("/master-entity");
        })
        .catch((res) => {
          if (res.response.data.statusCode === 422) {
            setIsError(res.response.data.message);
            setHideAlert(true);
          } else {
            setIsError("Data was not added successfully");
            setHideAlert(true);
          }
        });
    }
  };

  useEffect(() => {
    if (
      dataEntity.code === "" ||
      dataEntity.name === "" ||
      dataEntity.code.length > 10
    ) {
      setDisableBtn(true);
    } else {
      setDisableBtn(false);
    }
  }, [dataEntity]);

  return (
    <>
      <Box>
        <SideBars />

        <Container maxWidth="lg" sx={{ marginBottom: "150px" }}>
          <h4 className="title-page-ccf">Form Master Entity</h4>
          {hideAlert && <Alert severity="error">{isError}</Alert>}

          <Box className="box-cost-center-form">
            <Box align="left">
              <Row>
                <Col sm={6}>
                  <Typography sx={{ fontSize: "16px", m: 1 }}>
                    Code <span style={{ color: "red" }}>*</span>
                  </Typography>
                </Col>
                <Col sm={6}>
                  <Typography sx={{ fontSize: "12px", color: "red" }}>
                    {isErrorCode}
                  </Typography>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    sx={{ width: "100%" }}
                    size="small"
                    name="code"
                    onChange={handleChangeCode}
                  />
                </Col>
              </Row>

              <br />

              <Row>
                <Col sm={6}>
                  <Typography sx={{ fontSize: "16px", m: 1 }}>
                    Name <span style={{ color: "red" }}>*</span>
                  </Typography>
                </Col>
                <Col sm={6}>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    sx={{ width: "100%" }}
                    size="small"
                    name="name"
                    onChange={handleChange}
                  />
                </Col>
              </Row>

              <br />

              <Row>
                <Col sm={6}>
                  <Typography sx={{ fontSize: "16px", m: 1 }}>
                    Description
                  </Typography>
                </Col>
                <Col sm={6}>
                  <TextField
                    id="outlined-multiline-static"
                    multiline
                    rows={4}
                    sx={{ width: "100%" }}
                    name="desc"
                    onChange={handleChange}
                  />
                </Col>
              </Row>

              <br />

              <Row>
                <Col>
                  <Typography sx={{ fontSize: "16px", m: 1 }}>
                    Active
                  </Typography>
                </Col>
                <Col>
                  <Checkbox
                    sx={{ marginLeft: "-12px" }}
                    name="isActive"
                    onChange={handleChangeCheckbox}
                  />
                </Col>
              </Row>
            </Box>

            <br />

            <Box align="right">
              <Row>
                <Col>
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: "#436FB1",
                      marginTop: "10px",
                    }}
                    onClick={() => navigation("/master-entity")}
                  >
                    Back To List
                  </Button>

                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: "#084298",
                      marginTop: "10px",
                      marginLeft: "10px",
                    }}
                    onClick={() => createNewEntity()}
                    disabled={disableBtn ? true : false}
                  >
                    Save
                  </Button>
                </Col>
              </Row>
            </Box>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default EntityCreateForm;
