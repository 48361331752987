import React from "react";
import {
  Paper,
  TableRow,
  TableHead,
  TableContainer,
  TableBody,
  Button,
  Table,
  styled,
} from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#8FD0EF",
    color: "#484848",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: "#F0F0F0",
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function TableApproval(props) {
  const { takePage, page, open, dataApproval, updateReject, updateApprove } =
    props;

  const fmt = new Intl.DateTimeFormat("en-GB", {
    year: "2-digit",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  });

  const handleStatusTrx = (approve, done) => {
    if (approve === false && done === false) {
      return "Pending";
    } else if (approve === false && done === true) {
      return "Rejected";
    } else if (approve === true && done === false) {
      return "Approved";
    } else if (approve === true && done === true) {
      return "Done";
    }
  };

  const handleBtnTrx = (approve, done, id, email, trxCode, unitNum) => {
    if (approve === false && done === false) {
      return (
        <>
          <Button
            variant="outlined"
            color="success"
            size="small"
            className="btn-submit"
            sx={{ textTransform: "none", marginRight: "5px" }}
            onClick={() => updateApprove(id, email, trxCode, unitNum)}
            disabled={open}
          >
            Approve
          </Button>
          <Button
            variant="outlined"
            color="error"
            size="small"
            className="btn-submit"
            sx={{ textTransform: "none" }}
            onClick={() => updateReject(id, email, trxCode, unitNum)}
            disabled={open}
          >
            Rejected
          </Button>
        </>
      );
    } else if (approve === false && done === true) {
      return (
        <>
          <Button
            variant="outlined"
            color="error"
            size="small"
            className="btn-submit"
            sx={{ textTransform: "none" }}
            disabled={true}
          >
            Rejected
          </Button>
        </>
      );
    } else if (approve === true && done === false) {
      return (
        <>
          <Button
            variant="outlined"
            color="success"
            size="small"
            className="btn-submit"
            sx={{ textTransform: "none" }}
            disabled={true}
          >
            Approve
          </Button>
        </>
      );
    } else if (approve === true && done === true) {
      return (
        <Button
          variant="outlined"
          color="success"
          size="small"
          className="btn-submit"
          sx={{ textTransform: "none" }}
          disabled={true}
        >
          Done
        </Button>
      );
    }
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell sx={{ fontWeight: "bold" }}>#</StyledTableCell>
              <StyledTableCell sx={{ fontWeight: "bold" }} align="center">
                Actions
              </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: "bold" }}>
                Code
              </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: "bold" }}>
                Status
              </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: "bold" }}>
                Request By
              </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: "bold" }}>
                Unit Number
              </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: "bold" }}>
                Qty (Ltr)
              </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: "bold" }}>
                Mileage (KM)
              </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: "bold" }}>
                Remarks
              </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: "bold" }}>
                User Created
              </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: "bold" }}>
                User Updated
              </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: "bold" }}>
                Date Created
              </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: "bold" }}>
                Date Updated
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataApproval.data.map((item, index) => (
              <StyledTableRow key={item.id}>
                <StyledTableCell>
                  {(page - 1) * takePage + index + 1}
                </StyledTableCell>
                <StyledTableCell align="center" sx={{ minWidth: "200px" }}>
                  {handleBtnTrx(
                    item.isApproved,
                    item.isDone,
                    item.id,
                    item.createdBy.email,
                    item.trxCode,
                    item.vehicleId.unitNum
                  )}
                </StyledTableCell>
                <StyledTableCell>{item.trxCode}</StyledTableCell>
                <StyledTableCell>
                  {handleStatusTrx(item.isApproved, item.isDone)}
                </StyledTableCell>
                <StyledTableCell>{item.requesterName}</StyledTableCell>
                <StyledTableCell>{item.vehicleId.unitNum}</StyledTableCell>
                <StyledTableCell>{item.requestQty}</StyledTableCell>
                <StyledTableCell>{item.requestMileage}</StyledTableCell>
                <StyledTableCell>{item.remark}</StyledTableCell>
                <StyledTableCell>
                  {item.createdBy === null ? "-" : item.createdBy.nama}
                </StyledTableCell>
                <StyledTableCell>
                  {item.updatedBy === null ? "-" : item.updatedBy.nama}
                </StyledTableCell>
                <StyledTableCell>
                  {item.createdAt === null
                    ? "-"
                    : fmt.format(new Date(item.createdAt))}
                </StyledTableCell>
                <StyledTableCell>
                  {item.updatedAt === null
                    ? "-"
                    : fmt.format(new Date(item.updatedAt))}
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
