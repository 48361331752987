import React, { useState, useEffect } from "react";
import {
  Container,
  Box,
  Typography,
  Alert,
  TextField,
  Checkbox,
  Button,
} from "@mui/material";
import { Row, Col } from "react-bootstrap";
import SideBars from "../../../components/SideBars";
import "../../../styles/setup/locationStyle/LocationsUpdateFormStyle.css";
import { useLocation, useNavigate } from "react-router-dom";
import { updateLocationService } from "../../../services/apiService/apiSetup/masterLocation/ServiceApiLocation";
import Swal from "sweetalert2";

const LocationsUpdateForm = () => {
  const navigation = useNavigate();
  const location = useLocation();

  const storedData = localStorage.getItem("userData");
  const userData = JSON.parse(storedData);

  const [dataLocation, setDataLocation] = useState({
    lokasiNama: location.state.lokasiNama,
    lokasiAddress: location.state.lokasiAddress,
    isActive: location.state.isActive,
    updatedBy: userData.id,
  });
  const [isError, setIsError] = useState("");
  const [hideAlert, setHideAlert] = useState(false);
  const [disableBtn, setDisableBtn] = useState(false);

  const handleChange = (event) => {
    setDataLocation({
      ...dataLocation,
      [event.target.name]: event.target.value,
    });
  };

  const handleChangeCheckbox = (event) => {
    setDataLocation({
      ...dataLocation,
      isActive: event.target.checked,
    });
  };

  const updateLocations = (
    { lokasiNama, lokasiAddress, isActive, updatedBy },
    id
  ) => {
    updateLocationService(
      {
        lokasiNama:
          dataLocation.lokasiNama === "" ? lokasiNama : dataLocation.lokasiNama,
        lokasiAddress:
          dataLocation.lokasiAddress === ""
            ? lokasiAddress
            : dataLocation.lokasiAddress,
        isActive:
          dataLocation.isActive === "" ? isActive : dataLocation.isActive,
        updatedBy:
          dataLocation.updatedBy === "" ? updatedBy : dataLocation.updatedBy,
      },
      id
    )
      .then(() => {
        Swal.fire("Data updated successfully", "", "success");
        navigation("/master-location");
      })
      .catch((res) => {
        if (res.response.data.statusCode === 422) {
          setIsError(res.response.data.message);
          setHideAlert(true);
        } else {
          setIsError("Data was not updated successfully");
          setHideAlert(true);
        }
      });
  };

  useEffect(() => {
    if (dataLocation.lokasiNama === "") {
      setDisableBtn(true);
    } else {
      setDisableBtn(false);
    }
  }, [dataLocation]);

  return (
    <>
      <Box>
        <SideBars />

        <Container maxWidth="lg" sx={{ marginBottom: "150px" }}>
          <h4 className="title-page-ccf">Form Master Location</h4>
          {hideAlert && <Alert severity="error">{isError}</Alert>}

          <Box className="box-cost-center-form">
            <Box align="left">
              <Row>
                <Col sm={6}>
                  <Typography sx={{ fontSize: "16px", m: 1 }}>
                    Code <span style={{ color: "red" }}>*</span>
                  </Typography>
                </Col>
                <Col sm={6}>
                  <TextField
                    id="outlined-basic"
                    type="number"
                    variant="outlined"
                    sx={{ width: "100%" }}
                    size="small"
                    defaultValue={location.state.lokasiCode}
                    name="lokasiCode"
                    disabled={true}
                  />
                </Col>
              </Row>

              <br />

              <Row>
                <Col sm={6}>
                  <Typography sx={{ fontSize: "16px", m: 1 }}>
                    Name <span style={{ color: "red" }}>*</span>
                  </Typography>
                </Col>
                <Col sm={6}>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    sx={{ width: "100%" }}
                    size="small"
                    defaultValue={location.state.lokasiNama}
                    name="lokasiNama"
                    onChange={handleChange}
                  />
                </Col>
              </Row>

              <br />

              <Row>
                <Col sm={6}>
                  <Typography sx={{ fontSize: "16px", m: 1 }}>
                    Address <span style={{ color: "red" }}>*</span>
                  </Typography>
                </Col>
                <Col sm={6}>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    sx={{ width: "100%" }}
                    size="small"
                    defaultValue={location.state.lokasiAddress}
                    name="lokasiAddress"
                    onChange={handleChange}
                  />
                </Col>
              </Row>

              <br />

              <Row>
                <Col>
                  <Typography sx={{ fontSize: "16px", m: 1 }}>
                    Active
                  </Typography>
                </Col>
                <Col>
                  <Checkbox
                    sx={{ marginLeft: "-12px" }}
                    defaultChecked={location.state.isActive}
                    name="isActive"
                    onChange={handleChangeCheckbox}
                  />
                </Col>
              </Row>
            </Box>

            <br />

            <Box align="right">
              <Row>
                <Col>
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: "#436FB1",
                      marginTop: "10px",
                    }}
                    onClick={() => navigation("/master-location")}
                  >
                    Back To List
                  </Button>

                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: "#084298",
                      marginTop: "10px",
                      marginLeft: "10px",
                    }}
                    onClick={() =>
                      updateLocations(location.state, location.state.id)
                    }
                    disabled={disableBtn ? true : false}
                  >
                    Save
                  </Button>
                </Col>
              </Row>
            </Box>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default LocationsUpdateForm;
