import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import packageJson from "../../package.json";

export default function FooterBar() {
  const { version } = packageJson;
  return (
    <Box
      sx={{
        backgroundColor: "#084298",
        opacity: 0.8,
        position: "fixed",
        left: 0,
        bottom: 0,
        width: "100%",
        padding: "10px",
      }}
    >
      <Grid
        container
        direction="row"
        justifyContent="space-around"
        alignItems="center"
      >
        <Typography></Typography>
        <Typography
          variant="subtitle1"
          gutterBottom
          sx={{ color: "white", textAlign: "center" }}
        >
          Copyright ©2023 PT. Cahaya Anugrah Firdaus, All rights reserved
        </Typography>
        <Typography variant="subtitle1" gutterBottom sx={{ color: "white" }}>
          Version {version}
        </Typography>
      </Grid>
    </Box>
  );
}
