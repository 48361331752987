import React from "react";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  Paper,
  styled,
  TableRow,
} from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from "@mui/icons-material/Create";
import { useNavigate } from "react-router-dom";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#8FD0EF",
    color: "#484848",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: "#F0F0F0",
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function TableRolePermission(props) {
  const { dataRole, takePage, page } = props;
  const navigation = useNavigate();

  return (
    <>
      <TableContainer component={Paper} className="table-container">
        <Table
          sx={{ minWidth: 700 }}
          aria-label="customized table"
          className="table-approval"
        >
          <TableHead>
            <TableRow>
              <StyledTableCell sx={{ fontWeight: "bold" }}>#</StyledTableCell>
              <StyledTableCell sx={{ fontWeight: "bold" }}>
                Actions
              </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: "bold" }}>
                Role Permission
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataRole.map((item, index) => (
              <StyledTableRow key={item.id}>
                <StyledTableCell sx={{ width: "80px" }}>
                  {(page - 1) * takePage + index + 1}
                </StyledTableCell>
                <StyledTableCell sx={{ width: "80px" }}>
                  <EditIcon
                    className="icon-edit"
                    onClick={() =>
                      navigation("/form-role-update", {
                        state: item,
                      })
                    }
                  />
                  <VisibilityIcon
                    sx={{ color:"#3161A7" }}
                    onClick={() =>
                      navigation("/form-permission", {
                        state: item,
                      })
                    }
                  />
                </StyledTableCell>
                <StyledTableCell>{item.roleName}</StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
