import axios from "axios";

const getAllProviders = async (take = 10, page = 1, providerName = "") => {
  const storedData = localStorage.getItem("userData");
  const userData = JSON.parse(storedData);
  const token = !userData ? "" : userData.token;

  const getProviders = await axios({
    method: "get",
    url: `${process.env.REACT_APP_URL_TCM}/api/provider?take=${take}&page=${page}&providerName=${providerName}`,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return getProviders;
};

const newProviderService = async (form) => {
  const json = JSON.stringify(form);
  const storedData = localStorage.getItem("userData");
  const userData = JSON.parse(storedData);
  const token = !userData ? "" : userData.token;

  const providers = await axios.post(
    `${process.env.REACT_APP_URL_TCM}/api/provider/new`,
    json,
    {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return providers;
};

const deleteProviderService = async (id) => {
  const storedData = localStorage.getItem("userData");
  const userData = JSON.parse(storedData);
  const token = !userData ? "" : userData.token;

  const provider = await axios.delete(
    `${process.env.REACT_APP_URL_TCM}/api/provider/${id}`,
    {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return provider;
};

const updateProviderService = async (form, id) => {
  const json = JSON.stringify(form);
  const storedData = localStorage.getItem("userData");
  const userData = JSON.parse(storedData);
  const token = !userData ? "" : userData.token;

  const provider = await axios.put(
    `${process.env.REACT_APP_URL_TCM}/api/provider/update/${id}`,
    json,
    {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return provider;
};

export {
  getAllProviders,
  newProviderService,
  deleteProviderService,
  updateProviderService,
};
