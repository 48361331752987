import React, { useState } from "react";
import LogoEbbm from "../../assets/loginAssets/LogoEbbm.png";
import BgLogin from "../../assets/loginAssets/BgLogin.jpg";
import Form from "react-bootstrap/Form";
import Button from "@mui/material/Button";
import Container from "react-bootstrap/Container";
import { useNavigate } from "react-router-dom";
import "../../styles/login/LoginStyle.css";
import { Grid, Typography } from "@mui/material";
import { userLoginService } from "../../services/apiService/apiLogin/ServiceApiLogin";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Logins = () => {
  const navigation = useNavigate();

  const [dataUser, setDataUser] = useState({
    username:"",
    password: "",
  });

  const handleChange = (event) => {
    const name = event.target.name;
    setDataUser({ ...dataUser, [name]: event.target.value });
  };

  const userLogin = () => {
    userLoginService({
      username: dataUser.username,
      password: dataUser.password,
    })
      .then((res) => {
        const result = res.data;
        localStorage.setItem("userData", JSON.stringify(result));
        toast.success("Login success", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });

        setTimeout(() => {
          navigation("/dashboard");
        }, 1000);
      })
      .catch(() => {
        toast.error("Wrong username or password", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      });
  };

  return (
    <Grid container className="container-login">
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Grid
        item
        md={7}
        sx={{
          backgroundImage: `url(${BgLogin})`,
          height: "100vh",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <img src={LogoEbbm} alt="logo ebbm" className="logo-ebbm" />

        <Typography
          sx={{
            color: "white",
            textAlign: "center",
            fontWeight: "bold",
            fontSize: "30px",
          }}
        >
          MELAK GROUP
        </Typography>
      </Grid>

      <Grid
        item
        md={5}
        container
        direction="column"
        justifyContent="space-around"
        alignItems="center"
        sx={{ padding: "20px" }}
      >
        <Container>
          <Typography variant="h3" gutterBottom sx={{ color: "#27248E" }}>
            Log in
          </Typography>
          <Form className="form-login">
            <Form.Group className="mb-3">
              <Form.Label>Username</Form.Label>
              <Form.Control
                type="text"
                placeholder="input your username"
                className="username"
                name="username"
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="input your password"
                className="password"
                name="password"
                onChange={handleChange}
              />
            </Form.Group>

            <Button
              variant="contained"
              className="btn-login"
              sx={{
                backgroundColor: "#084298",
                textTransform: "none",
                fontSize: "24px",
              }}
              onClick={() => userLogin()}
            >
              Login
            </Button>
          </Form>
        </Container>
      </Grid>
    </Grid>
  );
};

export default Logins;
