import React from "react";
import {
  Container,
  Box,
  Typography,
  Alert,
  TextField,
  Button,
  Autocomplete,
  Checkbox,
} from "@mui/material";
import { Row, Col } from "react-bootstrap";
import SideBars from "../../../components/SideBars";
import "../../../styles/transaction/fuelingRequestStyle/FuelingRequestCreateFormStyle.css";
import Loading from "../../../assets/approvalAssets/loading.gif";
import Modal from "@mui/material/Modal";
import { ToastContainer } from "react-toastify";
import { FuelingReqCreateLogic } from "./logic/FuelingReqCreateLogic";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "3px solid #e9e7e7",
  borderRadius: "10px",
  boxShadow: 24,
};

const FuelingRequestCreateForm = () => {
  const {
    open,
    isError,
    hideAlert,
    disabled,
    dataCostCenter,
    dataDepartment,
    dataDivision,
    dataEntity,
    dataSection,
    dataTransaction,
    dataVehicle,
    createNewRequest,
    navigation,
    handleChange,
    handleChangeCheckbox,
    handleCostCenterChange,
    handleDeptChange,
    handleDivChange,
    handleEntityChange,
    handleSectionChange,
    handleVehicleChange,
  } = FuelingReqCreateLogic();

  return (
    <>
      <Box>
        <SideBars />
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        <div>
          <Modal
            open={open}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{
              backgroundColor: "white",
              opacity: 0.98,
            }}
          >
            <Box sx={style}>
              <img
                src={Loading}
                alt="loading"
                style={{
                  height: "100px",
                }}
              />
            </Box>
          </Modal>
        </div>

        <Container maxWidth="lg" sx={{ marginBottom: "150px" }}>
          <h4 className="title-page-frf">Form Fueling Request</h4>
          {hideAlert && <Alert severity="error">{isError}</Alert>}
          <Box className="box-fueling-request-form">
            <Row>
              <Col sm={6}>
                <Typography sx={{ fontSize: "16px", m: 1 }}>
                  Entity <span style={{ color: "red" }}>*</span>
                </Typography>
              </Col>
              <Col sm={6}>
                <Autocomplete
                  disablePortal
                  options={dataEntity}
                  getOptionLabel={(dataEntity) =>
                    dataEntity.name === undefined ? "" : dataEntity.name
                  }
                  onChange={(_, value) => handleEntityChange(value)}
                  size="small"
                  fullWidth
                  renderInput={(params) => <TextField {...params} />}
                  value={
                    dataEntity.length > 0
                      ? dataEntity.find(
                          (item) => item.id === dataTransaction.entityId
                        ) || ""
                      : ""
                  }
                />
              </Col>
            </Row>

            <br />

            <Row>
              <Col sm={6}>
                <Typography sx={{ fontSize: "16px", m: 1 }}>
                  Division <span style={{ color: "red" }}>*</span>
                </Typography>
              </Col>
              <Col sm={6}>
                <Autocomplete
                  disablePortal
                  options={dataDivision}
                  getOptionLabel={(dataDivision) =>
                    dataDivision.divisiNama === undefined
                      ? ""
                      : dataDivision.divisiNama
                  }
                  onChange={(_, value) => handleDivChange(value)}
                  size="small"
                  fullWidth
                  renderInput={(params) => <TextField {...params} />}
                  value={
                    dataDivision.length > 0
                      ? dataDivision.find(
                          (item) => item.id === dataTransaction.divId
                        ) || ""
                      : ""
                  }
                />
              </Col>
            </Row>

            <br />

            <Row>
              <Col sm={6}>
                <Typography sx={{ fontSize: "16px", m: 1 }}>
                  Department <span style={{ color: "red" }}>*</span>
                </Typography>
              </Col>
              <Col sm={6}>
                <Autocomplete
                  disablePortal
                  options={dataDepartment}
                  getOptionLabel={(dataDepartment) =>
                    dataDepartment.deptName === undefined
                      ? ""
                      : dataDepartment.deptName
                  }
                  onChange={(_, value) => handleDeptChange(value)}
                  size="small"
                  fullWidth
                  renderInput={(params) => <TextField {...params} />}
                  value={
                    dataDepartment.find(
                      (item) => item.departemen_id === dataTransaction.deptId
                    ) || ""
                  }
                />
              </Col>
            </Row>

            <br />

            <Row>
              <Col sm={6}>
                <Typography sx={{ fontSize: "16px", m: 1 }}>
                  Section <span style={{ color: "red" }}>*</span>
                </Typography>
              </Col>
              <Col sm={6}>
                <Autocomplete
                  disablePortal
                  options={dataSection}
                  getOptionLabel={(dataSection) =>
                    dataSection.sectionName === undefined
                      ? ""
                      : dataSection.sectionName
                  }
                  onChange={(_, value) => handleSectionChange(value)}
                  size="small"
                  fullWidth
                  renderInput={(params) => <TextField {...params} />}
                  value={
                    dataSection.find(
                      (item) => item.section_id === dataTransaction.sectionId
                    ) || ""
                  }
                />
              </Col>
            </Row>

            <br />

            <Row>
              <Col sm={6}>
                <Typography sx={{ fontSize: "16px", m: 1 }}>
                  Cost Center <span style={{ color: "red" }}>*</span>
                </Typography>
              </Col>
              <Col sm={6}>
                <Autocomplete
                  disablePortal
                  options={dataCostCenter}
                  getOptionLabel={(dataCostCenter) =>
                    dataCostCenter.costCenterName === undefined
                      ? ""
                      : dataCostCenter.costCenterName
                  }
                  onChange={(_, value) => handleCostCenterChange(value)}
                  size="small"
                  fullWidth
                  renderInput={(params) => <TextField {...params} />}
                  value={
                    dataCostCenter.find(
                      (item) => item.id === dataTransaction.costCenterId
                    ) || ""
                  }
                />
              </Col>
            </Row>

            <br />

            <Row>
              <Col sm={6}>
                <Typography sx={{ fontSize: "16px", m: 1 }}>
                  All Unit Selection
                </Typography>
              </Col>
              <Col sm={6}>
                <Checkbox
                  sx={{ marginLeft: "-12px" }}
                  name="allUnit"
                  onChange={handleChangeCheckbox}
                />
                <span
                  style={{
                    fontSize: "12px",
                    fontWeight: "bold",
                    fontStyle: "italic",
                  }}
                >
                  (Please click check box, if you want request with another your
                  asset)
                </span>
              </Col>
            </Row>

            <br />

            <Row>
              <Col sm={6}>
                <Typography sx={{ fontSize: "16px", m: 1 }}>
                  Unit Number <span style={{ color: "red" }}>*</span>
                </Typography>
              </Col>
              <Col sm={6}>
                <Autocomplete
                  disablePortal
                  options={dataVehicle}
                  getOptionLabel={(dataVehicle) =>
                    dataVehicle.unitNum === undefined ? "" : dataVehicle.unitNum
                  }
                  onChange={(_, value) => handleVehicleChange(value)}
                  size="small"
                  fullWidth
                  renderInput={(params) => <TextField {...params} />}
                  value={
                    dataVehicle.find(
                      (item) => item.id === dataTransaction.vehicleId
                    ) || ""
                  }
                />
              </Col>
            </Row>

            <br />

            <Row>
              <Col sm={6}>
                <Typography sx={{ fontSize: "16px", m: 1 }}>
                  Requester Name <span style={{ color: "red" }}>*</span>
                </Typography>
              </Col>
              <Col sm={6}>
                <TextField
                  variant="outlined"
                  sx={{ width: "100%" }}
                  size="small"
                  name="requesterName"
                  onChange={handleChange}
                />
              </Col>
            </Row>

            <br />

            <Row>
              <Col sm={6}>
                <Typography sx={{ fontSize: "16px", m: 1 }}>
                  Qty (Liter) <span style={{ color: "red" }}>*</span>
                </Typography>
              </Col>
              <Col sm={6}>
                <TextField
                  variant="outlined"
                  sx={{ width: "100%" }}
                  type="number"
                  size="small"
                  name="requestQty"
                  onChange={handleChange}
                />
              </Col>
            </Row>

            <br />

            <Row>
              <Col sm={6}>
                <Typography sx={{ fontSize: "16px", m: 1 }}>
                  Mileage (KM) <span style={{ color: "red" }}>*</span>
                </Typography>
              </Col>
              <Col sm={6}>
                <TextField
                  variant="outlined"
                  sx={{ width: "100%" }}
                  type="number"
                  size="small"
                  name="requestMileage"
                  onChange={handleChange}
                />
              </Col>
            </Row>

            <br />

            <Row>
              <Col sm={6}>
                <Typography sx={{ fontSize: "16px", m: 1 }}>Remark</Typography>
              </Col>
              <Col sm={6}>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  sx={{ width: "100%" }}
                  size="small"
                  name="remark"
                  onChange={handleChange}
                />
              </Col>
            </Row>
          </Box>

          <br />

          <Box align="right">
            <Row>
              <Col>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "#436FB1",
                    marginTop: "10px",
                  }}
                  onClick={() => navigation("/fueling-request")}
                >
                  Back To List
                </Button>

                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "#084298",
                    marginTop: "10px",
                    marginLeft: "10px",
                  }}
                  disabled={disabled ? true : false}
                  onClick={() => createNewRequest()}
                >
                  Save
                </Button>
              </Col>
            </Row>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default FuelingRequestCreateForm;
