import { useCallback, useEffect, useState } from "react";
import { getAllRequest } from "../../../../services/apiService/apiTransaction/fuelingRequest/ServiceApiFuelingRequest";
import { useNavigate } from "react-router-dom";

export const FuelingReqLogic = () => {
  const navigation = useNavigate();

  const [takePage, setTakePage] = useState(10);
  const [page, setPage] = useState(1);
  const [searchCodeTrx, setSearchCodeTrx] = useState("");
  const [dataApproval, setDataApproval] = useState([]);

  const handleChangePage = (_, v) => {
    setPage(v);
  };

  const handleChangeTakePage = (e) => {
    setTakePage(e.target.value);
  };

  const handleChangeSearch = (e) => {
    setSearchCodeTrx(e.target.value);
  };

  const handleSearch = (codeTrx) => {
    setSearchCodeTrx(codeTrx);
    loadData();
  };

  const loadData = useCallback(() => {
    getAllRequest(takePage, page, searchCodeTrx)
      .then((res) => {
        const result = res.data;

        setDataApproval(result);
        if (res.data.data.length === 0) {
          setPage(1);
        }
      })
      .catch((res) => {
        if (res.request.status === 401) {
          navigation("/");
        }
      });
  }, [takePage, page, searchCodeTrx, navigation]);

  useEffect(() => {
    loadData();
    const interval = setInterval(loadData, 7000);

    return () => clearInterval(interval);
  }, [loadData]);

  return {
    dataApproval,
    takePage,
    page,
    searchCodeTrx,
    navigation,
    handleChangePage,
    handleChangeSearch,
    handleChangeTakePage,
    handleSearch,
  };
};
