import React, { useEffect, useState, useCallback } from "react";
import {
  Container,
  Box,
  Typography,
  Alert,
  TextField,
  Button,
  Checkbox,
  Autocomplete,
} from "@mui/material";
import { Row, Col } from "react-bootstrap";
import SideBars from "../../../components/SideBars";
import "../../../styles/setup/vehicleStyle/VehicleUpdateFormStyle.css";
import { useLocation, useNavigate } from "react-router-dom";
import { updateVehicleService } from "../../../services/apiService/apiSetup/masterVehicle/ServiceApiVehicle";
import { getAllProviders } from "../../../services/apiService/apiSetup/masterProvider/ServiceApiProvider";
import { getAllVehicleType } from "../../../services/apiService/apiSetup/masterVehicleType/ServiceApiVehicleType";
import { getAllEntity } from "../../../services/apiService/apiSetup/masterEntity/ServiceApiEntity";
import { getDivisionByEntityService } from "../../../services/apiService/apiSetup/masterDivision/ServiceApiDivision";
import { getDeptByDivService } from "../../../services/apiService/apiSetup/masterDepartment/ServiceApiDepartment";
import { getSectionByDeptService } from "../../../services/apiService/apiSetup/masterSection/ServiceApiSection";
import Swal from "sweetalert2";

const VehicleUpdateForm = () => {
  const navigation = useNavigate();
  const location = useLocation();
  const storedData = localStorage.getItem("userData");
  const userData = JSON.parse(storedData);

  const [dataVehicle, setDataVehicle] = useState({
    entity: location.state.vehicleSectionId.departemen.divisi.entity.id,
    division: location.state.vehicleSectionId.departemen.divisi.id,
    department: location.state.vehicleSectionId.departemen.id,
    vehicleSectionId: location.state.vehicleSectionId.id,
    unitNum: location.state.unitNum,
    vehicleProviderId: location.state.vehicleProviderId.id,
    platNo: location.state.platNo,
    vehicleTypeId: location.state.vehicleTypeId.id,
    assetNumber: location.state.assetNumber,
    isActive: location.state.isActive,
    updatedBy: userData.id,
  });
  const [isError, setIsError] = useState("");
  const [hideAlert, setHideAlert] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [dataEntity, setDataEntity] = useState([]);
  const [dataDivision, setDataDivision] = useState([]);
  const [dataDepartment, setDataDepartment] = useState([]);
  const [dataSection, setDataSection] = useState([]);
  const [dataProvider, setDataProvider] = useState([]);
  const [dataVehicleType, setDataVehicleType] = useState([]);
  const [defaultVehicle, setDefaultVehicle] = useState({
    entity: location.state.vehicleSectionId.departemen.divisi.entity.name,
    division: location.state.vehicleSectionId.departemen.divisi.divisiNama,
    department: location.state.vehicleSectionId.departemen.deptName,
    vehicleSectionId: location.state.vehicleSectionId.sectionName,
    unitNum: location.state.unitNum,
    vehicleProviderId: location.state.vehicleProviderId.providerName,
    platNo: location.state.platNo,
    vehicleTypeId: location.state.vehicleTypeId.vehicleTypeName,
    assetNumber: location.state.assetNumber,
    isActive: location.state.isActive,
  });
  const page = 1;
  const takePage = 0;

  const handleChange = (event) => {
    setDataVehicle({
      ...dataVehicle,
      [event.target.name]: event.target.value,
    });
  };

  const handleChangeCheckbox = (event) => {
    setDataVehicle({
      ...dataVehicle,
      isActive: event.target.checked,
    });
  };

  const getSectionByDept = useCallback((id) => {
    getSectionByDeptService(id)
      .then((res) => {
        const result = res.data;
        setDataSection(result);
      })
      .catch((res) => {
        if (res.request.status === 404) {
          setIsError("There are no section under the department");
          setHideAlert(true);
        }
      });
  }, []);

  const getDeptByDiv = useCallback(
    (id) => {
      getDeptByDivService(id)
        .then((res) => {
          const result = res.data;
          setDataDepartment(result);
          if (dataVehicle.department !== "") {
            getSectionByDept(dataVehicle.department);
          }
        })
        .catch((res) => {
          if (res.request.status === 404) {
            setIsError("There are no department under the division");
            setHideAlert(true);
          }
        });
    },
    [dataVehicle, getSectionByDept]
  );

  const getDivByEntity = useCallback(
    (id) => {
      getDivisionByEntityService(id)
        .then((res) => {
          const result = res.data;
          setDataDivision(result);
          if (dataVehicle.division !== "") {
            getDeptByDiv(dataVehicle.division);
          }
        })
        .catch((res) => {
          if (res.request.status === 404) {
            setIsError("There are no divisions under the entity");
            setHideAlert(true);
          }
        });
    },
    [dataVehicle, getDeptByDiv]
  );

  useEffect(() => {
    getAllEntity(takePage, page)
      .then((res) => {
        const result = res.data.data;
        setDataEntity(result);
        if (dataVehicle.entity !== "") {
          getDivByEntity(dataVehicle.entity);
        }
      })
      .catch(() => {});

    getAllProviders(takePage, page)
      .then((res) => {
        const result = res.data.data;
        setDataProvider(result);
      })
      .catch(() => {});

    getAllVehicleType(takePage, page)
      .then((res) => {
        const result = res.data.data;
        setDataVehicleType(result);
      })
      .catch(() => {});

    if (
      dataVehicle.entity === "" ||
      dataVehicle.division === "" ||
      dataVehicle.department === "" ||
      dataVehicle.vehicleSectionId === "" ||
      dataVehicle.unitNum === "" ||
      dataVehicle.vehicleProviderId === "" ||
      dataVehicle.platNo === "" ||
      dataVehicle.vehicleTypeId === ""
    ) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [dataVehicle, getDivByEntity]);

  const updateVehicles = (
    {
      vehicleSectionId,
      unitNum,
      vehicleProviderId,
      platNo,
      vehicleTypeId,
      assetNumber,
      isActive,
      updatedBy,
    },
    id
  ) => {
    updateVehicleService(
      {
        vehicleSectionId:
          dataVehicle.vehicleSectionId === ""
            ? vehicleSectionId
            : dataVehicle.vehicleSectionId,
        unitNum: dataVehicle.unitNum === "" ? unitNum : dataVehicle.unitNum,
        vehicleProviderId:
          dataVehicle.vehicleProviderId === ""
            ? vehicleProviderId
            : dataVehicle.vehicleProviderId,
        platNo: dataVehicle.platNo === "" ? platNo : dataVehicle.platNo,
        vehicleTypeId:
          dataVehicle.vehicleTypeId === ""
            ? vehicleTypeId
            : dataVehicle.vehicleTypeId,
        assetNumber:
          dataVehicle.assetNumber === ""
            ? assetNumber
            : dataVehicle.assetNumber,
        isActive: dataVehicle.isActive === "" ? isActive : dataVehicle.isActive,
        updatedBy:
          dataVehicle.updatedBy === "" ? updatedBy : dataVehicle.updatedBy,
      },
      id
    )
      .then(() => {
        Swal.fire("Data updated successfully", "", "success");
        navigation("/master-vehicle");
      })
      .catch((res) => {
        if (res.response.data.statusCode === 422) {
          setIsError(res.response.data.message);
          setHideAlert(true);
        } else {
          setIsError("Data was not updated successfully");
          setHideAlert(true);
        }
      });
  };

  const handleEntityChange = (value) => {
    setDataDivision([]);
    setDataDepartment([]);
    setDataSection([]);
    const idEntity = dataEntity.find((item) => item.name === value)?.id || "";
    if (value !== null) {
      setDataVehicle((dataVehicle) => ({
        ...dataVehicle,
        entity: idEntity,
        division: "",
        department: "",
        vehicleSectionId: "",
      }));
      setDefaultVehicle((defaultVehicle) => ({
        ...defaultVehicle,
        entity: value,
        division: "",
        department: "",
        vehicleSectionId: "",
      }));
      if (dataVehicle.division === "") {
        getDivByEntity(idEntity);
      }
    } else {
      setDataVehicle((dataVehicle) => ({
        ...dataVehicle,
        entity: "",
      }));
    }
  };

  const handleDivChange = (value) => {
    setDataDepartment([]);
    setDataSection([]);
    const idDiv =
      dataDivision.find((item) => item.divisiNama === value)?.id || "";
    if (value !== null) {
      setDataVehicle((dataVehicle) => ({
        ...dataVehicle,
        division: idDiv,
        department: "",
        vehicleSectionId: "",
      }));
      setDefaultVehicle((defaultVehicle) => ({
        ...defaultVehicle,
        division: value,
        department: "",
        vehicleSectionId: "",
      }));
      if (dataVehicle.department === "") {
        getDeptByDiv(idDiv);
      }
    } else {
      setDataVehicle((dataVehicle) => ({
        ...dataVehicle,
        division: "",
      }));
    }
  };

  const handleDeptChange = (value) => {
    setDataSection([]);
    const idDept =
      dataDepartment.find((item) => item.deptName === value)?.id || "";
    if (value !== null) {
      setDataVehicle((dataVehicle) => ({
        ...dataVehicle,
        department: idDept,
        vehicleSectionId: "",
      }));
      setDefaultVehicle((defaultVehicle) => ({
        ...defaultVehicle,
        department: value,
        vehicleSectionId: "",
      }));
      if (dataVehicle.vehicleSectionId === "") {
        getSectionByDept(idDept);
      }
    } else {
      setDataVehicle((dataVehicle) => ({
        ...dataVehicle,
        department: "",
      }));
    }
  };

  const handleSectionChange = (value) => {
    const idSection =
      dataSection.find((item) => item.sectionName === value)?.id || "";
    if (value !== null) {
      setDataVehicle((dataVehicle) => ({
        ...dataVehicle,
        vehicleSectionId: idSection,
      }));
      setDefaultVehicle((defaultVehicle) => ({
        ...defaultVehicle,
        vehicleSectionId: value,
      }));
    } else {
      setDataVehicle((dataVehicle) => ({
        ...dataVehicle,
        vehicleSectionId: "",
      }));
    }
  };

  const handleProviderChange = (value) => {
    const idProvider =
      dataProvider.find((item) => item.providerName === value)?.id || "";
    if (value !== null) {
      setDataVehicle((dataVehicle) => ({
        ...dataVehicle,
        vehicleProviderId: idProvider,
      }));
      setDefaultVehicle((defaultVehicle) => ({
        ...defaultVehicle,
        vehicleProviderId: value,
      }));
    } else {
      setDataVehicle((dataVehicle) => ({
        ...dataVehicle,
        vehicleProviderId: "",
      }));
    }
  };

  const handleVehicleTypeChange = (value) => {
    const idVehicleType =
      dataVehicleType.find((item) => item.vehicleTypeName === value)?.id || "";
    if (value !== null) {
      setDataVehicle((dataVehicle) => ({
        ...dataVehicle,
        vehicleTypeId: idVehicleType,
      }));
      setDefaultVehicle((defaultVehicle) => ({
        ...defaultVehicle,
        vehicleTypeId: value,
      }));
    } else {
      setDataVehicle((dataVehicle) => ({
        ...dataVehicle,
        vehicleTypeId: "",
      }));
    }
  };

  return (
    <>
      <Box>
        <SideBars />

        <Container maxWidth="lg" sx={{ marginBottom: "150px" }}>
          <h4 className="title-page-vf">Form Master Vehicle</h4>
          {hideAlert && <Alert severity="error">{isError}</Alert>}
          <Box className="box-vehicle-form">
            <Box align="left">
              <Row>
                <Col sm={6}>
                  <Typography sx={{ fontSize: "16px", m: 1 }}>
                    Entity <span style={{ color: "red" }}>*</span>
                  </Typography>
                </Col>
                <Col sm={6}>
                  <Autocomplete
                    disablePortal
                    options={dataEntity.map((item) => item.name)}
                    onChange={(_, value) => handleEntityChange(value)}
                    size="small"
                    fullWidth
                    value={defaultVehicle.entity}
                    renderInput={(params) => <TextField {...params} />}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                  />
                </Col>
              </Row>

              <br />

              <Row>
                <Col sm={6}>
                  <Typography sx={{ fontSize: "16px", m: 1 }}>
                    Division <span style={{ color: "red" }}>*</span>
                  </Typography>
                </Col>
                <Col sm={6}>
                  <Autocomplete
                    disablePortal
                    options={dataDivision.map((item) => item.divisiNama)}
                    onChange={(_, value) => handleDivChange(value)}
                    size="small"
                    fullWidth
                    value={defaultVehicle.division}
                    renderInput={(params) => <TextField {...params} />}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                  />
                </Col>
              </Row>

              <br />

              <Row>
                <Col sm={6}>
                  <Typography sx={{ fontSize: "16px", m: 1 }}>
                    Department <span style={{ color: "red" }}>*</span>
                  </Typography>
                </Col>
                <Col sm={6}>
                  <Autocomplete
                    disablePortal
                    options={dataDepartment.map((item) => item.deptName)}
                    onChange={(_, value) => handleDeptChange(value)}
                    size="small"
                    fullWidth
                    value={defaultVehicle.department}
                    renderInput={(params) => <TextField {...params} />}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                  />
                </Col>
              </Row>

              <br />

              <Row>
                <Col sm={6}>
                  <Typography sx={{ fontSize: "16px", m: 1 }}>
                    Section <span style={{ color: "red" }}>*</span>
                  </Typography>
                </Col>
                <Col sm={6}>
                  <Autocomplete
                    disablePortal
                    options={dataSection.map((item) => item.sectionName)}
                    onChange={(_, value) => handleSectionChange(value)}
                    size="small"
                    fullWidth
                    value={defaultVehicle.vehicleSectionId}
                    renderInput={(params) => <TextField {...params} />}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                  />
                </Col>
              </Row>

              <br />

              <Row>
                <Col sm={6}>
                  <Typography sx={{ fontSize: "16px", m: 1 }}>
                    Unit Number <span style={{ color: "red" }}>*</span>
                  </Typography>
                </Col>
                <Col sm={6}>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    sx={{ width: "100%" }}
                    size="small"
                    name="unitNum"
                    defaultValue={defaultVehicle.unitNum}
                    onChange={handleChange}
                  />
                </Col>
              </Row>

              <br />

              <Row>
                <Col sm={6}>
                  <Typography sx={{ fontSize: "16px", m: 1 }}>
                    Type <span style={{ color: "red" }}>*</span>
                  </Typography>
                </Col>
                <Col sm={6}>
                  <Autocomplete
                    disablePortal
                    options={dataProvider.map((item) => item.providerName)}
                    onChange={(_, value) => handleProviderChange(value)}
                    size="small"
                    fullWidth
                    value={defaultVehicle.vehicleProviderId}
                    renderInput={(params) => <TextField {...params} />}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                  />
                </Col>
              </Row>

              <br />

              <Row>
                <Col sm={6}>
                  <Typography sx={{ fontSize: "16px", m: 1 }}>
                    Plate Number <span style={{ color: "red" }}>*</span>
                  </Typography>
                </Col>
                <Col sm={6}>
                  <TextField
                    variant="outlined"
                    sx={{ width: "100%" }}
                    size="small"
                    defaultValue={defaultVehicle.platNo}
                    name="platNo"
                    onChange={handleChange}
                  />
                </Col>
              </Row>

              <br />

              <Row>
                <Col sm={6}>
                  <Typography sx={{ fontSize: "16px", m: 1 }}>
                    Type of Unit <span style={{ color: "red" }}>*</span>
                  </Typography>
                </Col>
                <Col sm={6}>
                  <Autocomplete
                    disablePortal
                    options={dataVehicleType.map(
                      (item) => item.vehicleTypeName
                    )}
                    onChange={(_, value) => handleVehicleTypeChange(value)}
                    size="small"
                    fullWidth
                    value={defaultVehicle.vehicleTypeId}
                    renderInput={(params) => <TextField {...params} />}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                  />
                </Col>
              </Row>

              <br />

              <Row>
                <Col sm={6}>
                  <Typography sx={{ fontSize: "16px", m: 1 }}>
                    Asset Number
                  </Typography>
                </Col>
                <Col sm={6}>
                  <TextField
                    variant="outlined"
                    sx={{ width: "100%" }}
                    size="small"
                    defaultValue={defaultVehicle.assetNumber}
                    name="assetNumber"
                    onChange={handleChange}
                  />
                </Col>
              </Row>

              <br />

              <Row>
                <Col>
                  <Typography sx={{ fontSize: "16px", m: 1 }}>
                    Active
                  </Typography>
                </Col>
                <Col>
                  <Checkbox
                    sx={{ marginLeft: "-12px" }}
                    defaultChecked={defaultVehicle.isActive}
                    name="isActive"
                    onChange={handleChangeCheckbox}
                  />
                </Col>
              </Row>
            </Box>

            <br />

            <Box align="right">
              <Row>
                <Col>
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: "#436FB1",
                      marginTop: "10px",
                    }}
                    onClick={() => navigation("/master-vehicle")}
                  >
                    Back To List
                  </Button>

                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: "#084298",
                      marginTop: "10px",
                      marginLeft: "10px",
                    }}
                    onClick={() =>
                      updateVehicles(location.state, location.state.id)
                    }
                    disabled={disabled ? true : false}
                  >
                    Save
                  </Button>
                </Col>
              </Row>
            </Box>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default VehicleUpdateForm;
