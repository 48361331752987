const initialState = {
  allDataMenu: [],
};

const GetMenus = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ALL_MENUS":
      return { ...state, allDataMenu: action.data };

    default:
      return state;
  }
};

export default GetMenus;
