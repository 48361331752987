import axios from "axios";

const getAllVehicle = async (take = 10, page = 1, unitNum = "") => {
  const storedData = localStorage.getItem("userData");
  const userData = JSON.parse(storedData);
  const token = !userData ? "" : userData.token;

  const vehicle = await axios({
    method: "get",
    url: `${process.env.REACT_APP_URL_TCM}/api/vehicle?take=${take}&page=${page}&unitNum=${unitNum}`,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return vehicle;
};

const newVehicleService = async (form) => {
  const json = JSON.stringify(form);
  const storedData = localStorage.getItem("userData");
  const userData = JSON.parse(storedData);
  const token = !userData ? "" : userData.token;

  const vehicle = await axios.post(
    `${process.env.REACT_APP_URL_TCM}/api/vehicle/new`,
    json,
    {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return vehicle;
};

const deleteVehicleService = async (id) => {
  const storedData = localStorage.getItem("userData");
  const userData = JSON.parse(storedData);
  const token = !userData ? "" : userData.token;

  const vehicle = await axios.delete(
    `${process.env.REACT_APP_URL_TCM}/api/vehicle/${id}`,
    {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return vehicle;
};

const updateVehicleService = async (form, id) => {
  const json = JSON.stringify(form);
  const storedData = localStorage.getItem("userData");
  const userData = JSON.parse(storedData);
  const token = !userData ? "" : userData.token;

  const vehicle = await axios.put(
    `${process.env.REACT_APP_URL_TCM}/api/vehicle/update/${id}`,
    json,
    {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return vehicle;
};

const getVehicleById = async (id) => {
  const storedData = localStorage.getItem("userData");
  const userData = JSON.parse(storedData);
  const token = !userData ? "" : userData.token;

  const division = await axios({
    method: "get",
    url: `${process.env.REACT_APP_URL_TCM}/api/vehicle/${id}`,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return division;
};

const getVehicleBySectionService = async (id) => {
  const storedData = localStorage.getItem("userData");
  const userData = JSON.parse(storedData);
  const token = !userData ? "" : userData.token;

  const division = await axios({
    method: "get",
    url: `${process.env.REACT_APP_URL_TCM}/api/vehicle/departement/${id}`,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return division;
};

const getVehicleAllSection = async () => {
  const storedData = localStorage.getItem("userData");
  const userData = JSON.parse(storedData);
  const token = !userData ? "" : userData.token;

  const vehicle = await axios({
    method: "get",
    url: `${process.env.REACT_APP_URL_TCM}/api/vehicle/all`,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return vehicle;
};

export {
  getAllVehicle,
  newVehicleService,
  deleteVehicleService,
  updateVehicleService,
  getVehicleById,
  getVehicleBySectionService,
  getVehicleAllSection
};
