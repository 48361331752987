import React from "react";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import {
  TableRow,
  TableHead,
  Paper,
  Table,
  TableBody,
  TableContainer,
  styled,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Create";
import { useNavigate } from "react-router-dom";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#8FD0EF",
    color: "#484848",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: "#F0F0F0",
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function TableCostCenter(props) {
  const { deleteCostCenter, takePage, page, dataCostCenter } = props;
  const navigation = useNavigate();

  const fmt = new Intl.DateTimeFormat("en-GB", {
    year: "2-digit",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  });

  return (
    <>
      <TableContainer component={Paper} className="table-container">
        <Table
          sx={{ minWidth: 700 }}
          aria-label="customized table"
          className="table-approval"
        >
          <TableHead>
            <TableRow>
              <StyledTableCell sx={{ fontWeight: "bold" }}>#</StyledTableCell>
              <StyledTableCell sx={{ fontWeight: "bold" }}>
                Actions
              </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: "bold" }}>
                Entity
              </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: "bold" }}>
                Division
              </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: "bold" }}>
                Department
              </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: "bold" }}>
                Section
              </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: "bold" }}>
                Code
              </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: "bold" }}>
                Name
              </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: "bold" }}>
                Company
              </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: "bold" }}>
                GL Debit
              </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: "bold" }}>
                GL Credit
              </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: "bold" }}>
                Descriptions
              </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: "bold" }}>
                Active
              </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: "bold" }}>
                User Created
              </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: "bold" }}>
                User Updated
              </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: "bold" }}>
                Date Created
              </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: "bold" }}>
                Date Updated
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataCostCenter.data.map((item, index) => (
              <StyledTableRow key={item.id}>
                <StyledTableCell>
                  {(page - 1) * takePage + index + 1}
                </StyledTableCell>
                <StyledTableCell>
                  <EditIcon
                    className="icon-edit"
                    onClick={() =>
                      navigation("/form-cost-center-update", {
                        state: item,
                      })
                    }
                  />
                  <DeleteIcon
                    className="icon-delete"
                    onClick={() => deleteCostCenter(item.id)}
                  />
                </StyledTableCell>
                <StyledTableCell>
                  {item.section || item.section.departemen.divisi.entity
                    ? item.section.departemen.divisi.entity.name
                    : "-"}
                </StyledTableCell>
                <StyledTableCell>
                  {item.section
                    ? item.section.departemen.divisi.divisiNama
                    : "-"}
                </StyledTableCell>
                <StyledTableCell>
                  {item.section ? item.section.departemen.deptName : "-"}
                </StyledTableCell>
                <StyledTableCell>
                  {item.section ? item.section.sectionName : "-"}
                </StyledTableCell>
                <StyledTableCell>{item.costCenterCode}</StyledTableCell>
                <StyledTableCell>{item.costCenterName}</StyledTableCell>
                <StyledTableCell>
                  {item.costCenterEntityId
                    ? item.costCenterEntityId.costCenterEntity
                    : "-"}
                </StyledTableCell>
                <StyledTableCell>
                  {item.glDebit ? item.glDebit : "-"}
                </StyledTableCell>
                <StyledTableCell>
                  {item.glCredit ? item.glCredit : "-"}
                </StyledTableCell>
                <StyledTableCell>{item.desc ? item.desc : "-"}</StyledTableCell>
                <StyledTableCell>
                  {item.isActive ? "Active" : "Not Active"}
                </StyledTableCell>
                <StyledTableCell>
                  {item.createdBy ? item.createdBy.nama : "-"}
                </StyledTableCell>
                <StyledTableCell>
                  {item.updatedBy ? item.updatedBy.nama : "-"}
                </StyledTableCell>
                <StyledTableCell>
                  {item.createdAt ? fmt.format(new Date(item.createdAt)) : "-"}
                </StyledTableCell>
                <StyledTableCell>
                  {item.updatedAt ? fmt.format(new Date(item.updatedAt)) : "-"}
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
