import React from "react";
import {
  Container,
  Box,
  Typography,
  Alert,
  TextField,
  Checkbox,
  Button,
  Autocomplete,
} from "@mui/material";
import { Row, Col } from "react-bootstrap";
import SideBars from "../../../components/SideBars";
import "../../../styles/setup/costCenterStyle/CostCenterCreateFormStyle.css";
import { LogicCreate } from "./logics/LogicCreate";

const CostCenterCreateForm = () => {
  const {
    hideAlert,
    isError,
    dataEntity,
    dataCostCenter,
    dataDivision,
    dataDepartment,
    dataSection,
    isErrorCode,
    dataCompany,
    disableBtn,
    navigation,
    createNewCostCenter,
    handleChange,
    handleChangeCheckbox,
    handleChangeCode,
    handleDeptChange,
    handleDivChange,
    handleEntityChange,
    handleSectionChange,
    handleSiteChange,
  } = LogicCreate();
  return (
    <>
      <Box>
        <SideBars />

        <Container maxWidth="lg" sx={{ marginBottom: "150px" }}>
          <h4 className="title-page-ccf">Form Cost Center</h4>
          {hideAlert && <Alert severity="error">{isError}</Alert>}
          <Box className="box-cost-center-form">
            <Box align="left">
              <Row>
                <Col sm={6}>
                  <Typography sx={{ fontSize: "16px", m: 1 }}>
                    Entity <span style={{ color: "red" }}>*</span>
                  </Typography>
                </Col>
                <Col sm={6}>
                  <Autocomplete
                    disablePortal
                    options={dataEntity}
                    getOptionLabel={(dataEntity) =>
                      dataEntity.name === undefined ? "" : dataEntity.name
                    }
                    onChange={(_, value) => handleEntityChange(value)}
                    size="small"
                    fullWidth
                    renderInput={(params) => <TextField {...params} />}
                    value={
                      dataEntity.length > 0
                        ? dataEntity.find(
                            (item) => item.id === dataCostCenter.entity
                          ) || null
                        : null
                    }
                  />
                </Col>
              </Row>

              <br />

              <Row>
                <Col sm={6}>
                  <Typography sx={{ fontSize: "16px", m: 1 }}>
                    Division <span style={{ color: "red" }}>*</span>
                  </Typography>
                </Col>
                <Col sm={6}>
                  <Autocomplete
                    disablePortal
                    options={dataDivision}
                    getOptionLabel={(dataDivision) =>
                      dataDivision.divisiNama === undefined
                        ? ""
                        : dataDivision.divisiNama
                    }
                    onChange={(_, value) => handleDivChange(value)}
                    size="small"
                    fullWidth
                    renderInput={(params) => <TextField {...params} />}
                    value={
                      dataDivision.length > 0
                        ? dataDivision.find(
                            (item) => item.id === dataCostCenter.division
                          ) || null
                        : null
                    }
                  />
                </Col>
              </Row>

              <br />

              <Row>
                <Col sm={6}>
                  <Typography sx={{ fontSize: "16px", m: 1 }}>
                    Department <span style={{ color: "red" }}>*</span>
                  </Typography>
                </Col>
                <Col sm={6}>
                  <Autocomplete
                    disablePortal
                    options={dataDepartment}
                    getOptionLabel={(dataDepartment) =>
                      dataDepartment.deptName === undefined
                        ? ""
                        : dataDepartment.deptName
                    }
                    onChange={(_, value) => handleDeptChange(value)}
                    size="small"
                    fullWidth
                    renderInput={(params) => <TextField {...params} />}
                    value={
                      dataDepartment.length > 0
                        ? dataDepartment.find(
                            (item) => item.id === dataCostCenter.department
                          ) || null
                        : null
                    }
                  />
                </Col>
              </Row>

              <br />

              <Row>
                <Col sm={6}>
                  <Typography sx={{ fontSize: "16px", m: 1 }}>
                    Section <span style={{ color: "red" }}>*</span>
                  </Typography>
                </Col>
                <Col sm={6}>
                  <Autocomplete
                    disablePortal
                    options={dataSection}
                    getOptionLabel={(dataSection) =>
                      dataSection.sectionName === undefined
                        ? ""
                        : dataSection.sectionName
                    }
                    onChange={(_, value) => handleSectionChange(value)}
                    size="small"
                    fullWidth
                    renderInput={(params) => <TextField {...params} />}
                    value={
                      dataSection.length > 0
                        ? dataSection.find(
                            (item) => item.id === dataCostCenter.section
                          ) || null
                        : null
                    }
                  />
                </Col>
              </Row>

              <br />

              <Row>
                <Col sm={6}>
                  <Typography sx={{ fontSize: "16px", m: 1 }}>
                    Code <span style={{ color: "red" }}>*</span>
                  </Typography>
                </Col>
                <Col sm={6}>
                  <Typography sx={{ fontSize: "12px", color: "red" }}>
                    {isErrorCode}
                  </Typography>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    sx={{ width: "100%" }}
                    size="small"
                    name="costCenterCode"
                    onChange={handleChangeCode}
                  />
                </Col>
              </Row>

              <br />

              <Row>
                <Col sm={6}>
                  <Typography sx={{ fontSize: "16px", m: 1 }}>
                    Name <span style={{ color: "red" }}>*</span>
                  </Typography>
                </Col>
                <Col sm={6}>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    sx={{ width: "100%" }}
                    size="small"
                    name="costCenterName"
                    onChange={handleChange}
                  />
                </Col>
              </Row>

              <br />

              <Row>
                <Col sm={6}>
                  <Typography sx={{ fontSize: "16px", m: 1 }}>
                    Company <span style={{ color: "red" }}>*</span>
                  </Typography>
                </Col>
                <Col sm={6}>
                  <Autocomplete
                    disablePortal
                    options={dataCompany}
                    getOptionLabel={(dataCompany) =>
                      dataCompany.costCenterEntity === undefined
                        ? ""
                        : dataCompany.costCenterEntity
                    }
                    onChange={(_, value) => handleSiteChange(value)}
                    size="small"
                    fullWidth
                    renderInput={(params) => <TextField {...params} />}
                    value={
                      dataCompany.length > 0
                        ? dataCompany.find(
                            (item) =>
                              item.id === dataCostCenter.costCenterEntityId
                          ) || null
                        : null
                    }
                  />
                </Col>
              </Row>

              <br />

              <Row>
                <Col sm={6}>
                  <Typography sx={{ fontSize: "16px", m: 1 }}>
                    GL Debit <span style={{ color: "red" }}>*</span>
                  </Typography>
                </Col>
                <Col sm={6}>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    sx={{ width: "100%" }}
                    size="small"
                    name="glDebit"
                    onChange={handleChange}
                  />
                </Col>
              </Row>

              <br />

              <Row>
                <Col sm={6}>
                  <Typography sx={{ fontSize: "16px", m: 1 }}>
                    GL Credit <span style={{ color: "red" }}>*</span>
                  </Typography>
                </Col>
                <Col sm={6}>
                  <TextField
                    disabled={true}
                    id="outlined-basic"
                    variant="outlined"
                    sx={{ width: "100%" }}
                    size="small"
                    name="glCredit"
                    value={dataCostCenter.glCredit}
                  />
                </Col>
              </Row>

              <br />

              <Row>
                <Col sm={6}>
                  <Typography sx={{ fontSize: "16px", m: 1 }}>
                    Description
                  </Typography>
                </Col>
                <Col sm={6}>
                  <TextField
                    id="outlined-multiline-static"
                    multiline
                    rows={4}
                    sx={{ width: "100%" }}
                    name="desc"
                    onChange={handleChange}
                  />
                </Col>
              </Row>

              <br />

              <Row>
                <Col>
                  <Typography sx={{ fontSize: "16px", m: 1 }}>
                    Active
                  </Typography>
                </Col>
                <Col>
                  <Checkbox
                    sx={{ marginLeft: "-12px" }}
                    name="isActive"
                    onChange={handleChangeCheckbox}
                  />
                </Col>
              </Row>
            </Box>

            <br />

            <Box align="right">
              <Row>
                <Col>
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: "#436FB1",
                      marginTop: "10px",
                    }}
                    onClick={() => navigation("/cost-center")}
                  >
                    Back To List
                  </Button>

                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: "#084298",
                      marginTop: "10px",
                      marginLeft: "10px",
                    }}
                    onClick={() => createNewCostCenter()}
                    disabled={disableBtn ? true : false}
                  >
                    Save
                  </Button>
                </Col>
              </Row>
            </Box>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default CostCenterCreateForm;
