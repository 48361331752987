import axios from "axios";

const getAllDivision = async (take = 10, page = 1, divName = "") => {
  const storedData = localStorage.getItem("userData");
  const userData = JSON.parse(storedData);
  const token = !userData ? "" : userData.token;

  const division = await axios({
    method: "get",
    url: `${process.env.REACT_APP_URL_TCM}/api/divisi?take=${take}&page=${page}&divName=${divName}`,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return division;
};

const newDivisionService = async (form) => {
  const json = JSON.stringify(form);
  const storedData = localStorage.getItem("userData");
  const userData = JSON.parse(storedData);
  const token = !userData ? "" : userData.token;

  const division = await axios.post(
    `${process.env.REACT_APP_URL_TCM}/api/divisi/new`,
    json,
    {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return division;
};

const deleteDivisionService = async (id) => {
  const storedData = localStorage.getItem("userData");
  const userData = JSON.parse(storedData);
  const token = !userData ? "" : userData.token;

  const division = await axios.delete(
    `${process.env.REACT_APP_URL_TCM}/api/divisi/${id}`,
    {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return division;
};

const updateDivisionService = async (form, id) => {
  const json = JSON.stringify(form);
  const storedData = localStorage.getItem("userData");
  const userData = JSON.parse(storedData);
  const token = !userData ? "" : userData.token;

  const division = await axios.put(
    `${process.env.REACT_APP_URL_TCM}/api/divisi/update/${id}`,
    json,
    {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return division;
};

const getDivisionByEntityService = async (id) => {
  const storedData = localStorage.getItem("userData");
  const userData = JSON.parse(storedData);
  const token = !userData ? "" : userData.token;

  const division = await axios({
    method: "get",
    url: `${process.env.REACT_APP_URL_TCM}/api/divisi/byEntity/${id}`,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return division;
};

export {
  getAllDivision,
  newDivisionService,
  deleteDivisionService,
  updateDivisionService,
  getDivisionByEntityService,
};
