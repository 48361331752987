import axios from "axios";

const getDataDashboardService = async () => {
  const storedData = localStorage.getItem("userData");
  const userData = JSON.parse(storedData);
  const token = !userData ? "" : userData.token;

  const request = await axios({
    method: "get",
    url: `${process.env.REACT_APP_URL_TCM}/api/ebbm-request/dashboard`,
    withCredentials: true,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });

  return request;
};

const getDataChartService = async () => {
  const storedData = localStorage.getItem("userData");
  const userData = JSON.parse(storedData);
  const token = !userData ? "" : userData.token;

  const request = await axios({
    method: "get",
    url: `${process.env.REACT_APP_URL_TCM}/api/ebbm-request/daily-chart`,
    withCredentials: true,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });

  return request;
};

const getDetailTrxService = async (trxCode) => {
  const storedData = localStorage.getItem("userData");
  const userData = JSON.parse(storedData);
  const token = !userData ? "" : userData.token;

  const request = await axios({
    method: "get",
    url: `${process.env.REACT_APP_URL_TCM}/api/ebbm-request/trx/${trxCode}`,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return request;
};

export { getDataDashboardService, getDataChartService, getDetailTrxService };
