import React from "react";
import {
  Box,
  MenuItem,
  Select,
  FormControl,
  createTheme,
  ThemeProvider,
  Pagination,
} from "@mui/material";

const theme = createTheme({
  palette: {
    neutral: {
      main: "#27248E",
    },
  },
});

export default function PaginationVehicle(props) {
  const {
    handleChangeTakePage,
    handleChangePage,
    dataVehicles,
    takePage,
    page,
  } = props;
  return (
    <>
      <ThemeProvider theme={theme}>
        <Box sx={{ marginBottom: "10px", marginRight: "10px" }}>
          <Pagination
            count={Math.ceil(dataVehicles.meta.total / takePage)}
            variant="outlined"
            shape="rounded"
            size="small"
            color="neutral"
            onChange={handleChangePage}
          />
        </Box>
        <Box sx={{ minWidth: "120px", marginRight: "10px" }}>
          <span className="sum-page">
          {dataVehicles.meta.total !== 0 ? (page - 1) * takePage + 1 : 0} -{" "}
            {Math.min(page * takePage, dataVehicles.meta.total)} of{" "}
            {dataVehicles.meta.total} Results
          </span>
        </Box>
        <Box>
          <FormControl variant="outlined">
            <Select
              labelId="demo-customized-select-label"
              id="demo-customized-select"
              value={takePage}
              onChange={handleChangeTakePage}
              defaultValue={10}
              color="neutral"
              sx={{
                height: "30px",
                widht: "5px",
                fontSize: "15px",
              }}
            >
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={25}>25</MenuItem>
              <MenuItem value={50}>50</MenuItem>
              <MenuItem value={100}>100</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </ThemeProvider>
    </>
  );
}
