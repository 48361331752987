import React, { useState, useEffect } from "react";
import {
  Container,
  Box,
  Typography,
  TextField,
  Checkbox,
  Button,
  Alert,
} from "@mui/material";
import { Row, Col } from "react-bootstrap";
import SideBars from "../../components/SideBars";
import "../../styles/rolePermission/RoleCreateFormStyle.css";
import { newRoleService } from "../../services/apiService/apiRolePermission/ServiceRolePermission";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

const RoleCreateForm = () => {
  const navigation = useNavigate();

  const storedData = localStorage.getItem("userData");
  const userData = JSON.parse(storedData);

  const [dataRole, setDataRole] = useState({
    roleName: "",
    roleDesc: "",
    status: false,
    createdBy: userData.id,
  });
  const [isError, setIsError] = useState("");
  const [hideAlert, setHideAlert] = useState(false);
  const [disableBtn, setDisableBtn] = useState(false);

  const handleChange = (event) => {
    setDataRole({ ...dataRole, [event.target.name]: event.target.value });
  };

  const handleChangeCheckbox = (event) => {
    setDataRole({
      ...dataRole,
      [event.target.name]: event.target.checked,
    });
  };

  const createDataRole = () => {
    if (dataRole.roleName === "") {
      setIsError("Role Name cannot be empty");
      setHideAlert(!hideAlert);
    } else {
      newRoleService({
        roleName: dataRole.roleName,
        roleDesc: dataRole.roleDesc,
        status: dataRole.status,
        createdBy: dataRole.createdBy,
      })
        .then(() => {
          Swal.fire("Successfully added data", "", "success");
          navigation("/role-permission");
        })
        .catch((res) => {
          if (res.response.data.statusCode === 422) {
            setIsError(res.response.data.message);
            setHideAlert(true);
          } else {
            setIsError("Data was not added successfully");
            setHideAlert(true);
          }
        });
    }
  };

  useEffect(() => {
    if (dataRole.roleName === "") {
      setDisableBtn(true);
    } else {
      setDisableBtn(false);
    }
  }, [dataRole]);

  return (
    <>
      <Box>
        <SideBars />
        <Container maxWidth="lg" sx={{ marginBottom: "150px" }}>
          <h4 className="title-page-ccf">Form Role</h4>
          {hideAlert && <Alert severity="error">{isError}</Alert>}
          <Box className="box-cost-center-form">
            <Box align="left">
              <Row>
                <Col sm={6}>
                  <Typography sx={{ fontSize: "16px", m: 1 }}>
                    Role Name <span style={{ color: "red" }}>*</span>
                  </Typography>
                </Col>
                <Col sm={6}>
                  <TextField
                    variant="outlined"
                    sx={{ width: "100%" }}
                    size="small"
                    name="roleName"
                    onChange={handleChange}
                  />
                </Col>
              </Row>

              <br />

              <Row>
                <Col sm={6}>
                  <Typography sx={{ fontSize: "16px", m: 1 }}>
                    Description
                  </Typography>
                </Col>
                <Col sm={6}>
                  <TextField
                    variant="outlined"
                    sx={{ width: "100%" }}
                    size="small"
                    name="roleDesc"
                    onChange={handleChange}
                  />
                </Col>
              </Row>

              <br />

              <Row>
                <Col>
                  <Typography sx={{ fontSize: "16px", m: 1 }}>
                    Active
                  </Typography>
                </Col>
                <Col>
                  <Checkbox
                    sx={{ marginLeft: "-12px" }}
                    value={dataRole.status}
                    name="status"
                    onChange={handleChangeCheckbox}
                  />
                </Col>
              </Row>
            </Box>

            <br />

            <Box align="right">
              <Row>
                <Col>
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: "#436FB1",
                      marginTop: "10px",
                    }}
                    onClick={() => navigation("/role-permission")}
                  >
                    Back To List
                  </Button>

                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: "#084298",
                      marginTop: "10px",
                      marginLeft: "10px",
                    }}
                    onClick={() => createDataRole()}
                    disabled={disableBtn ? true : false}
                  >
                    Save
                  </Button>
                </Col>
              </Row>
            </Box>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default RoleCreateForm;
