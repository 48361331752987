import React, { Fragment, useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import SideBars from "../../../components/SideBars";
import "../../../styles/setup/userAuthorizationStyle/UserAuthorizationStyle.css";
import {
  Container,
  Grid,
  Typography,
  Box,
  Button,
  TextField,
} from "@mui/material";
import { getAllUsers } from "../../../services/apiService/apiSetup/masterUser/ServiceApiUsername";
import { getAllEntity } from "../../../services/apiService/apiSetup/masterEntity/ServiceApiEntity";
import {
  getAuthEntityService,
  getAuthDivisionService,
  getAuthDeptService,
  getAuthSectionService,
  newUserAuthorService,
  getDivisionByEntityService,
  getDeptByDivService,
  getSectionByDeptService,
  deleteAuthEntityService,
  deleteAuthDivService,
  deleteAuthDeptService,
  deleteAuthSectionService,
} from "../../../services/apiService/apiSetup/userAuthorization/ServiceApiUserAuthorization";
import Swal from "sweetalert2";
import PaginationUserAuthor from "./components/PaginationUserAuthor";
import TableUserAuthor from "./components/TableUserAuthor";

const UserAuthorization = () => {
  const navigation = useNavigate();

  const storedData = localStorage.getItem("userData");
  const userData = JSON.parse(storedData);

  const [takePage, setTakePage] = useState(10);
  const [page, setPage] = useState(1);
  const [searchName, setSearchName] = useState("");
  const [dataUsers, setDataUsers] = useState([]);
  const [dataEntity, setDataEntity] = useState([]);
  const [dataDivision, setDataDivision] = useState([]);
  const [dataDepartment, setDataDepartment] = useState([]);
  const [dataSection, setDataSection] = useState([]);
  const [authEntity, setAuthEntity] = useState([]);
  const [authDivision, setAuthDivision] = useState([]);
  const [authDept, setAuthDept] = useState([]);
  const [authSection, setAuthSection] = useState([]);
  const [open, setOpen] = useState({});
  const [dataUserAuthor, setDataUserAuthor] = useState({
    users_id: 0,
    entity_id: [],
    divisi_id: [],
    departemen_id: [],
    sections_id: [],
    createdBy: userData.id,
    updatedBy: userData.id,
  });
  const [disableBtn, setDisableBtn] = useState(true);
  const [selectValue, setSelectValue] = useState(null);
  const [valueTabList, setValueTabList] = useState("Entity");

  const pageFilter = 1;
  const takePageFilter = 0;

  const loadData = useCallback(() => {
    getAllUsers(takePage, page, searchName)
      .then((res) => {
        const result = res.data;
        setDataUsers(result);
        if (res.data.data.length === 0) {
          setPage(1);
        }
      })
      .catch((res) => {
        if (res.request.status === 401) {
          navigation("/");
        }
      });

    getAllEntity(takePageFilter, pageFilter)
      .then((res) => {
        const resultEntity = res.data.data;
        setDataEntity(resultEntity);
      })
      .catch(() => {});
  }, [takePage, page, searchName, navigation]);

  const createNewUserAuthor = useCallback(() => {
    newUserAuthorService({
      users_id: dataUserAuthor.users_id,
      entity_id: dataUserAuthor.entity_id,
      divisi_id: dataUserAuthor.divisi_id,
      departemen_id: dataUserAuthor.departemen_id,
      sections_id: dataUserAuthor.sections_id,
      createdBy: dataUserAuthor.createdBy,
      updatedBy: dataUserAuthor.updatedBy,
    })
      .then(() => {
        Swal.fire("Data added successfully", "", "success");
        setDisableBtn(true);
        setSelectValue(null);

        getAuthEntityService(dataUserAuthor.users_id)
          .then((res) => {
            const resultEntity = res.data;
            setAuthEntity(resultEntity);
          })
          .catch(() => {});

        getAuthDivisionService(dataUserAuthor.users_id)
          .then((res) => {
            const resultDiv = res.data;
            setAuthDivision(resultDiv);
          })
          .catch(() => {});

        getAuthDeptService(dataUserAuthor.users_id)
          .then((res) => {
            const resultDept = res.data;
            setAuthDept(resultDept);
          })
          .catch(() => {});

        getAuthSectionService(dataUserAuthor.users_id)
          .then((res) => {
            const resultSection = res.data;
            setAuthSection(resultSection);
          })
          .catch(() => {});
      })
      .catch((res) => {
        setDisableBtn(true);
        setSelectValue(null);
        if (res.request.status === 422) {
          Swal.fire("The added data already exists", "", "error");
        }

        getAuthEntityService(dataUserAuthor.users_id)
          .then((res) => {
            const resultEntity = res.data;
            setAuthEntity(resultEntity);
            const resultEntityId = resultEntity.map((item) => item.id);
            setDataUserAuthor((dataUserAuthor) => ({
              ...dataUserAuthor,
              entity_id: resultEntityId,
            }));
          })
          .catch(() => {});

        getAuthDivisionService(dataUserAuthor.users_id)
          .then((res) => {
            const resultDiv = res.data;
            setAuthDivision(resultDiv);
            const resultDivId = resultDiv.map((item) => item.id);
            setDataUserAuthor((dataUserAuthor) => ({
              ...dataUserAuthor,
              divisi_id: resultDivId,
            }));
          })
          .catch(() => {});

        getAuthDeptService(dataUserAuthor.users_id)
          .then((res) => {
            const resultDept = res.data;
            setAuthDept(resultDept);
            const resultDeptId = resultDept.map((item) => item.id);
            setDataUserAuthor((dataUserAuthor) => ({
              ...dataUserAuthor,
              departemen_id: resultDeptId,
            }));
          })
          .catch(() => {});

        getAuthSectionService(dataUserAuthor.users_id)
          .then((res) => {
            const resultSection = res.data;
            setAuthSection(resultSection);
            const resultSectionId = resultSection.map((item) => item.id);
            setDataUserAuthor((dataUserAuthor) => ({
              ...dataUserAuthor,
              sections_id: resultSectionId,
            }));
          })
          .catch(() => {});
      });
  }, [dataUserAuthor]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const getAuthDiv = () => {
    if (authEntity.length !== 0) {
      const idEntity = authEntity.map((item) => item.id);

      getDivisionByEntityService(idEntity).then((res) => {
        const resultDivision = res.data;
        setDataDivision(resultDivision);
      });
    } else {
      setDataDivision([]);
    }
  };

  const getAuthDept = () => {
    if (authDivision.length !== 0) {
      const idDiv = authDivision.map((item) => item.id);
      getDeptByDivService(idDiv).then((res) => {
        const resultDivision = res.data;
        setDataDepartment(resultDivision);
      });
    } else {
      setDataDepartment([]);
    }
  };

  const getAuthSection = () => {
    if (authDept.length !== 0) {
      const idDept = authDept.map((item) => item.id);
      getSectionByDeptService(idDept).then((res) => {
        const resultSection = res.data;
        setDataSection(resultSection);
      });
    } else {
      setDataSection([]);
    }
  };

  const handleBtnPermission = (item) => {
    setOpen((prevOpen) => (prevOpen === item.id ? null : item.id));
    setDataUserAuthor((dataUserAuthor) => ({
      ...dataUserAuthor,
      users_id: item.id,
      entity_id: [],
      divisi_id: [],
      departemen_id: [],
      sections_id: [],
    }));
    setDataDivision([]);
    setDataDepartment([]);
    setDataSection([]);
    setValueTabList("Entity");

    getAuthEntityService(item.id)
      .then((res) => {
        const resultEntity = res.data;
        setAuthEntity(resultEntity);
        if (resultEntity !== "") {
          const resultEntityId = resultEntity.map((item) => item.id);
          setDataUserAuthor((dataUserAuthor) => ({
            ...dataUserAuthor,
            entity_id: resultEntityId,
          }));
        }
      })
      .catch(() => {
        setAuthEntity([]);
      });

    getAuthDivisionService(item.id)
      .then((res) => {
        const resultDivision = res.data;
        setAuthDivision(resultDivision);

        const resultDivId = resultDivision.map((item) => item.id);
        setDataUserAuthor((dataUserAuthor) => ({
          ...dataUserAuthor,
          divisi_id: resultDivId,
        }));
      })
      .catch(() => {
        setAuthDivision([]);
      });

    getAuthDeptService(item.id)
      .then((res) => {
        const resultDept = res.data;
        setAuthDept(resultDept);

        const resultDeptId = resultDept.map((item) => item.id);
        setDataUserAuthor((dataUserAuthor) => ({
          ...dataUserAuthor,
          departemen_id: resultDeptId,
        }));
      })
      .catch(() => {
        setAuthDept([]);
      });

    getAuthSectionService(item.id)
      .then((res) => {
        const resultSection = res.data;
        setAuthSection(resultSection);

        const resultSectionId = resultSection.map((item) => item.id);
        setDataUserAuthor((dataUserAuthor) => ({
          ...dataUserAuthor,
          sections_id: resultSectionId,
        }));
      })
      .catch(() => {
        setAuthSection([]);
      });
  };

  const handleTabList = (_, valueTabList) => {
    setValueTabList(valueTabList);
  };

  const deleteAuthEntity = (idUser, idEntity) => {
    Swal.fire({
      title: "Are you sure to deleted ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#084298",
      cancelButtonColor: "#d33",
      confirmButtonText: "Delete",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteAuthEntityService(idUser, idEntity)
          .then(() => {
            Swal.fire("", "Data successfully deleted", "success");

            getAuthEntityService(idUser)
              .then((res) => {
                const resultEntity = res.data;
                setAuthEntity(resultEntity);
                const resultEntityId = resultEntity.map((item) => item.id);
                setDataUserAuthor((dataUserAuthor) => ({
                  ...dataUserAuthor,
                  entity_id: resultEntityId,
                }));
              })
              .catch(() => {
                setAuthEntity([]);
                setDataUserAuthor((dataUserAuthor) => ({
                  ...dataUserAuthor,
                  entity_id: [],
                }));
              });
          })
          .catch(() => {
            Swal.fire({
              icon: "error",
              title: "",
              text: "Data was not successfully deleted",
            });
          });
      }
    });
  };

  const deleteAuthDivision = (idUser, idDiv) => {
    Swal.fire({
      title: "Are you sure to deleted ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#084298",
      cancelButtonColor: "#d33",
      confirmButtonText: "Delete",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteAuthDivService(idUser, idDiv)
          .then(() => {
            Swal.fire("", "Data successfully deleted", "success");
            getAuthDivisionService(idUser)
              .then((res) => {
                const resultDiv = res.data;
                setAuthDivision(resultDiv);
                const resultDivId = resultDiv.map((item) => item.id);
                setDataUserAuthor((dataUserAuthor) => ({
                  ...dataUserAuthor,
                  divisi_id: resultDivId,
                }));
              })
              .catch(() => {
                setAuthDivision([]);
                setDataUserAuthor((dataUserAuthor) => ({
                  ...dataUserAuthor,
                  divisi_id: [],
                }));
              });
          })
          .catch(() => {
            Swal.fire({
              icon: "error",
              title: "",
              text: "Data was not successfully deleted",
            });
          });
      }
    });
  };

  const deleteAuthDept = (idUser, idDept) => {
    Swal.fire({
      title: "Are you sure to deleted ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#084298",
      cancelButtonColor: "#d33",
      confirmButtonText: "Delete",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteAuthDeptService(idUser, idDept)
          .then(() => {
            Swal.fire("", "Data successfully deleted", "success");
            getAuthDeptService(dataUserAuthor.users_id)
              .then((res) => {
                const resultDept = res.data;
                setAuthDept(resultDept);
                const resultDeptId = resultDept.map((item) => item.id);
                setDataUserAuthor((dataUserAuthor) => ({
                  ...dataUserAuthor,
                  departemen_id: resultDeptId,
                }));
              })
              .catch(() => {
                setAuthDept([]);
                setDataUserAuthor((dataUserAuthor) => ({
                  ...dataUserAuthor,
                  departemen_id: [],
                }));
              });
          })
          .catch(() => {
            Swal.fire({
              icon: "error",
              title: "",
              text: "Data was not successfully deleted",
            });
          });
      }
    });
  };

  const deleteAuthSection = (idUser, idSection) => {
    Swal.fire({
      title: "Are you sure to deleted ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#084298",
      cancelButtonColor: "#d33",
      confirmButtonText: "Delete",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteAuthSectionService(idUser, idSection)
          .then(() => {
            Swal.fire("", "Data successfully deleted", "success");
            getAuthSectionService(dataUserAuthor.users_id)
              .then((res) => {
                const resultSection = res.data;
                setAuthSection(resultSection);
                const resultSectionId = resultSection.map((item) => item.id);
                setDataUserAuthor((dataUserAuthor) => ({
                  ...dataUserAuthor,
                  sections_id: resultSectionId,
                }));
              })
              .catch(() => {
                setAuthSection([]);
                setDataUserAuthor((dataUserAuthor) => ({
                  ...dataUserAuthor,
                  sections_id: [],
                }));
              });
          })
          .catch(() => {
            Swal.fire({
              icon: "error",
              title: "",
              text: "Data was not successfully deleted",
            });
          });
      }
    });
  };

  const handleChangePage = (_, v) => {
    setPage(v);
  };

  const handleChangeTakePage = (e) => {
    setTakePage(e.target.value);
  };

  const handleChangeSearch = (e) => {
    setSearchName(e.target.value);
  };

  const handleSearch = (userName) => {
    setSearchName(userName);
    loadData();
  };

  const handleChangeEntity = (_, value) => {
    if (value !== null) {
      setDataUserAuthor((dataUserAuthor) => ({
        ...dataUserAuthor,
        entity_id: [...dataUserAuthor.entity_id, value.id],
      }));
      setDisableBtn(false);
      setSelectValue(value);
    } else {
      setDisableBtn(true);
    }
  };

  const handleChangeDiv = (_, value) => {
    if (value !== null) {
      setDataUserAuthor((dataUserAuthor) => ({
        ...dataUserAuthor,
        divisi_id: [...dataUserAuthor.divisi_id, value.id],
      }));
      setDisableBtn(false);
      setSelectValue(value);
    } else {
      setDisableBtn(true);
    }
  };

  const handleChangeDept = (_, value) => {
    if (value !== null) {
      setDataUserAuthor((dataUserAuthor) => ({
        ...dataUserAuthor,
        departemen_id: [...dataUserAuthor.departemen_id, value.id],
      }));
      setDisableBtn(false);
      setSelectValue(value);
    } else {
      setDisableBtn(true);
    }
  };

  const handleChangeSection = (_, value) => {
    if (value !== null) {
      setDataUserAuthor((dataUserAuthor) => ({
        ...dataUserAuthor,
        sections_id: [...dataUserAuthor.sections_id, value.id],
      }));
      setDisableBtn(false);
      setSelectValue(value);
    } else {
      setDisableBtn(true);
    }
  };

  if (!dataUsers.data) {
    return null;
  }
  if (!authEntity) {
    return null;
  }
  if (!authDivision) {
    return null;
  }
  if (!authDept) {
    return null;
  }

  return (
    <>
      <Box>
        <SideBars />
        <Container maxWidth="xl" sx={{ marginBottom: "150px" }}>
          <h3 className="title-page-username">User Authorization</h3>
          <Box
            sx={{
              padding: "30px",
              borderRadius: "10px",
              border: "3px solid #e9e7e7",
            }}
          >
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={2}
            >
              <Grid>
                <Typography
                  sx={{ fontSize: "16px", paddingTop: 1, marginRight: "10px" }}
                >
                  Search by Username
                </Typography>
              </Grid>
              <Grid>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  size="small"
                  sx={{ width: "300px" }}
                  onChange={handleChangeSearch}
                />
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={2}
              sx={{ marginTop: "10px" }}
            >
              <Grid>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "#6EA8FE",
                    minWidth: "200px",
                    fontSize: "16px",
                    textTransform: "none",
                  }}
                  size="small"
                  onClick={() => handleSearch(searchName)}
                >
                  Find
                </Button>
              </Grid>
            </Grid>
          </Box>

          <Box align="right" className="box-new-user-author"></Box>

          <Box>
            <TableUserAuthor
              createNewUserAuthor={createNewUserAuthor}
              deleteAuthEntity={deleteAuthEntity}
              deleteAuthDivision={deleteAuthDivision}
              deleteAuthDept={deleteAuthDept}
              deleteAuthSection={deleteAuthSection}
              handleChangeEntity={handleChangeEntity}
              handleChangeDiv={handleChangeDiv}
              handleChangeDept={handleChangeDept}
              handleChangeSection={handleChangeSection}
              handleBtnPermission={handleBtnPermission}
              handleTabList={handleTabList}
              valueTabList={valueTabList}
              getAuthDept={getAuthDept}
              getAuthSection={getAuthSection}
              getAuthDiv={getAuthDiv}
              authEntity={authEntity}
              authDept={authDept}
              authDivision={authDivision}
              authSection={authSection}
              dataUsers={dataUsers}
              dataEntity={dataEntity}
              dataDivision={dataDivision}
              dataDepartment={dataDepartment}
              dataSection={dataSection}
              disableBtn={disableBtn}
              open={open}
              takePage={takePage}
              page={page}
              selectValue={selectValue}
            />
          </Box>

          <br />

          <Box sx={{ display: "flex", flexWrap: "wrap" }}>
            <PaginationUserAuthor
              handleChangeTakePage={handleChangeTakePage}
              handleChangePage={handleChangePage}
              dataUsers={dataUsers}
              takePage={takePage}
              page={page}
            />
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default UserAuthorization;
