import React from "react";
import {
  Paper,
  TableHead,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  styled,
} from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Create";
import { useNavigate } from "react-router-dom";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#8FD0EF",
    color: "#484848",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: "#F0F0F0",
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function TableDepartment(props) {
  const { deleteDepartment, dataDepartment, takePage, page } = props;
  const navigation = useNavigate();


  const fmt = new Intl.DateTimeFormat("en-GB", {
    year: "2-digit",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  });
  return (
    <>
      <TableContainer component={Paper} className="table-container">
        <Table
          sx={{ minWidth: 700 }}
          aria-label="customized table"
          className="table-approval"
        >
          <TableHead>
            <TableRow>
              <StyledTableCell sx={{ fontWeight: "bold" }}>#</StyledTableCell>
              <StyledTableCell sx={{ fontWeight: "bold" }}>
                Actions
              </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: "bold" }}>
                Entity
              </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: "bold" }}>
                Division
              </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: "bold" }}>
                Code
              </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: "bold" }}>
                Name
              </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: "bold" }}>
                Descriptions
              </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: "bold" }}>
                Active
              </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: "bold" }}>
                User Created
              </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: "bold" }}>
                User Updated
              </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: "bold" }}>
                Date Created
              </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: "bold" }}>
                Date Updated
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataDepartment.data.map((item, index) => (
              <StyledTableRow key={item.id}>
                <StyledTableCell>
                  {(page - 1) * takePage + index + 1}
                </StyledTableCell>
                <StyledTableCell sx={{ minWidth: "80px" }}>
                  <EditIcon
                    className="icon-edit"
                    onClick={() =>
                      navigation("/form-master-department-update", {
                        state: item,
                      })
                    }
                  />
                  <DeleteIcon
                    className="icon-delete"
                    onClick={() => deleteDepartment(item.id)}
                  />
                </StyledTableCell>
                <StyledTableCell>
                  {item.divisi.entity === null ? "-" : item.divisi.entity.name}
                </StyledTableCell>
                <StyledTableCell>{item.divisi.divisiNama}</StyledTableCell>
                <StyledTableCell>{item.deptCode}</StyledTableCell>
                <StyledTableCell>{item.deptName}</StyledTableCell>
                <StyledTableCell>{item.desc}</StyledTableCell>
                <StyledTableCell>
                  {item.isActive ? "Active" : "Not Active"}
                </StyledTableCell>
                <StyledTableCell>
                  {item.createdBy === null ? "-" : item.createdBy.nama}
                </StyledTableCell>
                <StyledTableCell>
                  {item.updatedBy === null ? "-" : item.updatedBy.nama}
                </StyledTableCell>
                <StyledTableCell>
                  {item.createdAt === null
                    ? "-"
                    : fmt.format(new Date(item.createdAt))}
                </StyledTableCell>
                <StyledTableCell>
                  {item.updatedAt === null
                    ? "-"
                    : fmt.format(new Date(item.updatedAt))}
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
