const initialState = {
  dataLocations: [],
};

const GetLocations = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ALL_LOCATIONS":
      return { ...state, dataLocations: action.data };
    default:
      return state;
  }
};

export default GetLocations;
