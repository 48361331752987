import React, { useState, useEffect } from "react";
import {
  Container,
  Box,
  Typography,
  TextField,
  Checkbox,
  Button,
  Alert,
} from "@mui/material";
import { Row, Col } from "react-bootstrap";
import SideBars from "../../components/SideBars";
import "../../styles/rolePermission/PermissionCreateFormStyle.css";
import { newPermissionService } from "../../services/apiService/apiRolePermission/ServiceRolePermission";
import { useLocation, useNavigate } from "react-router-dom";
import FormControlLabel from "@mui/material/FormControlLabel";
import {
  getAllMenu,
  getAllParents,
  getMenuByIdRoleService,
} from "../../services/apiService/apiSetup/masterMenu/ServiceApiMenu";
import Swal from "sweetalert2";

const PermissionCreateForm = () => {
  const navigation = useNavigate();
  const location = useLocation();

  const storedData = localStorage.getItem("userData");
  const userData = JSON.parse(storedData);
  const dataPermission = {
    roleId: location.state.id,
    menuIds: [],
    status: true,
    createdBy: userData.id,
  };
  const [isError, setIsError] = useState("");
  const [hideAlert, setHideAlert] = useState(false);
  const [dataParent, setDataParent] = useState([]);
  const [menuIds, setMenuIds] = useState([]);
  const [allMenus, setAllMenus] = useState([]);
  const [selectAllChecked, setSelectAllChecked] = useState(false);

  const createDataPermission = () => {
    newPermissionService({
      roleId: dataPermission.roleId,
      menuIds: menuIds,
      status: dataPermission.status,
      createdBy: dataPermission.createdBy,
    })
      .then(() => {
        Swal.fire("Successfully added permission", "", "success");
        navigation("/role-permission");
      })
      .catch(() => {
        setIsError("Permission was not added successfully");
        setHideAlert(true);
      });
  };

  useEffect(() => {
    getAllParents()
      .then((res) => {
        const result = res.data;
        setDataParent(result);
      })
      .catch(() => {});

    getMenuByIdRoleService(location.state.id)
      .then((res) => {
        const result = res.data;
        const idMenu = [
          ...result.map((item) => item.id),
          ...result.flatMap((item) => item.child.map((data) => data.id)),
        ];
        getAllMenu().then((res) => {
          setAllMenus(res.data);
          setSelectAllChecked(idMenu.length === res.data.length);
        });
        setMenuIds(idMenu);
      })
      .catch(() => {});
  }, [location]);

  const handleSelectAll = () => {
    const newMenuIds = selectAllChecked
      ? []
      : [
          ...dataParent.data.map((item) => item.id),
          ...dataParent.data.flatMap((item) =>
            item.child.map((data) => data.id)
          ),
        ];
    setMenuIds(newMenuIds);
    setSelectAllChecked(!selectAllChecked);
  };

  const handleCheckbox = (event, item) => {
    const isChecked = event.target.checked;
    const idMenu = item.id;
    const idParent = item.menuIdParent;

    if (idParent === null) {
      const childIds = item.child.map((itemChild) => itemChild.id);
      const newIds = isChecked
        ? [...menuIds, idMenu, ...childIds]
        : menuIds.filter((id) => !childIds.includes(id) && id !== idMenu);
      setMenuIds(Array.from(new Set(newIds)));
    } else {
      const newIds = isChecked
        ? [...menuIds, idMenu, idParent]
        : menuIds.filter((id) => id !== idMenu);
      setMenuIds(Array.from(new Set(newIds)));
    }
  };

  useEffect(() => {
    setSelectAllChecked(allMenus.length === menuIds.length);
  }, [allMenus, menuIds]);

  if (!dataParent.data) {
    return null;
  }

  return (
    <>
      <Box>
        <SideBars />
        <Container maxWidth="lg" sx={{ marginBottom: "150px" }}>
          <h4 className="title-page-ccf">Form Permissions</h4>
          {hideAlert && <Alert severity="error">{isError}</Alert>}
          <Box className="box-cost-center-form">
            <Box align="left">
              <Row>
                <Col sm={6}>
                  <Typography sx={{ fontSize: "16px", m: 1 }}>
                    Role Name
                  </Typography>
                </Col>
                <Col sm={6}>
                  <TextField
                    variant="outlined"
                    sx={{ width: "100%" }}
                    size="small"
                    name="roleName"
                    defaultValue={location.state.roleName}
                    disabled={true}
                  />
                </Col>
              </Row>

              <br />

              <Row style={{ borderBottom: "1px solid #E0E0E0" }}>
                <Col sm={6}>
                  <Typography
                    sx={{ fontSize: "16px", m: 1, fontWeight: "bold" }}
                  >
                    Role & Permission Access
                  </Typography>
                </Col>
                <Col sm={6} className="col-checkbox">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={selectAllChecked}
                        onChange={() => handleSelectAll()}
                      />
                    }
                    label="Select All"
                  />
                </Col>
              </Row>

              {dataParent.data.map((item) => (
                <Row
                  style={{ borderBottom: "1px solid #E0E0E0" }}
                  key={item.id}
                >
                  <Col sm={6}>
                    <Typography
                      sx={{ fontSize: "16px", m: 1, fontWeight: "bold" }}
                    >
                      {item.menuName}
                    </Typography>
                  </Col>
                  <Col sm={6} className="col-checkbox">
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={menuIds.includes(item.id)}
                          name="menuIds"
                          onChange={(event) => handleCheckbox(event, item)}
                        />
                      }
                      label="Enable"
                    />
                  </Col>

                  {item.child.map((data) => (
                    <Row key={data.id} className="row-child">
                      <Col sm={6}>
                        <Typography sx={{ fontSize: "16px", m: 1 }}>
                          {data.menuName}
                        </Typography>
                      </Col>
                      <Col sm={6} className="col-checkbox">
                        <FormControlLabel
                          control={
                            <Checkbox
                              value={dataPermission.menuIds}
                              checked={menuIds.includes(data.id)}
                              name="menuIds"
                              onChange={(event) => handleCheckbox(event, data)}
                            />
                          }
                          label="Enable"
                        />
                      </Col>
                    </Row>
                  ))}
                </Row>
              ))}
            </Box>

            <br />

            <Box align="right">
              <Row>
                <Col>
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: "#436FB1",
                      marginTop: "10px",
                    }}
                    onClick={() => navigation("/role-permission")}
                  >
                    Back To List
                  </Button>

                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: "#084298",
                      marginTop: "10px",
                      marginLeft: "10px",
                    }}
                    onClick={() => createDataPermission()}
                  >
                    Save
                  </Button>
                </Col>
              </Row>
            </Box>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default PermissionCreateForm;
