import React, { useEffect, useState } from "react";
import {
  Container,
  Box,
  Typography,
  Alert,
  TextField,
  Checkbox,
  Button,
  MenuItem,
  Select,
  FormControl,
} from "@mui/material";
import { Row, Col } from "react-bootstrap";
import SideBars from "../../../components/SideBars";
import "../../../styles/setup/sectionStyle/SectionCreateFormStyle.css";
import { useNavigate } from "react-router-dom";
import { newSectionService } from "../../../services/apiService/apiSetup/masterSection/ServiceApiSection";
import { getAllEntity } from "../../../services/apiService/apiSetup/masterEntity/ServiceApiEntity";
import { getDivisionByEntityService } from "../../../services/apiService/apiSetup/masterDivision/ServiceApiDivision";
import { getDeptByDivService } from "../../../services/apiService/apiSetup/masterDepartment/ServiceApiDepartment";
import Swal from "sweetalert2";

const SectionCreateForm = () => {
  const navigation = useNavigate();

  const storedData = localStorage.getItem("userData");
  const userData = JSON.parse(storedData);

  const [dataSection, setDataSection] = useState({
    entity: "",
    divisi: "",
    departemen: "",
    sectionCode: "",
    sectionName: "",
    description: "",
    isActive: false,
    createdBy: userData.id,
  });

  const [isError, setIsError] = useState("");
  const [isErrorCode, setIsErrorCode] = useState("");
  const [hideAlert, setHideAlert] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [dataEntity, setDataEntity] = useState([]);
  const [dataDivision, setDataDivision] = useState([]);
  const [dataDepartment, setDataDepartment] = useState([]);
  const page = 1;
  const takePage = 0;

  const handleChange = (event) => {
    setDataSection({
      ...dataSection,
      [event.target.name]: event.target.value,
    });
  };

  const handleChangeCode = (event) => {
    setDataSection({
      ...dataSection,
      sectionCode: event.target.value.trim(),
    });

    if (dataSection.sectionCode.length >= 10) {
      setIsErrorCode("Input code with maximum 10 characters");
    } else {
      setIsErrorCode("");
    }
  };

  const handleChangeCheckbox = (event) => {
    setDataSection({
      ...dataSection,
      isActive: event.target.checked,
    });
  };

  useEffect(() => {
    getAllEntity(takePage, page)
      .then((res) => {
        const result = res.data.data;
        setDataEntity(result);
      })
      .catch(() => {});

    if (
      dataSection.entity === "" ||
      dataSection.divisi === "" ||
      dataSection.departemen === "" ||
      dataSection.sectionCode === "" ||
      dataSection.sectionName === "" ||
      dataSection.sectionCode.length > 10
    ) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [dataSection]);

  const getDivisionByEntity = (id) => {
    getDivisionByEntityService(id)
      .then((res) => {
        const result = res.data;
        setDataDivision(result);
      })
      .catch((res) => {
        if (res.request.status === 404) {
          setIsError("There are no divisions under the entity");
          setHideAlert(!hideAlert);
        }
        setDataDivision([]);
      });
  };

  const getDeptByDiv = (id) => {
    getDeptByDivService(id)
      .then((res) => {
        const result = res.data;
        setDataDepartment(result);
      })
      .catch((res) => {
        if (res.request.status === 404) {
          setIsError("There are no divisions under the entity");
          setHideAlert(!hideAlert);
        }
        setDataDepartment([]);
      });
  };

  const createNewSection = () => {
    newSectionService({
      departemen: dataSection.departemen,
      sectionCode: dataSection.sectionCode,
      sectionName: dataSection.sectionName,
      description: dataSection.description,
      isActive: dataSection.isActive,
      createdBy: dataSection.createdBy,
    })
      .then(() => {
        Swal.fire("Data added successfully", "", "success");
        navigation("/master-section");
      })
      .catch((res) => {
        if (res.response.data.statusCode === 422) {
          setIsError(res.response.data.message);
          setHideAlert(true);
        } else {
          setIsError("Data was not added successfully");
          setHideAlert(true);
        }
      });
  };

  return (
    <>
      <Box>
        <SideBars />

        <Container maxWidth="lg" sx={{ marginBottom: "150px" }}>
          <h4 className="title-page-ccf">Form Master Section</h4>
          {hideAlert && <Alert severity="error">{isError}</Alert>}
          <Box className="box-cost-center-form">
            <Box align="left">
              <Row>
                <Col sm={6}>
                  <Typography sx={{ fontSize: "16px", m: 1 }}>
                    Entity <span style={{ color: "red" }}>*</span>
                  </Typography>
                </Col>
                <Col sm={6}>
                  <FormControl fullWidth>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      size="small"
                      value={dataSection.entity}
                      name="entity"
                      onChange={handleChange}
                      displayEmpty
                    >
                      <MenuItem
                        value=""
                        onClick={() => getDivisionByEntity("")}
                      >
                        -
                      </MenuItem>
                      {dataEntity.map((item) => (
                        <MenuItem
                          key={item.id}
                          value={item.id}
                          onClick={() => getDivisionByEntity(item.id)}
                        >
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Col>
              </Row>

              <br />

              <Row>
                <Col sm={6}>
                  <Typography sx={{ fontSize: "16px", m: 1 }}>
                    Division <span style={{ color: "red" }}>*</span>
                  </Typography>
                </Col>
                <Col sm={6}>
                  <FormControl fullWidth>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={dataSection.divisi}
                      size="small"
                      name="divisi"
                      onChange={handleChange}
                      displayEmpty
                    >
                      <MenuItem value="">-</MenuItem>
                      {dataDivision.map((item) => (
                        <MenuItem
                          key={item.id}
                          value={item.id}
                          onClick={() => getDeptByDiv(item.id)}
                        >
                          {item.divisiNama}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Col>
              </Row>

              <br />

              <Row>
                <Col sm={6}>
                  <Typography sx={{ fontSize: "16px", m: 1 }}>
                    Department <span style={{ color: "red" }}>*</span>
                  </Typography>
                </Col>
                <Col sm={6}>
                  <FormControl fullWidth>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={dataSection.departemen}
                      size="small"
                      name="departemen"
                      onChange={handleChange}
                      displayEmpty
                    >
                      <MenuItem value="">-</MenuItem>
                      {dataDepartment.map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.deptName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Col>
              </Row>

              <br />

              <Row>
                <Col sm={6}>
                  <Typography sx={{ fontSize: "16px", m: 1 }}>
                    Code <span style={{ color: "red" }}>*</span>
                  </Typography>
                </Col>
                <Col sm={6}>
                  <Typography sx={{ fontSize: "12px", color: "red" }}>
                    {isErrorCode}
                  </Typography>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    sx={{ width: "100%" }}
                    size="small"
                    name="sectionCode"
                    onChange={handleChangeCode}
                  />
                </Col>
              </Row>

              <br />

              <Row>
                <Col sm={6}>
                  <Typography sx={{ fontSize: "16px", m: 1 }}>
                    Name <span style={{ color: "red" }}>*</span>
                  </Typography>
                </Col>
                <Col sm={6}>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    sx={{ width: "100%" }}
                    size="small"
                    name="sectionName"
                    onChange={handleChange}
                  />
                </Col>
              </Row>

              <br />

              <Row>
                <Col sm={6}>
                  <Typography sx={{ fontSize: "16px", m: 1 }}>
                    Description
                  </Typography>
                </Col>
                <Col sm={6}>
                  <TextField
                    id="outlined-multiline-static"
                    multiline
                    rows={4}
                    sx={{ width: "100%" }}
                    name="description"
                    onChange={handleChange}
                  />
                </Col>
              </Row>

              <br />

              <Row>
                <Col>
                  <Typography sx={{ fontSize: "16px", m: 1 }}>
                    Active
                  </Typography>
                </Col>
                <Col>
                  <Checkbox
                    sx={{ marginLeft: "-12px" }}
                    value={dataSection.isActive}
                    name="isActive"
                    onChange={handleChangeCheckbox}
                  />
                </Col>
              </Row>
            </Box>

            <br />

            <Box align="right">
              <Row>
                <Col>
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: "#436FB1",
                      marginTop: "10px",
                    }}
                    onClick={() => navigation("/master-section")}
                  >
                    Back To List
                  </Button>

                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: "#084298",
                      marginTop: "10px",
                      marginLeft: "10px",
                    }}
                    disabled={disabled ? true : false}
                    onClick={() => createNewSection()}
                  >
                    Save
                  </Button>
                </Col>
              </Row>
            </Box>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default SectionCreateForm;
