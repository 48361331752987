import { useCallback, useEffect, useState } from "react";
import {
  getAllTrxHistoryService,
  getExportToExcelService,
  getImageOdoService,
} from "../../../../services/apiService/apiReport/transactionHistory/ServiceApiTransactionHistory";
import { useNavigate } from "react-router-dom";
import {
  getCostCenterBySectionUserService,
  getDeptByDivUserService,
  getDivByEntityUserService,
  getEntityUserService,
  getSectionByDeptUserService,
} from "../../../../services/apiService/apiTransaction/fuelingRequest/ServiceApiFuelingRequest";
import { getVehicleBySectionService } from "../../../../services/apiService/apiSetup/masterVehicle/ServiceApiVehicle";
import * as XLSX from "xlsx";

export const TrxHistoryLogic = () => {
  const navigation = useNavigate();

  const fmt = new Intl.DateTimeFormat("fr-CA", {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  });

  const fmt2 = new Intl.DateTimeFormat("en-GB", {
    year: "2-digit",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
  });

  const [currentDate, setCurrentDate] = useState(new Date());
  const [filterApi, setFilterApi] = useState({
    entity: "",
    divisi: "",
    dept: "",
    section: "",
    cost: "",
    unit: "",
    startDate: fmt.format(new Date(currentDate)),
    endDate: fmt.format(new Date(currentDate)),
  });
  const [takePage, setTakePage] = useState(10);
  const [page, setPage] = useState(1);
  const [dataTrxHistory, setDataTrxHistory] = useState([]);
  const [dataEntity, setDataEntity] = useState([]);
  const [dataDivision, setDataDivision] = useState([]);
  const [dataDepartment, setDataDepartment] = useState([]);
  const [dataSection, setDataSection] = useState([]);
  const [dataCostCenter, setDataCostCenter] = useState([]);
  const [dataVehicle, setDataVehicle] = useState([]);
  const [dataOdo, setDataOdo] = useState("");
  const [open, setOpen] = useState(false);

  const handleChange = (event, value) => {
    setFilterApi({
      ...filterApi,
      [event.target.name]: event.target.value,
    });
    if (event.target.name === "entity") {
      getDivisionByEntity(value.id);
    }
  };

  const handleChangePage = (_, v) => {
    setPage(v);
  };

  const handleChangeTakePage = (e) => {
    setTakePage(e.target.value);
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const loadData = useCallback(() => {
    getAllTrxHistoryService(
      takePage,
      page,
      filterApi.entity,
      filterApi.divisi,
      filterApi.dept,
      filterApi.section,
      filterApi.cost,
      filterApi.unit,
      filterApi.startDate,
      filterApi.endDate
    )
      .then((res) => {
        const result = res.data;
        setDataTrxHistory(result);

        if (res.data.data.length === 0) {
          setPage(1);
        }
      })
      .catch((res) => {
        if (res.request.status === 401) {
          navigation("/");
        }
      });

    getEntityUserService()
      .then((res) => {
        const result = res.data;
        setDataEntity(result);
      })
      .catch(() => {});
  }, [filterApi, takePage, page, navigation]);

  useEffect(() => {
    loadData();
    setCurrentDate(new Date());
  }, [loadData]);

  const getDivisionByEntity = (id) => {
    getDivByEntityUserService(id)
      .then((res) => {
        const result = res.data;
        setDataDivision(result);
      })
      .catch(() => {});
  };

  const getDeptByDiv = (id) => {
    getDeptByDivUserService(id)
      .then((res) => {
        const result = res.data;
        setDataDepartment(result);
      })
      .catch(() => {});
  };

  const getSectionByDept = (id) => {
    getSectionByDeptUserService(id)
      .then((res) => {
        const result = res.data;
        setDataSection(result);
      })
      .catch(() => {});
  };

  const getCostCenterBySection = (id) => {
    getCostCenterBySectionUserService(id)
      .then((res) => {
        const result = res.data;
        setDataCostCenter(result);
      })
      .catch(() => {});
  };

  const getVehicleBySection = (id) => {
    getVehicleBySectionService(id)
      .then((res) => {
        const result = res.data;
        setDataVehicle(result);
      })
      .catch(() => {});
  };

  const getImageOdo = (odo) => {
    getImageOdoService(odo)
      .then((response) => {
        const blob = new Blob([response.data], { type: "image/jpeg" });
        const url = URL.createObjectURL(blob);
        setDataOdo(url);
      })
      .catch(() => {});
  };

  const handleExportToExcel = () => {
    getExportToExcelService(
      filterApi.entity,
      filterApi.divisi,
      filterApi.dept,
      filterApi.section,
      filterApi.cost,
      filterApi.unit,
      filterApi.startDate,
      filterApi.endDate
    )
      .then((res) => {
        const result = res.data.data;
        const dataExcel = result.map((item, index) => ({
          "#": index + 1,
          "Transaction Code": item.trxCode,
          "Fueling Date": item.issuedAt
            ? fmt2.format(new Date(item.issuedAt))
            : "",
          Entity: item.costCenterId.section
            ? item.costCenterId.section.departemen.divisi.entity.name
            : "",
          Division: item.costCenterId.section
            ? item.costCenterId.section.departemen.divisi.divisiNama
            : "",
          Department: item.costCenterId.section
            ? item.costCenterId.section.departemen.deptName
            : "",
          Section: item.costCenterId.section
            ? item.costCenterId.section.sectionName
            : "",
          "Cost Center": item.costCenterId
            ? item.costCenterId.costCenterName
            : "",
          Location: item.locationId ? item.locationId.lokasiAddress : "",
          "Unit Number": item.vehicleId ? item.vehicleId.unitNum : "",
          "Asset Number": item.vehicleId ? item.vehicleId.assetNumber : "",
          "Request by": item.requesterName,
          "Mileage (KM) Before": item.requestMileage,
          "Mileage (KM) After": item.transactionMileage,
          "Qty Request": item.requestQty,
          "Qty Issue": item.actualQty,
        }));
        const fileName = "Transaction History";
        const title = "Transaction History";
        const dataWithHeader = [
          [title],
          [],
          Object.keys(dataExcel[0]),
          ...dataExcel.map(Object.values),
        ];
        const worksheet = XLSX.utils.sheet_add_aoa(
          XLSX.utils.json_to_sheet([]),
          dataWithHeader,
          { origin: -1 }
        );
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
        XLSX.writeFile(workbook, fileName + ".xlsx");
      })
      .catch(() => {});
  };

  const handleSearch = (
    entity,
    divisi,
    dept,
    section,
    cost,
    unit,
    startDate,
    endDate
  ) => {
    setFilterApi((filterApi) => ({
      ...filterApi,
      entity: entity,
      divisi: divisi,
      dept: dept,
      section: section,
      cost: cost,
      unit: unit,
      startDate: startDate,
      endDate: endDate,
    }));
  };

  const handleViewOddo = (oddo) => {
    getImageOdo(oddo);
    handleOpen();
  };

  const handleChangeEntity = (value) => {
    if (value !== null) {
      getDivisionByEntity(value.id);
      setFilterApi((filterApi) => ({
        ...filterApi,
        entity: value.id,
      }));
    } else {
      setFilterApi((filterApi) => ({
        ...filterApi,
        entity: "",
      }));
      setDataDivision([]);
      setDataDepartment([]);
      setDataSection([]);
      setDataCostCenter([]);
      setDataVehicle([]);
    }
  };

  const handleChangeDiv = (value) => {
    if (value !== null) {
      getDeptByDiv(value.id);
      setFilterApi((filterApi) => ({
        ...filterApi,
        divisi: value.id,
      }));
    } else {
      setFilterApi((filterApi) => ({
        ...filterApi,
        divisi: "",
      }));
      setDataDepartment([]);
      setDataSection([]);
      setDataCostCenter([]);
      setDataVehicle([]);
    }
  };

  const handleChangeDept = (value) => {
    if (value !== null) {
      getSectionByDept(value.departemen_id);
      setFilterApi((filterApi) => ({
        ...filterApi,
        dept: value.departemen_id,
      }));
    } else {
      setFilterApi((filterApi) => ({
        ...filterApi,
        dept: "",
      }));
      setDataSection([]);
      setDataCostCenter([]);
      setDataVehicle([]);
    }
  };

  const handleChangeSec = (value) => {
    if (value !== null) {
      getCostCenterBySection(value.section_id);
      getVehicleBySection(value.section_id);
      setFilterApi((filterApi) => ({
        ...filterApi,
        section: value.section_id,
      }));
    } else {
      setFilterApi((filterApi) => ({
        ...filterApi,
        section: "",
      }));
      setDataCostCenter([]);
      setDataVehicle([]);
    }
  };

  const handleChangeCC = (value) => {
    if (value !== null) {
      setFilterApi((filterApi) => ({
        ...filterApi,
        cost: value.id,
      }));
    } else {
      setFilterApi((filterApi) => ({
        ...filterApi,
        cost: "",
      }));
    }
  };

  const handleChangeVehicle = (value) => {
    if (value !== null) {
      setFilterApi((filterApi) => ({
        ...filterApi,
        unit: value.id,
      }));
    } else {
      setFilterApi((filterApi) => ({
        ...filterApi,
        unit: "",
      }));
    }
  };

  return {
    handleChange,
    handleChangePage,
    handleChangeTakePage,
    handleClose,
    handleExportToExcel,
    handleSearch,
    handleViewOddo,
    handleChangeEntity,
    handleChangeDiv,
    handleChangeDept,
    handleChangeSec,
    handleChangeCC,
    handleChangeVehicle,
    dataCostCenter,
    dataDepartment,
    dataDivision,
    dataEntity,
    dataOdo,
    dataSection,
    dataTrxHistory,
    dataVehicle,
    open,
    filterApi,
    takePage,
    page,
  };
};
