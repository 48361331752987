import React, { useState, useEffect } from "react";
import {
  Container,
  Box,
  Typography,
  Alert,
  TextField,
  Checkbox,
  Button,
} from "@mui/material";
import { Row, Col } from "react-bootstrap";
import SideBars from "../../../components/SideBars";
import "../../../styles/setup/locationStyle/LocationsCreateFormStyle.css";
import { useNavigate } from "react-router-dom";
import { newLocationService } from "../../../services/apiService/apiSetup/masterLocation/ServiceApiLocation";
import Swal from "sweetalert2";

const LocationsCreateForm = () => {
  const navigation = useNavigate();

  const storedData = localStorage.getItem("userData");
  const userData = JSON.parse(storedData);

  const [dataLocation, setDataLocation] = useState({
    lokasiCode: 0,
    lokasiNama: "",
    lokasiAddress: "",
    isActive: false,
    createdBy: userData.id,
  });
  const [isError, setIsError] = useState("");
  const [isErrorCode, setIsErrorCode] = useState("");
  const [hideAlert, setHideAlert] = useState(false);
  const [disableBtn, setDisableBtn] = useState(false);

  const handleChange = (event) => {
    setDataLocation({
      ...dataLocation,
      [event.target.name]: event.target.value,
    });
  };

  const handleChangeCode = (event) => {
    setDataLocation({
      ...dataLocation,
      lokasiCode: event.target.value.trim(),
    });

    if (dataLocation.lokasiCode.length >= 10) {
      setIsErrorCode("Input code with maximum 10 characters");
    } else {
      setIsErrorCode("");
    }
  };

  const handleChangeCheckbox = (event) => {
    setDataLocation({
      ...dataLocation,
      isActive: event.target.checked,
    });
  };

  const createNewLocation = () => {
    if (dataLocation.lokasiCode.length === 0) {
      setIsError("Location code cannot be empty");
      setHideAlert(!hideAlert);
    } else if (dataLocation.lokasiCode.length < 2) {
      setIsError("Location code must have a minimum of 2 digits");
      setHideAlert(!hideAlert);
    } else if (dataLocation.lokasiCode.startsWith("0")) {
      setIsError("Location code cannot start with the number 0");
      setHideAlert(!hideAlert);
    } else if (dataLocation.lokasiNama === "") {
      setIsError("Location name cannot be empty");
      setHideAlert(!hideAlert);
    } else if (dataLocation.lokasiAddress === "") {
      setIsError("Location address cannot be empty");
      setHideAlert(!hideAlert);
    } else {
      newLocationService({
        lokasiCode: dataLocation.lokasiCode,
        lokasiNama: dataLocation.lokasiNama,
        lokasiAddress: dataLocation.lokasiAddress,
        isActive: dataLocation.isActive,
        createdBy: dataLocation.createdBy,
      })
        .then(() => {
          Swal.fire("Data added successfully", "", "success");
          navigation("/master-location");
        })
        .catch((res) => {
          if (res.response.data.statusCode === 422) {
            setIsError(res.response.data.message);
            setHideAlert(true);
          } else {
            setIsError("Data was not added successfully");
            setHideAlert(true);
          }
        });
    }
  };

  useEffect(() => {
    if (dataLocation.lokasiCode === 0 || dataLocation.lokasiNama === "") {
      setDisableBtn(true);
    } else {
      setDisableBtn(false);
    }
  }, [dataLocation]);

  return (
    <>
      <Box>
        <SideBars />

        <Container maxWidth="lg" sx={{ marginBottom: "150px" }}>
          <h4 className="title-page-ccf">Form Master Location</h4>
          {hideAlert && <Alert severity="error">{isError}</Alert>}

          <Box className="box-cost-center-form">
            <Box align="left">
              <Row>
                <Col sm={6}>
                  <Typography sx={{ fontSize: "16px", m: 1 }}>
                    Code <span style={{ color: "red" }}>*</span>
                  </Typography>
                </Col>
                <Col sm={6}>
                  <Typography sx={{ fontSize: "12px", color: "red" }}>
                    {isErrorCode}
                  </Typography>
                  <TextField
                    id="outlined-basic"
                    type="number"
                    variant="outlined"
                    sx={{ width: "100%" }}
                    size="small"
                    name="lokasiCode"
                    onChange={handleChangeCode}
                  />
                </Col>
              </Row>

              <br />

              <Row>
                <Col sm={6}>
                  <Typography sx={{ fontSize: "16px", m: 1 }}>
                    Name <span style={{ color: "red" }}>*</span>
                  </Typography>
                </Col>
                <Col sm={6}>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    sx={{ width: "100%" }}
                    size="small"
                    name="lokasiNama"
                    onChange={handleChange}
                  />
                </Col>
              </Row>

              <br />

              <Row>
                <Col sm={6}>
                  <Typography sx={{ fontSize: "16px", m: 1 }}>
                    Address <span style={{ color: "red" }}>*</span>
                  </Typography>
                </Col>
                <Col sm={6}>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    sx={{ width: "100%" }}
                    size="small"
                    name="lokasiAddress"
                    onChange={handleChange}
                  />
                </Col>
              </Row>

              <br />

              <Row>
                <Col>
                  <Typography sx={{ fontSize: "16px", m: 1 }}>
                    Active
                  </Typography>
                </Col>
                <Col>
                  <Checkbox
                    sx={{ marginLeft: "-12px" }}
                    name="isActive"
                    onChange={handleChangeCheckbox}
                  />
                </Col>
              </Row>
            </Box>

            <br />

            <Box align="right">
              <Row>
                <Col>
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: "#436FB1",
                      marginTop: "10px",
                    }}
                    onClick={() => navigation("/master-location")}
                  >
                    Back To List
                  </Button>

                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: "#084298",
                      marginTop: "10px",
                      marginLeft: "10px",
                    }}
                    onClick={() => createNewLocation()}
                    disabled={disableBtn ? true : false}
                  >
                    Save
                  </Button>
                </Col>
              </Row>
            </Box>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default LocationsCreateForm;
