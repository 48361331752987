import axios from "axios";

const getAllUsers = async (take = 10, page = 1, name = "") => {
  const storedData = localStorage.getItem("userData");
  const userData = JSON.parse(storedData);
  const token = !userData ? "" : userData.token;

  const getUsers = await axios({
    method: "get",
    url: `${process.env.REACT_APP_URL_TCM}/api/users?take=${take}&page=${page}&nama=${name}`,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return getUsers;
};

const newUserService = async (form) => {
  const json = JSON.stringify(form);
  const storedData = localStorage.getItem("userData");
  const userData = JSON.parse(storedData);
  const token = !userData ? "" : userData.token;

  const users = await axios.post(
    `${process.env.REACT_APP_URL_TCM}/api/users/new-user`,
    json,
    {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return users;
};

const deleteUserService = async (id) => {
  const storedData = localStorage.getItem("userData");
  const userData = JSON.parse(storedData);
  const token = !userData ? "" : userData.token;

  const users = await axios.delete(
    `${process.env.REACT_APP_URL_TCM}/api/users/${id}`,
    {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return users;
};

const updateUserService = async (form, id) => {
  const json = JSON.stringify(form);
  const storedData = localStorage.getItem("userData");
  const userData = JSON.parse(storedData);
  const token = !userData ? "" : userData.token;

  const users = await axios.put(
    `${process.env.REACT_APP_URL_TCM}/api/users/update-profile/${id}`,
    json,
    {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return users;
};

const updatePasswordService = async (form, id) => {
  const json = JSON.stringify(form);
  const storedData = localStorage.getItem("userData");
  const userData = JSON.parse(storedData);
  const token = !userData ? "" : userData.token;

  const users = await axios.put(
    `${process.env.REACT_APP_URL_TCM}/api/users/update-password/${id}`,
    json,
    {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return users;
};


export {
  getAllUsers,
  newUserService,
  deleteUserService,
  updateUserService,
  updatePasswordService,
};
