import React, { Fragment } from "react";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  Paper,
  styled,
  TableRow,
  Box,
  Typography,
  Grid,
} from "@mui/material";
import { TabContext, TabPanel } from "@mui/lab";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Create";
import { useNavigate } from "react-router-dom";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#8FD0EF",
    color: "#484848",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: "#F0F0F0",
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function TableMenus(props) {
  const {
    deleteMenus,
    dataAllMenu,
    takePage,
    page,
    handleBtnChild,
    open,
    menuChild,
  } = props;
  const navigation = useNavigate();

  const fmt = new Intl.DateTimeFormat("en-GB", {
    year: "2-digit",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  });

  return (
    <>
      <TableContainer component={Paper} className="table-container">
        <Table
          sx={{ minWidth: 700 }}
          aria-label="customized table"
          className="table-approval"
        >
          <TableHead>
            <TableRow>
              <StyledTableCell sx={{ fontWeight: "bold" }}>#</StyledTableCell>
              <StyledTableCell sx={{ fontWeight: "bold" }}>
                Actions
              </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: "bold" }}>
                Menu Name
              </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: "bold" }}>
                Menu Index
              </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: "bold" }}>
                URL
              </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: "bold" }}>
                Descriptions
              </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: "bold" }}>
                User Created
              </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: "bold" }}>
                User Updated
              </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: "bold" }}>
                Date Created
              </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: "bold" }}>
                Date Updated
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataAllMenu.data.map((item, index) => (
              <Fragment key={item.id}>
                <StyledTableRow key={item.id}>
                  <StyledTableCell>
                    {(page - 1) * takePage + index + 1}
                  </StyledTableCell>
                  <StyledTableCell sx={{ minWidth: "110px" }}>
                    <EditIcon
                      className="icon-edit"
                      onClick={() =>
                        navigation("/form-master-menu-update", {
                          state: item,
                        })
                      }
                    />
                    <DeleteIcon
                      className="icon-delete"
                      onClick={() => deleteMenus(item.id)}
                    />
                    {open === item.id ? (
                      <KeyboardArrowUpIcon
                        onClick={() => handleBtnChild(item)}
                      />
                    ) : (
                      <KeyboardArrowDownIcon
                        onClick={() => handleBtnChild(item)}
                      />
                    )}
                  </StyledTableCell>
                  <StyledTableCell>{item.menuName}</StyledTableCell>
                  <StyledTableCell>{item.menuSeq}</StyledTableCell>
                  <StyledTableCell>{item.slug}</StyledTableCell>
                  <StyledTableCell>{item.menuDesc}</StyledTableCell>
                  <StyledTableCell>
                    {item.createdBy ? item.createdBy.nama : "-"}
                  </StyledTableCell>
                  <StyledTableCell>
                    {item.updatedBy ? item.updatedBy.nama : "-"}
                  </StyledTableCell>
                  <StyledTableCell>
                    {item.createdAt === null
                      ? "-"
                      : fmt.format(new Date(item.createdAt))}
                  </StyledTableCell>
                  <StyledTableCell>
                    {item.updatedAt === null
                      ? "-"
                      : fmt.format(new Date(item.updatedAt))}
                  </StyledTableCell>
                </StyledTableRow>

                {open === item.id && (
                  <StyledTableRow>
                    <StyledTableCell
                      style={{
                        backgroundColor: "white",
                      }}
                      colSpan={10}
                    >
                      <Box sx={{ margin: 2 }}>
                        <Typography variant="h6" gutterBottom>
                          Child Menu
                        </Typography>

                        <TabContext value="child-menu">
                          <Box
                            sx={{
                              border: 1,
                              borderColor: "divider",
                            }}
                          >
                            <TabPanel value="child-menu" sx={{ margin: 2 }}>
                              <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                                spacing={2}
                              >
                                <Table
                                  size="small"
                                  aria-label="purchases"
                                  sx={{
                                    marginTop: "20px",
                                    border: 1,
                                    borderColor: "#e0e0e0",
                                  }}
                                >
                                  <TableHead>
                                    <TableRow>
                                      <TableCell sx={{ fontWeight: "bold" }}>
                                        #
                                      </TableCell>
                                      <TableCell sx={{ fontWeight: "bold" }}>
                                        Actions
                                      </TableCell>
                                      <TableCell sx={{ fontWeight: "bold" }}>
                                        Menu Name
                                      </TableCell>
                                      <TableCell sx={{ fontWeight: "bold" }}>
                                        Menu Index
                                      </TableCell>
                                      <TableCell sx={{ fontWeight: "bold" }}>
                                        URL
                                      </TableCell>
                                      <TableCell sx={{ fontWeight: "bold" }}>
                                        Descriptions
                                      </TableCell>
                                      <TableCell sx={{ fontWeight: "bold" }}>
                                        User Created
                                      </TableCell>
                                      <TableCell sx={{ fontWeight: "bold" }}>
                                        User Updated
                                      </TableCell>
                                      <TableCell sx={{ fontWeight: "bold" }}>
                                        Date Created
                                      </TableCell>
                                      <TableCell sx={{ fontWeight: "bold" }}>
                                        Date Updated
                                      </TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {menuChild.map((data, index) => (
                                      <TableRow key={data.id}>
                                        <TableCell>
                                          {(page - 1) * takePage + index + 1}
                                        </TableCell>
                                        <TableCell sx={{ minWidth: "80px" }}>
                                          <EditIcon
                                            className="icon-edit"
                                            onClick={() =>
                                              navigation(
                                                "/form-master-menu-update",
                                                {
                                                  state: data,
                                                }
                                              )
                                            }
                                          />
                                          <DeleteIcon
                                            className="icon-delete"
                                            onClick={() =>
                                              deleteMenus(data.id, item.id)
                                            }
                                          />
                                        </TableCell>
                                        <TableCell>{data.menuName}</TableCell>
                                        <TableCell>{data.menuSeq}</TableCell>
                                        <TableCell>{data.slug}</TableCell>
                                        <TableCell>{data.menuDesc}</TableCell>
                                        <TableCell>
                                          {data.createdBy
                                            ? data.createdBy.nama
                                            : "-"}
                                        </TableCell>
                                        <TableCell>
                                          {data.updatedBy
                                            ? data.updatedBy.nama
                                            : "-"}
                                        </TableCell>
                                        <TableCell>
                                          {data.createdAt === null
                                            ? "-"
                                            : fmt.format(
                                                new Date(data.createdAt)
                                              )}
                                        </TableCell>
                                        <TableCell>
                                          {data.updatedAt === null
                                            ? "-"
                                            : fmt.format(
                                                new Date(data.updatedAt)
                                              )}
                                        </TableCell>
                                      </TableRow>
                                    ))}
                                  </TableBody>
                                </Table>
                              </Grid>
                            </TabPanel>
                          </Box>
                        </TabContext>
                      </Box>
                    </StyledTableCell>
                  </StyledTableRow>
                )}
              </Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
