import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "../../styles/login/LoginStyle.css";
import { userLoginByPassService } from "../../services/apiService/apiLogin/ServiceApiLogin";
import { useEffect } from "react";
import Loading from "../../assets/approvalAssets/loading.gif";
import { Box, Alert } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "3px solid #e9e7e7",
  borderRadius: "10px",
  boxShadow: 24,
};

const LoginsByPass = () => {
  const navigation = useNavigate();

  const [isError, setIsError] = useState("");
  const [hideAlert, setHideAlert] = useState(false);
  const [apiCalled, setApiCalled] = useState(false);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const email = searchParams.get("email");

  useEffect(() => {
    if (email !== null && !apiCalled) {
      setApiCalled(true);
      userLoginByPassService({
        email: email,
      })
        .then((res) => {
          const result = res.data;
          localStorage.setItem("userData", JSON.stringify(result));
          setTimeout(() => {
            navigation("/approval");
          }, 1000);
        })
        .catch((res) => {
          setIsError("Wrong email");
          setHideAlert(true);
          setTimeout(() => {
            navigation("/");
          }, 1000);
        });
    }
  }, [navigation, email, apiCalled]);

  return (
    <>
      {hideAlert && <Alert severity="error">{isError}</Alert>}

      <Box sx={style}>
        <img
          src={Loading}
          alt="loading"
          style={{
            height: "100px",
          }}
        />
      </Box>
    </>
  );
};

export default LoginsByPass;
