import axios from "axios";

const getAllRole = async () => {
  const storedData = localStorage.getItem("userData");
  const userData = JSON.parse(storedData);
  const token = !userData ? "" : userData.token;

  const getRole = await axios({
    method: "get",
    url: `${process.env.REACT_APP_URL_TCM}/api/role/all`,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return getRole;
};


const newRoleService = async (form) => {
  const json = JSON.stringify(form);
  const storedData = localStorage.getItem("userData");
  const userData = JSON.parse(storedData);
  const token = !userData ? "" : userData.token;

  const createRole = await axios.post(
    `${process.env.REACT_APP_URL_TCM}/api/role/new`,
    json,
    {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return createRole;
};

const newPermissionService = async (form) => {
  const json = JSON.stringify(form);
  const storedData = localStorage.getItem("userData");
  const userData = JSON.parse(storedData);
  const token = !userData ? "" : userData.token;

  const permission = await axios.post(
    `${process.env.REACT_APP_URL_TCM}/api/role-permission/new`,
    json,
    {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return permission;
};


const updateRoleService = async (form, id) => {
  const json = JSON.stringify(form);
  const storedData = localStorage.getItem("userData");
  const userData = JSON.parse(storedData);
  const token = !userData ? "" : userData.token;

  const role = await axios.put(
    `${process.env.REACT_APP_URL_TCM}/api/role/update/${id}`,
    json,
    {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return role;
};


// const deleteUserService = async (id) => {
//   const storedData = localStorage.getItem("userData");
//   const userData = JSON.parse(storedData);
//   const token = !userData ? "" : userData.token;

//   const users = await axios.delete(
//     `${process.env.REACT_APP_URL_TCM}/api/users/${id}`,
//     {
//       withCredentials: true,
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: `Bearer ${token}`,
//       },
//     }
//   );
//   return users;
// };


export {
  getAllRole,
  newRoleService,
  newPermissionService,
  updateRoleService,
  // deleteUserService,
};
