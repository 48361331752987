import React, { useState, useEffect } from "react";
import {
  Container,
  Box,
  Typography,
  Alert,
  TextField,
  Checkbox,
  Button,
} from "@mui/material";
import { Row, Col } from "react-bootstrap";
import SideBars from "../../../components/SideBars";
import "../../../styles/setup/sectionStyle/SectionUpdateFormStyle.css";
import { useLocation, useNavigate } from "react-router-dom";
import { updateSectionService } from "../../../services/apiService/apiSetup/masterSection/ServiceApiSection";
import Swal from "sweetalert2";

const SectionUpdateForm = () => {
  const navigation = useNavigate();
  const location = useLocation();

  const storedData = localStorage.getItem("userData");
  const userData = JSON.parse(storedData);

  const [dataSection, setDataSection] = useState({
    sectionName: location.state.sectionName,
    description: "",
    isActive: location.state.isActive,
    updatedBy: userData.id,
  });
  const [isError, setIsError] = useState("");
  const [hideAlert, setHideAlert] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const handleChange = (event) => {
    setDataSection({
      ...dataSection,
      [event.target.name]: event.target.value,
    });
  };

  const handleChangeCheckbox = (event) => {
    setDataSection({
      ...dataSection,
      isActive: event.target.checked,
    });
  };

  const updateSection = (
    { sectionName, description, isActive, updatedBy },
    id
  ) => {
    updateSectionService(
      {
        sectionName:
          dataSection.sectionName === ""
            ? sectionName
            : dataSection.sectionName,
        description:
          dataSection.description === ""
            ? description
            : dataSection.description,
        isActive: dataSection.isActive === "" ? isActive : dataSection.isActive,
        updatedBy:
          dataSection.updatedBy === "" ? updatedBy : dataSection.updatedBy,
      },
      id
    )
      .then(() => {
        Swal.fire("Data updated successfully", "", "success");
        navigation("/master-section");
      })
      .catch((res) => {
        if (res.response.data.statusCode === 422) {
          setIsError(res.response.data.message);
          setHideAlert(true);
        } else {
          setIsError("Data was not updated successfully");
          setHideAlert(true);
        }
      });
  };

  useEffect(() => {
    if (dataSection.sectionName === "") {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [dataSection]);

  return (
    <>
      <Box>
        <SideBars />

        <Container maxWidth="lg" sx={{ marginBottom: "150px" }}>
          <h4 className="title-page-ccf">Form Master Section</h4>
          {hideAlert && <Alert severity="error">{isError}</Alert>}
          <Box className="box-cost-center-form">
            <Box align="left">
              <Row>
                <Col sm={6}>
                  <Typography sx={{ fontSize: "16px", m: 1 }}>
                    Entity
                  </Typography>
                </Col>
                <Col sm={6}>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    sx={{ width: "100%" }}
                    size="small"
                    defaultValue={
                      location.state.departemen.divisi.entity === null
                        ? "-"
                        : location.state.departemen.divisi.entity.name
                    }
                    name="entity"
                    disabled={true}
                  />
                </Col>
              </Row>

              <br />

              <Row>
                <Col sm={6}>
                  <Typography sx={{ fontSize: "16px", m: 1 }}>
                    Division
                  </Typography>
                </Col>
                <Col sm={6}>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    sx={{ width: "100%" }}
                    size="small"
                    defaultValue={location.state.departemen.divisi.divisiNama}
                    name="divisi"
                    disabled={true}
                  />
                </Col>
              </Row>

              <br />

              <Row>
                <Col sm={6}>
                  <Typography sx={{ fontSize: "16px", m: 1 }}>
                    Department
                  </Typography>
                </Col>
                <Col sm={6}>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    sx={{ width: "100%" }}
                    size="small"
                    defaultValue={location.state.departemen.deptName}
                    name="departemen"
                    disabled={true}
                  />
                </Col>
              </Row>

              <br />

              <Row>
                <Col sm={6}>
                  <Typography sx={{ fontSize: "16px", m: 1 }}>Code</Typography>
                </Col>
                <Col sm={6}>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    sx={{ width: "100%" }}
                    size="small"
                    defaultValue={location.state.sectionCode}
                    name="deptCode"
                    disabled={true}
                  />
                </Col>
              </Row>

              <br />

              <Row>
                <Col sm={6}>
                  <Typography sx={{ fontSize: "16px", m: 1 }}>Name</Typography>
                </Col>
                <Col sm={6}>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    sx={{ width: "100%" }}
                    size="small"
                    defaultValue={location.state.sectionName}
                    name="sectionName"
                    onChange={handleChange}
                  />
                </Col>
              </Row>

              <br />

              <Row>
                <Col sm={6}>
                  <Typography sx={{ fontSize: "16px", m: 1 }}>
                    Description
                  </Typography>
                </Col>
                <Col sm={6}>
                  <TextField
                    id="outlined-multiline-static"
                    multiline
                    rows={4}
                    sx={{ width: "100%" }}
                    defaultValue={location.state.description}
                    name="description"
                    onChange={handleChange}
                  />
                </Col>
              </Row>

              <br />

              <Row>
                <Col>
                  <Typography sx={{ fontSize: "16px", m: 1 }}>
                    Active
                  </Typography>
                </Col>
                <Col>
                  <Checkbox
                    sx={{ marginLeft: "-12px" }}
                    defaultChecked={location.state.isActive}
                    name="isActive"
                    onChange={handleChangeCheckbox}
                  />
                </Col>
              </Row>
            </Box>

            <br />

            <Box align="right">
              <Row>
                <Col>
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: "#436FB1",
                      marginTop: "10px",
                    }}
                    onClick={() => navigation("/master-section")}
                  >
                    Back To List
                  </Button>

                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: "#084298",
                      marginTop: "10px",
                      marginLeft: "10px",
                    }}
                    onClick={() =>
                      updateSection(location.state, location.state.id)
                    }
                    disabled={disabled ? true : false}
                  >
                    Save
                  </Button>
                </Col>
              </Row>
            </Box>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default SectionUpdateForm;
