import axios from "axios";

const getAllTrxHistoryService = async (
  limit = 10,
  page = 1,
  entity = "",
  divisi = "",
  dept = "",
  section = "",
  cost = "",
  unit = "",
  startDate = "",
  endDate = ""
) => {
  const storedData = localStorage.getItem("userData");
  const userData = JSON.parse(storedData);
  const token = !userData ? "" : userData.token;

  const request = await axios({
    method: "get",
    url: `${process.env.REACT_APP_URL_TCM}/api/ebbm-request/dataFiltersHistory?limit=${limit}&page=${page}&entity=${entity}&divisi=${divisi}&dept=${dept}&section=${section}&cost=${cost}&unit=${unit}&startDate=${startDate}&endDate=${endDate}`,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return request;
};

const getImageOdoService = async (odo) => {
  const storedData = localStorage.getItem("userData");
  const userData = JSON.parse(storedData);
  const token = !userData ? "" : userData.token;

  const request = await axios({
    method: "get",
    url: `${process.env.REACT_APP_URL_TCM}/api/ebbm-request/vehicle/oddo/${odo}`,
    withCredentials: true,
    responseType: "arraybuffer",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return request;
};

const getExportToExcelService = async (
  entity = "",
  divisi = "",
  dept = "",
  section = "",
  cost = "",
  unit = "",
  startDate = "",
  endDate = ""
) => {
  const storedData = localStorage.getItem("userData");
  const userData = JSON.parse(storedData);
  const token = !userData ? "" : userData.token;

  const request = await axios({
    method: "get",
    url: `${process.env.REACT_APP_URL_TCM}/api/ebbm-request/dataFiltersHistory?limit=0&page=1&entity=${entity}&divisi=${divisi}&dept=${dept}&section=${section}&cost=${cost}&unit=${unit}&startDate=${startDate}&endDate=${endDate}`,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return request;
};

export { getAllTrxHistoryService, getImageOdoService, getExportToExcelService };
