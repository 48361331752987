import React from "react";
import SideBars from "../../../components/SideBars";
import {
  Container,
  Grid,
  Typography,
  Button,
  TextField,
  Box,
} from "@mui/material";
import "../../../styles/setup/costCenterStyle/CostCenterStyle.css";
import PaginationCostCenter from "./components/PaginationCostCenter";
import TableCostCenter from "./components/TableCostCenter";
import { LogicContainer } from "./logics/LogicContainer";

const CostCenter = () => {
  const {
    searchName,
    page,
    takePage,
    dataCostCenter,
    handleChangePage,
    handleChangeTakePage,
    deleteCostCenter,
    navigation,
    handleSearch,
    handleChangeSearch,
  } = LogicContainer();

  if (!dataCostCenter.data) {
    return null;
  }

  return (
    <>
      <Box>
        <SideBars />
        <Container maxWidth="xl" sx={{ marginBottom: "150px" }}>
          <h3 className="title-page-setup">Master Cost Center</h3>
          <Box
            sx={{
              padding: "30px",
              borderRadius: "10px",
              border: "3px solid #e9e7e7",
            }}
          >
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={2}
            >
              <Grid>
                <Typography
                  sx={{ fontSize: "16px", paddingTop: 1, marginRight: "10px" }}
                >
                  Search by Cost Center Name
                </Typography>
              </Grid>
              <Grid>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  size="small"
                  sx={{ width: "300px" }}
                  onChange={handleChangeSearch}
                />
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={2}
              sx={{ marginTop: "10px" }}
            >
              <Grid>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "#6EA8FE",
                    minWidth: "200px",
                    fontSize: "16px",
                    textTransform: "none",
                  }}
                  onClick={() => handleSearch(searchName)}
                  size="small"
                >
                  Find
                </Button>
              </Grid>
            </Grid>
          </Box>

          <Box align="right" className="box-new-setup">
            <Button
              variant="contained"
              size="small"
              sx={{
                backgroundColor: "#084298",
                marginBottom: "10px",
                fontSize: "16px",
                textTransform: "none",
              }}
              onClick={() => navigation("/form-cost-center")}
            >
              New Cost Center
            </Button>
          </Box>

          <Box>
            <TableCostCenter
              deleteCostCenter={deleteCostCenter}
              dataCostCenter={dataCostCenter}
              takePage={takePage}
              page={page}
            />
          </Box>

          <Box sx={{ display: "flex", flexWrap: "wrap", marginTop: "20px" }}>
            <PaginationCostCenter
              handleChangeTakePage={handleChangeTakePage}
              handleChangePage={handleChangePage}
              dataCostCenter={dataCostCenter}
              takePage={takePage}
              page={page}
            />
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default CostCenter;
