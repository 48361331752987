import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getAllValidation } from "../../../../services/apiService/apiTransaction/validation/ServiceApiValidation";

export const ValidationLogic = () => {
  const navigation = useNavigate();

  // const storedData = localStorage.getItem("userData");
  // const userData = JSON.parse(storedData);

  const [takePage, setTakePage] = useState(10);
  const [page, setPage] = useState(1);
  const [searchCodeTrx, setSearchCodeTrx] = useState("");
  const [
    open,
    // setOpen
  ] = useState(false);
  const [dataValidation, setDataValidation] = useState([]);

  const handleChangePage = (_, v) => {
    setPage(v);
  };

  const handleChangeTakePage = (e) => {
    setTakePage(e.target.value);
  };

  const handleChangeSearch = (e) => {
    setSearchCodeTrx(e.target.value);
  };

  const handleSearch = (codeTrx) => {
    setSearchCodeTrx(codeTrx);
    loadData();
  };

  const loadData = useCallback(() => {
    getAllValidation(takePage, page, searchCodeTrx)
      .then((res) => {
        const result = res.data;
        setDataValidation(result);
        if (res.data.data.length === 0) {
          setPage(1);
        }
      })
      .catch((res) => {
        if (res.request.status === 401) {
          navigation("/");
        }
      });
  }, [takePage, page, searchCodeTrx, navigation]);

  useEffect(() => {
    loadData();
    const interval = setInterval(loadData, 7000);

    return () => clearInterval(interval);
  }, [loadData]);

  return {
    page,
    dataValidation,
    takePage,
    searchCodeTrx,
    open,
    handleChangePage,
    handleChangeSearch,
    handleChangeTakePage,
    handleSearch,
  };
};
