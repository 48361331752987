import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import SideBars from "../../components/SideBars";
import { Container, Box } from "@mui/material";
import AddTaskIcon from "@mui/icons-material/AddTask";
import ApprovalIcon from "@mui/icons-material/Approval";
import NoCrashIcon from "@mui/icons-material/NoCrash";
import AddchartIcon from "@mui/icons-material/Addchart";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import {
  getDataDashboardService,
  getDataChartService,
} from "../../services/apiService/apiDashboard/ServiceDashboard";
import "../../styles/dashboard/DashboardStyle.css";
import { useNavigate } from "react-router-dom";
import ChartTrx from "./components/ChartTrx";
import TableTrx from "./components/TableTrx";

const Dashboards = () => {
  const navigation = useNavigate();
  const [dataDashboard, setDataDashboard] = useState([]);
  const [dataChart, setDataChart] = useState([]);

  useEffect(() => {
    getDataDashboardService()
      .then((res) => {
        const result = res.data;
        setDataDashboard(result);
      })
      .catch((res) => {
        if (res.request.status === 401) {
          navigation("/");
        }
      });

    getDataChartService()
      .then((res) => {
        const result = res.data;
        setDataChart(result);
      })
      .catch((res) => {
        if (res.request.status === 401) {
          navigation("/");
        }
      });
  }, [setDataDashboard, setDataChart, navigation]);

  if (!dataChart.qty_transaction) {
    return null;
  }

  return (
    <>
      <Box>
        <SideBars />

        <Container maxWidth="xl" sx={{ marginBottom: "150px" }}>
          <Box className="box-card">
            <Row className="row-card1">
              <Col xs={4}>
                <Box className="schedule">
                  <Row>
                    <Col xl={1} className="col-icon">
                      <AddTaskIcon
                        sx={{ color: "white", height: "50px", width: "50px" }}
                      />
                    </Col>
                    <Col xl={11} className="col-text ">
                      <span className="card-text">Submit Request</span>
                      <br />
                      <span className="card-text">
                        {dataDashboard.submit_request === undefined
                          ? 0
                          : dataDashboard.submit_request.submit_total}
                      </span>
                    </Col>
                  </Row>
                </Box>
              </Col>

              <Col xs={4}>
                <Box className="outstanding">
                  <Row>
                    <Col xl={1} className="col-icon">
                      <ApprovalIcon
                        sx={{ color: "white", height: "50px", width: "50px" }}
                      />
                    </Col>
                    <Col xl={11} className="col-text ">
                      <span className="card-text">Outstanding</span>
                      <br />
                      <span className="card-text">
                        {dataDashboard.outstanding_approval === undefined
                          ? 0
                          : dataDashboard.outstanding_approval
                              .outstanding_approval_total}
                      </span>
                    </Col>
                  </Row>
                </Box>
              </Col>

              <Col xs={4}>
                <Box className="transaction">
                  <Row>
                    <Col xl={1} className="col-icon">
                      <NoCrashIcon
                        sx={{ color: "white", height: "50px", width: "50px" }}
                      />
                    </Col>
                    <Col xl={11} className="col-text ">
                      <span className="card-text">Transaction Success</span>
                      <br />
                      <span className="card-text">
                        {dataDashboard.transaction_success === undefined
                          ? 0
                          : dataDashboard.transaction_success
                              .transaction_success_total}
                      </span>
                    </Col>
                  </Row>
                </Box>
              </Col>
            </Row>

            <Row>
              <Col xs={4}>
                <Box className="qty-submit">
                  <Row>
                    <Col xl={1} className="col-icon">
                      <AddchartIcon
                        sx={{ color: "white", height: "50px", width: "50px" }}
                      />
                    </Col>
                    <Col xl={11} className="col-text ">
                      <span className="card-text">Qty Submit</span>
                      <br />
                      <span className="card-text">
                        {dataDashboard.submit_request === undefined ||
                        dataDashboard.submit_request.qty_submit === null
                          ? 0
                          : dataDashboard.submit_request.qty_submit}{" "}
                        (Ltr)
                      </span>
                    </Col>
                  </Row>
                </Box>
              </Col>

              <Col xs={4}>
                <Box className="qty-outstanding">
                  <Row>
                    <Col xl={1} className="col-icon">
                      <AccessTimeIcon
                        sx={{ color: "white", height: "50px", width: "50px" }}
                      />
                    </Col>
                    <Col xl={11} className="col-text ">
                      <span className="card-text">Qty Outstanding</span>
                      <br />
                      <span className="card-text">
                        {dataDashboard.outstanding_approval === undefined ||
                        dataDashboard.outstanding_approval.qty_outstanding ===
                          null
                          ? 0
                          : dataDashboard.outstanding_approval
                              .qty_outstanding}{" "}
                        (Ltr)
                      </span>
                    </Col>
                  </Row>
                </Box>
              </Col>

              <Col xs={4}>
                <Box className="qty-issue">
                  <Row>
                    <Col xl={1} className="col-icon">
                      <DoneAllIcon
                        sx={{ color: "white", height: "50px", width: "50px" }}
                      />
                    </Col>
                    <Col xl={11} className="col-text ">
                      <span className="card-text">Qty Issue</span>
                      <br />
                      <span className="card-text">
                        {dataDashboard.transaction_success === undefined ||
                        dataDashboard.transaction_success.qty_success === null
                          ? 0
                          : dataDashboard.transaction_success.qty_success}{" "}
                        (Ltr)
                      </span>
                    </Col>
                  </Row>
                </Box>
              </Col>
            </Row>
          </Box>

          <h4>QTY TRANSACTION by Day</h4>

          <Box>
            <Row>
              <Col xl={8}>
                <div className="box-chart">
                  <ChartTrx dataChart={dataChart} />
                </div>
              </Col>

              <Col xl={4}>
                <div className="box-table">
                  <TableTrx dataDashboard={dataDashboard} />
                </div>
              </Col>
            </Row>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default Dashboards;
