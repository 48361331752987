import React from "react";
import Logins from "../../pages/login/Logins";
import Dashboards from "../../pages/dashboard/Dashboards";
import FuelingRequest from "../../pages/transaction/fuelingRequest/FuelingRequest";
import FuelingRequestCreateForm from "../../pages/transaction/fuelingRequest/FuelingRequestCreateForm";
import Approvals from "../../pages/transaction/approval/Approvals";
import CostCenter from "../../pages/setup/masterCostCenter/CostCenter";
import CostCenterCreateForm from "../../pages/setup/masterCostCenter/CostCenterCreateForm";
import CostCenterUpdateForm from "../../pages/setup/masterCostCenter/CostCenterUpdateForm";
import Usernames from "../../pages/setup/masterUser/Usernames";
import UsernamesCreateForm from "../../pages/setup/masterUser/UsernamesCreateForm";
import UsernamesUpdateForm from "../../pages/setup/masterUser/UsernamesUpdateForm";
import Vehicles from "../../pages/setup/masterVehicle/Vehicles";
import VehicleCreateForm from "../../pages/setup/masterVehicle/VehicleCreateForm";
import VehicleUpdateForm from "../../pages/setup/masterVehicle/VehicleUpdateForm";
import VehiclesType from "../../pages/setup/masterVehicleType/VehiclesType";
import VehicleTypeCreateForm from "../../pages/setup/masterVehicleType/VehicleTypeCreateForm";
import VehicleTypeUpdateForm from "../../pages/setup/masterVehicleType/VehicleTypeUpdateForm";
import Entity from "../../pages/setup/masterEntity/Entity";
import EntityCreateForm from "../../pages/setup/masterEntity/EntityCreateForm";
import EntityUpdateForm from "../../pages/setup/masterEntity/EntityUpdateForm";
import Departments from "../../pages/setup/masterDepartment/Departments";
import DepartmentsCreateForm from "../../pages/setup/masterDepartment/DepartmentsCreateForm";
import DepartmentsUpdateForm from "../../pages/setup/masterDepartment/DepartmentsUpdateForm";
import Divisions from "../../pages/setup/masterDivision/Divisions";
import DivisionsCreateForm from "../../pages/setup/masterDivision/DivisionsCreateForm";
import DivisionsUpdateForm from "../../pages/setup/masterDivision/DivisionsUpdateForm";
import Locations from "../../pages/setup/masterLocation/Locations";
import LocationsCreateForm from "../../pages/setup/masterLocation/LocationsCreateForm";
import LocationsUpdateForm from "../../pages/setup/masterLocation/LocationsUpdateForm";
import Providers from "../../pages/setup/masterProvider/Providers";
import ProvidersCreateForm from "../../pages/setup/masterProvider/ProvidersCreateForm";
import ProvidersUpdateForm from "../../pages/setup/masterProvider/ProvidersUpdateForm";
import ProfileUpdateForm from "../../pages/profile/ProfileUpdateForm";
import TransactionHistory from "../../pages/report/transactionHistory/TransactionHistory";
import UserAuthorization from "../../pages/setup/userAuthorization/UserAuthorization";
import RolePermission from "../../pages/rolePermission/RolePermission";
import RoleCreateForm from "../../pages/rolePermission/RoleCreateForm";
import RoleUpdateForm from "../../pages/rolePermission/RoleUpdateForm";
import LoginsByPass from "../../pages/login/LoginsByPass";
import LogMasterData from "../../pages/report/logMasterData/LogMasterData";
import LogTransaction from "../../pages/report/logTransaction/LogTransaction";
import LogAuth from "../../pages/report/logAuth/LogAuth";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Section from "../../pages/setup/masterSection/Section";
import SectionCreateForm from "../../pages/setup/masterSection/SectionCreateForm";
import SectionUpdateForm from "../../pages/setup/masterSection/SectionUpdateForm";
// import ApprovalByPass from "../../pages/transaction/approval/ApprovalByPass";
import Menus from "../../pages/setup/masterMenu/Menus";
import MenusCreateForm from "../../pages/setup/masterMenu/MenusCreateForm";
import MenusUpdateForm from "../../pages/setup/masterMenu/MenusUpdateForm";
import PermissionCreateForm from "../../pages/rolePermission/PermissionCreateForm";
import IssuedTransaction from "../../pages/report/issuedTransaction/IssuedTransaction";
import Validations from "../../pages/transaction/validation/Validations";
import { PageNotFound } from "../../components/PageNotFound";

const routes = [
  { path: "/", element: <Logins /> },
  {
    path: `/ready-approve`,
    element: <LoginsByPass />,
  },

  { path: "/dashboard", element: <Dashboards /> },
  { path: "/fueling-request", element: <FuelingRequest /> },
  {
    path: "/form-fueling-request",
    element: <FuelingRequestCreateForm />,
  },
  { path: "/approval", element: <Approvals /> },
  // { path: "/approval-bypass", element: <ApprovalByPass /> },
  {
    path: "/user-authorization",
    element: <UserAuthorization />,
  },
  { path: "/cost-center", element: <CostCenter /> },
  {
    path: "/form-cost-center",
    element: <CostCenterCreateForm />,
  },
  {
    path: "/form-cost-center-update",
    element: <CostCenterUpdateForm />,
  },
  { path: "/master-menu", element: <Menus /> },
  {
    path: "/form-master-menu",
    element: <MenusCreateForm />,
  },
  {
    path: "/form-master-menu-update",
    element: <MenusUpdateForm />,
  },
  { path: "/master-user", element: <Usernames /> },
  {
    path: "/form-master-user",
    element: <UsernamesCreateForm />,
  },
  {
    path: "/form-master-user-update",
    element: <UsernamesUpdateForm />,
  },
  { path: "/master-vehicle", element: <Vehicles /> },
  {
    path: "/form-master-vehicle",
    element: <VehicleCreateForm />,
  },
  {
    path: "/form-master-vehicle-update",
    element: <VehicleUpdateForm />,
  },
  { path: "/master-vehicle-type", element: <VehiclesType /> },
  {
    path: "/form-master-vehicle-type",
    element: <VehicleTypeCreateForm />,
  },
  {
    path: "/form-master-vehicle-type-update",
    element: <VehicleTypeUpdateForm />,
  },
  { path: "/master-entity", element: <Entity /> },
  {
    path: "/form-master-entity",
    element: <EntityCreateForm />,
  },
  {
    path: "/form-master-entity-update",
    element: <EntityUpdateForm />,
  },
  { path: "/master-department", element: <Departments /> },
  {
    path: "/form-master-department",
    element: <DepartmentsCreateForm />,
  },
  {
    path: "/form-master-department-update",
    element: <DepartmentsUpdateForm />,
  },
  { path: "/master-division", element: <Divisions /> },
  {
    path: "/form-master-division",
    element: <DivisionsCreateForm />,
  },
  {
    path: "/form-master-division-update",
    element: <DivisionsUpdateForm />,
  },
  { path: "/master-location", element: <Locations /> },
  {
    path: "/form-master-location",
    element: <LocationsCreateForm />,
  },
  {
    path: "/form-master-location-update",
    element: <LocationsUpdateForm />,
  },
  { path: "/master-type", element: <Providers /> },
  {
    path: "/form-master-type",
    element: <ProvidersCreateForm />,
  },
  {
    path: "/form-master-type-update",
    element: <ProvidersUpdateForm />,
  },
  { path: "/profile", element: <ProfileUpdateForm /> },
  {
    path: "/transaction-history",
    element: <TransactionHistory />,
  },
  {
    path: "/log-master-data",
    element: <LogMasterData />,
  },
  {
    path: "/log-transaction",
    element: <LogTransaction />,
  },
  {
    path: "/log-auth",
    element: <LogAuth />,
  },

  {
    path: "/role-permission",
    element: <RolePermission />,
  },
  {
    path: "/form-role",
    element: <RoleCreateForm />,
  },
  {
    path: "/form-role-update",
    element: <RoleUpdateForm />,
  },
  {
    path: "/form-permission",
    element: <PermissionCreateForm />,
  },
  {
    path: "/master-section",
    element: <Section />,
  },
  {
    path: "/form-master-section",
    element: <SectionCreateForm />,
  },
  {
    path: "/form-master-section-update",
    element: <SectionUpdateForm />,
  },
  {
    path: "/issued-transaction",
    element: <IssuedTransaction />,
  },
  {
    path: "/validation",
    element: <Validations />,
  },
  { path: "*", element: <PageNotFound /> },
];

const RoutesBar = () => {
  return (
    <>
      <Router>
        <Routes>
          {routes.map((route, index) => (
            <Route
              key={index}
              path={route.path}
              exact
              element={route.element}
            />
          ))}
        </Routes>
      </Router>
    </>
  );
};

export default RoutesBar;
