import axios from "axios";

const getAllDepartment = async (take = 10, page = 1, deptName = "") => {
  const storedData = localStorage.getItem("userData");
  const userData = JSON.parse(storedData);
  const token = !userData ? "" : userData.token;

  const department = await axios({
    method: "get",
    url: `${process.env.REACT_APP_URL_TCM}/api/departemen?take=${take}&page=${page}&deptName=${deptName}`,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return department;
};

const newDepartmentService = async (form) => {
  const json = JSON.stringify(form);
  const storedData = localStorage.getItem("userData");
  const userData = JSON.parse(storedData);
  const token = !userData ? "" : userData.token;

  const department = await axios.post(
    `${process.env.REACT_APP_URL_TCM}/api/departemen/new`,
    json,
    {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return department;
};

const deleteDepartmentService = async (id) => {
  const storedData = localStorage.getItem("userData");
  const userData = JSON.parse(storedData);
  const token = !userData ? "" : userData.token;

  const department = await axios.delete(
    `${process.env.REACT_APP_URL_TCM}/api/departemen/${id}`,
    {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return department;
};

const updateDepartmentService = async (form, id) => {
  const json = JSON.stringify(form);
  const storedData = localStorage.getItem("userData");
  const userData = JSON.parse(storedData);
  const token = !userData ? "" : userData.token;

  const department = await axios.put(
    `${process.env.REACT_APP_URL_TCM}/api/departemen/update/${id}`,
    json,
    {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return department;
};

const getDeptByDivService = async (id) => {
  const storedData = localStorage.getItem("userData");
  const userData = JSON.parse(storedData);
  const token = !userData ? "" : userData.token;

  const division = await axios({
    method: "get",
    url: `${process.env.REACT_APP_URL_TCM}/api/departemen/byDiv/${id}`,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return division;
};

export {
  getAllDepartment,
  newDepartmentService,
  deleteDepartmentService,
  updateDepartmentService,
  getDeptByDivService,
};
