import { useEffect, useState } from "react";
import {
  getAllCompany,
  updateCostCenterService,
} from "../../../../services/apiService/apiSetup/masterCostCenter/ServiceApiCostCenter";
import Swal from "sweetalert2";
import { useLocation, useNavigate } from "react-router-dom";

export const LogicUpdate = () => {
  const navigation = useNavigate();
  const location = useLocation();

  const storedData = localStorage.getItem("userData");
  const userData = JSON.parse(storedData);

  const [dataCostCenter, setDataCostCenter] = useState({
    costCenterCode: location.state.costCenterCode,
    costCenterName: location.state.costCenterName,
    section: location.state.section ? location.state.section.id : "",
    desc: location.state.desc,
    isActive: location.state.isActive,
    updatedBy: userData.id,
    costCenterEntityId: location.state.costCenterEntityId
      ? location.state.costCenterEntityId.id
      : "",
    glDebit: location.state.glDebit,
    glCredit: location.state.glCredit ? location.state.glCredit : "",
  });
  const [isError, setIsError] = useState("");
  const [isErrorCode, setIsErrorCode] = useState("");
  const [dataCompany, setDataCompany] = useState([]);
  const [hideAlert, setHideAlert] = useState(false);
  const [disableBtn, setDisableBtn] = useState(false);

  const handleChange = (event) => {
    setDataCostCenter({
      ...dataCostCenter,
      [event.target.name]: event.target.value,
    });
  };

  const handleChangeCode = (event) => {
    setDataCostCenter({
      ...dataCostCenter,
      costCenterCode: event.target.value,
    });

    if (dataCostCenter.costCenterCode.length >= 10) {
      setIsErrorCode("Input code with maximum 10 characters");
    } else {
      setIsErrorCode("");
    }
  };

  const handleChangeCheckbox = (event) => {
    setDataCostCenter({
      ...dataCostCenter,
      isActive: event.target.checked,
    });
  };

  const updateCostCenter = (
    {
      costCenterCode,
      costCenterName,
      section,
      desc,
      isActive,
      updatedBy,
      costCenterEntityId,
      glDebit,
      glCredit,
    },
    id
  ) => {
    updateCostCenterService(
      {
        costCenterCode:
          dataCostCenter.costCenterCode === ""
            ? costCenterCode
            : dataCostCenter.costCenterCode,
        costCenterName:
          dataCostCenter.costCenterName === ""
            ? costCenterName
            : dataCostCenter.costCenterName,
        section:
          dataCostCenter.section === "" ? section : dataCostCenter.section,
        desc: dataCostCenter.desc === "" ? desc : dataCostCenter.desc,
        isActive:
          dataCostCenter.isActive === "" ? isActive : dataCostCenter.isActive,
        updatedBy:
          dataCostCenter.updatedBy === ""
            ? updatedBy
            : dataCostCenter.updatedBy,
        costCenterEntityId:
          dataCostCenter.costCenterEntityId === ""
            ? costCenterEntityId
            : dataCostCenter.costCenterEntityId,
        glDebit:
          dataCostCenter.glDebit === "" ? glDebit : dataCostCenter.glDebit,
        glCredit:
          dataCostCenter.glCredit === "" ? glCredit : dataCostCenter.glCredit,
      },
      id
    )
      .then(() => {
        Swal.fire("Data updated successfully", "", "success");
        navigation("/cost-center");
      })
      .catch((res) => {
        if (res.response.data.statusCode === 422) {
          setIsError(res.response.data.message);
          setHideAlert(true);
        } else {
          setIsError("Data was not updated successfully");
          setHideAlert(true);
        }
      });
  };

  useEffect(() => {
    getAllCompany()
      .then((res) => {
        const result = res.data;
        setDataCompany(result);
      })

      .catch(() => {});

    if (
      dataCostCenter.costCenterCode === "" ||
      dataCostCenter.costCenterName === "" ||
      dataCostCenter.glDebit === "" ||
      dataCostCenter.glCredit === "" ||
      dataCostCenter.costCenterCode.length > 10
    ) {
      setDisableBtn(true);
    } else {
      setDisableBtn(false);
    }
  }, [dataCostCenter]);

  const handleSiteChange = (value) => {
    if (value !== null) {
      setDataCostCenter((dataCostCenter) => ({
        ...dataCostCenter,
        costCenterEntityId: value.id,
        glCredit: value.glCredit,
      }));
    } else {
      setDataCostCenter((dataCostCenter) => ({
        ...dataCostCenter,
        costCenterEntityId: "",
        glCredit: "",
      }));
    }
  };
  return {
    hideAlert,
    isError,
    isErrorCode,
    dataCompany,
    disableBtn,
    location,
    dataCostCenter,
    navigation,
    updateCostCenter,
    handleChange,
    handleChangeCheckbox,
    handleChangeCode,
    handleSiteChange,
  };
};
