import React from "react";

export const PageNotFound = () => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <h1 style={{ fontSize: "100px" }}>404</h1>
      <h1>Page Not Found</h1>
    </div>
  );
};
