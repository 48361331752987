import React from "react";
import Modal from "@mui/material/Modal";
import CancelIcon from "@mui/icons-material/Cancel";
import { Grid, TextField, Typography, Box } from "@mui/material";

const styleModal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "3px solid #e9e7e7",
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
};

export default function ModalDetailTrx(props) {
  const { handleClose, open, dataDetailTrx } = props;

  const handleStatusTrx = (approve, done) => {
    if (approve === false && done === false) {
      return "Pending";
    } else if (approve === false && done === true) {
      return "Rejected";
    } else if (approve === true && done === false) {
      return "Approved";
    } else if (approve === true && done === true) {
      return "Done";
    }
  };

  const fmt = new Intl.DateTimeFormat("en-GB", {
    year: "2-digit",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  });

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ opacity: 1 }}
      >
        <Box sx={styleModal}>
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="flex-start"
          >
            <CancelIcon
              sx={{
                textAlign: "right",
              }}
              onClick={handleClose}
            />
          </Grid>

          <Typography
            variant="h5"
            gutterBottom
            sx={{
              textAlign: "center",
              fontWeight: "bold",
              marginBottom: "20px",
            }}
          >
            Detail Transaction
          </Typography>

          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Typography sx={{ fontSize: "16px", m: 1 }}>Code</Typography>
            </Grid>
            <Grid item xs={6}>
              <TextField
                type="text"
                size="small"
                defaultValue={dataDetailTrx.trx_code}
                disabled
                inputProps={{
                  style: {
                    fontWeight: "bold",
                  },
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <Typography sx={{ fontSize: "16px", m: 1 }}>Status</Typography>
            </Grid>
            <Grid item xs={6}>
              <TextField
                type="text"
                size="small"
                defaultValue={
                  handleStatusTrx(
                    dataDetailTrx.is_approved,
                    dataDetailTrx.is_done
                  ) 
                }
                disabled
                inputProps={{
                  style: {
                    fontWeight: "bold",
                  },
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <Typography sx={{ fontSize: "16px", m: 1 }}>
                Request By
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <TextField
                type="text"
                size="small"
                defaultValue={dataDetailTrx.requester_name}
                disabled
                inputProps={{
                  style: {
                    fontWeight: "bold",
                  },
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <Typography sx={{ fontSize: "16px", m: 1 }}>
                Unit Number
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <TextField
                type="text"
                size="small"
                defaultValue={
                  dataDetailTrx.vehicle_unit_number
                }
                disabled
                inputProps={{
                  style: {
                    fontWeight: "bold",
                  },
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <Typography sx={{ fontSize: "16px", m: 1 }}>Qty (Ltr)</Typography>
            </Grid>
            <Grid item xs={6}>
              <TextField
                type="text"
                size="small"
                defaultValue={dataDetailTrx.actual_qty}
                disabled
                inputProps={{
                  style: {
                    fontWeight: "bold",
                  },
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <Typography sx={{ fontSize: "16px", m: 1 }}>Remark</Typography>
            </Grid>
            <Grid item xs={6}>
              <TextField
                type="text"
                size="small"
                defaultValue={dataDetailTrx.remark}
                disabled
                inputProps={{
                  style: {
                    fontWeight: "bold",
                  },
                }}
                fullWidth
              />
            </Grid>

            <Grid item xs={6}>
              <Typography sx={{ fontSize: "16px", m: 1 }}>
                User Created
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <TextField
                type="text"
                size="small"
                defaultValue={
                  dataDetailTrx.nama
                }
                disabled
                inputProps={{
                  style: {
                    fontWeight: "bold",
                  },
                }}
                fullWidth
              />
            </Grid>

            <Grid item xs={6}>
              <Typography sx={{ fontSize: "16px", m: 1 }}>
                Date Created
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <TextField
                type="text"
                size="small"
                defaultValue={
                  dataDetailTrx.created_at === undefined ||
                  dataDetailTrx.created_at === null
                    ? ""
                    : fmt.format(new Date(dataDetailTrx.created_at))
                }
                disabled
                inputProps={{
                  style: {
                    fontWeight: "bold",
                  },
                }}
                fullWidth
              />
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
}
