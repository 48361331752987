const initialState = {
  dataEntity: [],
};

const GetEntity = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ALL_ENTITY":
      return { ...state, dataEntity: action.data };
    default:
      return state;
  }
};

export default GetEntity;
