import React, { useEffect, useState } from "react";
import {
  Container,
  Box,
  Typography,
  Alert,
  TextField,
  Button,
  Checkbox,
  Autocomplete,
} from "@mui/material";
import { Row, Col } from "react-bootstrap";
import SideBars from "../../../components/SideBars";
import "../../../styles/setup/vehicleStyle/VehicleCreateFormStyle.css";
import { useNavigate } from "react-router-dom";
import { newVehicleService } from "../../../services/apiService/apiSetup/masterVehicle/ServiceApiVehicle";
import { getAllProviders } from "../../../services/apiService/apiSetup/masterProvider/ServiceApiProvider";
import { getAllVehicleType } from "../../../services/apiService/apiSetup/masterVehicleType/ServiceApiVehicleType";
import { getAllEntity } from "../../../services/apiService/apiSetup/masterEntity/ServiceApiEntity";
import { getDivisionByEntityService } from "../../../services/apiService/apiSetup/masterDivision/ServiceApiDivision";
import { getDeptByDivService } from "../../../services/apiService/apiSetup/masterDepartment/ServiceApiDepartment";
import { getSectionByDeptService } from "../../../services/apiService/apiSetup/masterSection/ServiceApiSection";
import Swal from "sweetalert2";

const VehicleCreateForm = () => {
  const navigation = useNavigate();

  const storedData = localStorage.getItem("userData");
  const userData = JSON.parse(storedData);

  const [dataVehicle, setDataVehicle] = useState({
    entity: "",
    division: "",
    department: "",
    vehicleSectionId: "",
    unitNum: "",
    vehicleProviderId: "",
    platNo: "",
    vehicleTypeId: "",
    assetNumber: "",
    isActive: false,
    createdBy: userData.id,
  });
  const [isError, setIsError] = useState("");
  const [hideAlert, setHideAlert] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [dataEntity, setDataEntity] = useState([]);
  const [dataDivision, setDataDivision] = useState([]);
  const [dataDepartment, setDataDepartment] = useState([]);
  const [dataSection, setDataSection] = useState([]);
  const [dataProvider, setDataProvider] = useState([]);
  const [dataVehicleType, setDataVehicleType] = useState([]);

  const page = 1;
  const takePage = 0;

  const handleChange = (event) => {
    setDataVehicle({
      ...dataVehicle,
      [event.target.name]: event.target.value,
    });
  };

  const handleChangeCheckbox = (event) => {
    setDataVehicle({
      ...dataVehicle,
      isActive: event.target.checked,
    });
  };

  useEffect(() => {
    getAllEntity(takePage, page)
      .then((res) => {
        const result = res.data.data;
        setDataEntity(result);
      })
      .catch(() => {});

    getAllProviders(takePage, page)
      .then((res) => {
        const result = res.data.data;
        setDataProvider(result);
      })
      .catch(() => {});

    getAllVehicleType(takePage, page)
      .then((res) => {
        const result = res.data.data;
        setDataVehicleType(result);
      })
      .catch(() => {});

    if (
      dataVehicle.entity === "" ||
      dataVehicle.division === "" ||
      dataVehicle.department === "" ||
      dataVehicle.vehicleSectionId === "" ||
      dataVehicle.unitNum === "" ||
      dataVehicle.vehicleProviderId === "" ||
      dataVehicle.platNo === "" ||
      dataVehicle.vehicleTypeId === "" ||
      dataVehicle.assetNumber === ""
    ) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [dataVehicle]);

  const getDivByEntity = (id) => {
    getDivisionByEntityService(id)
      .then((res) => {
        const result = res.data;
        setDataDivision(result);
      })
      .catch((res) => {
        if (res.request.status === 404) {
          setIsError("There are no divisions under the entity");
          setHideAlert(!hideAlert);
        }
      });
  };

  const getDeptByDiv = (id) => {
    getDeptByDivService(id)
      .then((res) => {
        const result = res.data;
        setDataDepartment(result);
      })
      .catch((res) => {
        if (res.request.status === 404) {
          setIsError("There are no department under the division");
          setHideAlert(!hideAlert);
        }
      });
  };

  const getSectionByDept = (id) => {
    getSectionByDeptService(id)
      .then((res) => {
        const result = res.data;
        setDataSection(result);
      })
      .catch((res) => {
        if (res.request.status === 404) {
          setIsError("There are no section under the department");
          setHideAlert(!hideAlert);
        }
      });
  };

  const createNewVehicle = () => {
    newVehicleService({
      vehicleSectionId: dataVehicle.vehicleSectionId,
      unitNum: dataVehicle.unitNum,
      vehicleProviderId: dataVehicle.vehicleProviderId,
      platNo: dataVehicle.platNo,
      vehicleTypeId: dataVehicle.vehicleTypeId,
      assetNumber: dataVehicle.assetNumber,
      isActive: dataVehicle.isActive,
      createdBy: dataVehicle.createdBy,
    })
      .then(() => {
        Swal.fire("Successfully added data", "", "success");
        navigation("/master-vehicle");
      })
      .catch((res) => {
        if (res.response.data.statusCode === 422) {
          setIsError(res.response.data.message);
          setHideAlert(true);
        } else {
          setIsError("Failed to add data");
          setHideAlert(true);
        }
      });
  };

  const handleEntityChange = (value) => {
    if (value !== null) {
      getDivByEntity(value.id);
      setDataVehicle((dataVehicle) => ({
        ...dataVehicle,
        entity: value.id,
      }));
    } else {
      setDataVehicle((dataVehicle) => ({
        ...dataVehicle,
        entity: "",
      }));
      setDataDivision([]);
      setDataDepartment([]);
      setDataSection([]);
    }
  };

  const handleDivChange = (value) => {
    if (value !== null) {
      getDeptByDiv(value.id);
      setDataVehicle((dataVehicle) => ({
        ...dataVehicle,
        division: value.id,
      }));
    } else {
      setDataVehicle((dataVehicle) => ({
        ...dataVehicle,
        division: "",
      }));
      setDataDepartment([]);
      setDataSection([]);
    }
  };

  const handleDeptChange = (value) => {
    if (value !== null) {
      getSectionByDept(value.id);
      setDataVehicle((dataVehicle) => ({
        ...dataVehicle,
        department: value.id,
      }));
    } else {
      setDataVehicle((dataVehicle) => ({
        ...dataVehicle,
        department: "",
      }));
      setDataSection([]);
    }
  };

  const handleSectionChange = (value) => {
    if (value !== null) {
      setDataVehicle((dataVehicle) => ({
        ...dataVehicle,
        vehicleSectionId: value.id,
      }));
    } else {
      setDataVehicle((dataVehicle) => ({
        ...dataVehicle,
        vehicleSectionId: "",
      }));
    }
  };

  const handleProviderChange = (value) => {
    if (value !== null) {
      setDataVehicle((dataVehicle) => ({
        ...dataVehicle,
        vehicleProviderId: value.id,
      }));
    } else {
      setDataVehicle((dataVehicle) => ({
        ...dataVehicle,
        vehicleProviderId: "",
      }));
    }
  };

  const handleVehicleTypeChange = (value) => {
    if (value !== null) {
      setDataVehicle((dataVehicle) => ({
        ...dataVehicle,
        vehicleTypeId: value.id,
      }));
    } else {
      setDataVehicle((dataVehicle) => ({
        ...dataVehicle,
        vehicleTypeId: "",
      }));
    }
  };

  return (
    <>
      <Box>
        <SideBars />

        <Container maxWidth="lg" sx={{ marginBottom: "150px" }}>
          <h4 className="title-page-vf">Form Master Vehicle</h4>
          {hideAlert && <Alert severity="error">{isError}</Alert>}
          <Box className="box-vehicle-form">
            <Box align="left">
              <Row>
                <Col sm={6}>
                  <Typography sx={{ fontSize: "16px", m: 1 }}>
                    Entity <span style={{ color: "red" }}>*</span>
                  </Typography>
                </Col>
                <Col sm={6}>
                  <Autocomplete
                    disablePortal
                    options={dataEntity}
                    getOptionLabel={(dataEntity) =>
                      dataEntity.name === undefined ? "" : dataEntity.name
                    }
                    onChange={(_, value) => handleEntityChange(value)}
                    size="small"
                    fullWidth
                    renderInput={(params) => <TextField {...params} />}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                  />
                </Col>
              </Row>

              <br />

              <Row>
                <Col sm={6}>
                  <Typography sx={{ fontSize: "16px", m: 1 }}>
                    Division <span style={{ color: "red" }}>*</span>
                  </Typography>
                </Col>
                <Col sm={6}>
                  <Autocomplete
                    disablePortal
                    options={dataDivision}
                    getOptionLabel={(dataDivision) =>
                      dataDivision.divisiNama === undefined
                        ? ""
                        : dataDivision.divisiNama
                    }
                    onChange={(_, value) => handleDivChange(value)}
                    size="small"
                    fullWidth
                    renderInput={(params) => <TextField {...params} />}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                  />
                </Col>
              </Row>

              <br />

              <Row>
                <Col sm={6}>
                  <Typography sx={{ fontSize: "16px", m: 1 }}>
                    Department <span style={{ color: "red" }}>*</span>
                  </Typography>
                </Col>
                <Col sm={6}>
                  <Autocomplete
                    disablePortal
                    options={dataDepartment}
                    getOptionLabel={(dataDepartment) =>
                      dataDepartment.deptName === undefined
                        ? ""
                        : dataDepartment.deptName
                    }
                    onChange={(_, value) => handleDeptChange(value)}
                    size="small"
                    fullWidth
                    renderInput={(params) => <TextField {...params} />}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                  />
                </Col>
              </Row>

              <br />

              <Row>
                <Col sm={6}>
                  <Typography sx={{ fontSize: "16px", m: 1 }}>
                    Section <span style={{ color: "red" }}>*</span>
                  </Typography>
                </Col>
                <Col sm={6}>
                  <Autocomplete
                    disablePortal
                    options={dataSection}
                    getOptionLabel={(dataSection) =>
                      dataSection.sectionName === undefined
                        ? ""
                        : dataSection.sectionName
                    }
                    onChange={(_, value) => handleSectionChange(value)}
                    size="small"
                    fullWidth
                    renderInput={(params) => <TextField {...params} />}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                  />
                </Col>
              </Row>

              <br />

              <Row>
                <Col sm={6}>
                  <Typography sx={{ fontSize: "16px", m: 1 }}>
                    Unit Number <span style={{ color: "red" }}>*</span>
                  </Typography>
                </Col>
                <Col sm={6}>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    sx={{ width: "100%" }}
                    size="small"
                    name="unitNum"
                    onChange={handleChange}
                  />
                </Col>
              </Row>

              <br />

              <Row>
                <Col sm={6}>
                  <Typography sx={{ fontSize: "16px", m: 1 }}>
                    Type <span style={{ color: "red" }}>*</span>
                  </Typography>
                </Col>
                <Col sm={6}>
                  <Autocomplete
                    disablePortal
                    options={dataProvider}
                    getOptionLabel={(dataProvider) =>
                      dataProvider.providerName === undefined
                        ? ""
                        : dataProvider.providerName
                    }
                    onChange={(_, value) => handleProviderChange(value)}
                    size="small"
                    fullWidth
                    renderInput={(params) => <TextField {...params} />}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                  />
                </Col>
              </Row>

              <br />

              <Row>
                <Col sm={6}>
                  <Typography sx={{ fontSize: "16px", m: 1 }}>
                    Plate Number <span style={{ color: "red" }}>*</span>
                  </Typography>
                </Col>
                <Col sm={6}>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    sx={{ width: "100%" }}
                    size="small"
                    name="platNo"
                    onChange={handleChange}
                  />
                </Col>
              </Row>

              <br />

              <Row>
                <Col sm={6}>
                  <Typography sx={{ fontSize: "16px", m: 1 }}>
                    Type of Unit <span style={{ color: "red" }}>*</span>
                  </Typography>
                </Col>
                <Col sm={6}>
                  <Autocomplete
                    disablePortal
                    options={dataVehicleType}
                    getOptionLabel={(dataVehicleType) =>
                      dataVehicleType.vehicleTypeName === undefined
                        ? ""
                        : dataVehicleType.vehicleTypeName
                    }
                    onChange={(_, value) => handleVehicleTypeChange(value)}
                    size="small"
                    fullWidth
                    renderInput={(params) => <TextField {...params} />}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                  />
                </Col>
              </Row>

              <br />

              <Row>
                <Col sm={6}>
                  <Typography sx={{ fontSize: "16px", m: 1 }}>
                    Asset Number
                  </Typography>
                </Col>
                <Col sm={6}>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    sx={{ width: "100%" }}
                    size="small"
                    name="assetNumber"
                    onChange={handleChange}
                  />
                </Col>
              </Row>

              <br />

              <Row>
                <Col>
                  <Typography sx={{ fontSize: "16px", m: 1 }}>
                    Active
                  </Typography>
                </Col>
                <Col>
                  <Checkbox
                    sx={{ marginLeft: "-12px" }}
                    name="isActive"
                    onChange={handleChangeCheckbox}
                  />
                </Col>
              </Row>
            </Box>

            <br />

            <Box align="right">
              <Row>
                <Col>
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: "#436FB1",
                      marginTop: "10px",
                    }}
                    onClick={() => navigation("/master-vehicle")}
                  >
                    Back To List
                  </Button>

                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: "#084298",
                      marginTop: "10px",
                      marginLeft: "10px",
                    }}
                    disabled={disabled ? true : false}
                    onClick={() => createNewVehicle()}
                  >
                    Save
                  </Button>
                </Col>
              </Row>
            </Box>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default VehicleCreateForm;
