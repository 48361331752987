import React from "react";
import SideBars from "../../../components/SideBars";
import "../../../styles/setup/menusStyle/MenusStyle.css";
import {
  Container,
  Grid,
  Typography,
  Box,
  Button,
  TextField,
} from "@mui/material";
import PaginationMenus from "./components/PaginationMenus";
import TableMenus from "./components/TableMenus";
import { MenuLogic } from "./logic/MenusLogic";

const Menus = () => {
  const {    menuName,
    menuChild,
    takePage,
    page,
    dataAllMenu,
    open,
    navigation,
    handleChangeSearch,
    handleSearch,
    handleBtnChild,
    handleChangePage,
    handleChangeTakePage,
    deleteMenus, } = MenuLogic();

  if (!dataAllMenu.data) {
    return null;
  }

  return (
    <>
      <Box>
        <SideBars />
        <Container maxWidth="xl" sx={{ marginBottom: "150px" }}>
          <h3 className="title-page-username">Master Menu</h3>
          <Box
            sx={{
              padding: "30px",
              borderRadius: "10px",
              border: "3px solid #e9e7e7",
            }}
          >
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={2}
            >
              <Grid>
                <Typography
                  sx={{ fontSize: "16px", paddingTop: 1, marginRight: "10px" }}
                >
                  Search by Name Menu
                </Typography>
              </Grid>
              <Grid>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  size="small"
                  sx={{ width: "300px" }}
                  onChange={handleChangeSearch}
                />
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={2}
              sx={{ marginTop: "10px" }}
            >
              <Grid>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "#6EA8FE",
                    minWidth: "200px",
                    fontSize: "16px",
                    textTransform: "none",
                  }}
                  size="small"
                  onClick={() => handleSearch(menuName)}
                >
                  Find
                </Button>
              </Grid>
            </Grid>
          </Box>

          <Box align="right" className="box-new-username">
            <Button
              variant="contained"
              size="small"
              sx={{
                backgroundColor: "#084298",
                minWidth: "200px",
                marginBottom: "10px",
                fontSize: "16px",
                textTransform: "none",
              }}
              onClick={() => navigation("/form-master-menu")}
            >
              New Menu
            </Button>
          </Box>

          <Box>
            <TableMenus
              deleteMenus={deleteMenus}
              dataAllMenu={dataAllMenu}
              handleBtnChild={handleBtnChild}
              open={open}
              takePage={takePage}
              page={page}
              menuChild={menuChild}
            />
          </Box>

          <br />

          <Box sx={{ display: "flex", flexWrap: "wrap" }}>
            <PaginationMenus
              handleChangeTakePage={handleChangeTakePage}
              handleChangePage={handleChangePage}
              dataAllMenu={dataAllMenu}
              takePage={takePage}
              page={page}
            />
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default Menus;
