const initialState = {
  dataCostCenter: [],
};

const GetCostCenters = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ALL_COST_CENTER":
      return { ...state, dataCostCenter: action.data };

    default:
      return state;
  }
};

export default GetCostCenters;
