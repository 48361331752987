import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import SideBars from "../../../components/SideBars";
import "../../../styles/setup/entityStyle/EntityStyle.css";
import {
  Container,
  Grid,
  Typography,
  TextField,
  Button,
  Box,
} from "@mui/material";
import {
  getAllEntity,
  deleteEntityService,
} from "../../../services/apiService/apiSetup/masterEntity/ServiceApiEntity";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import PaginationEntity from "./components/PaginationEntity";
import TableEntity from "./components/TableEntity";

const Entity = (props) => {
  const { GetEntity, dataEntity } = props;
  const navigation = useNavigate();

  const [takePage, setTakePage] = useState(10);
  const [page, setPage] = useState(1);
  const [searchName, setSearchName] = useState("");

  const handleChangePage = (_, v) => {
    setPage(v);
  };

  const handleChangeTakePage = (e) => {
    setTakePage(e.target.value);
  };

  const handleChangeSearch = (e) => {
    setSearchName(e.target.value);
  };

  const handleSearch = (entityName) => {
    setSearchName(entityName);
    loadData();
  };

  const loadData = useCallback(() => {
    getAllEntity(takePage, page, searchName)
      .then((res) => {
        const result = res.data;
        GetEntity(result);
        if (res.data.data.length === 0) {
          setPage(1);
        }
      })
      .catch((res) => {
        if (res.request.status === 401) {
          navigation("/");
        }
      });
  }, [takePage, page, searchName, GetEntity, navigation]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const deleteEntity = (id) => {
    Swal.fire({
      title: "Are you sure to deleted ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#084298",
      cancelButtonColor: "#d33",
      confirmButtonText: "Delete",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteEntityService(id)
          .then(() => {
            loadData();
            Swal.fire("", "Data successfully deleted", "success");
          })
          .catch(() => {
            Swal.fire({
              icon: "error",
              title: "",
              text: "Data was not successfully deleted",
            });
          });
      }
    });
  };

  if (!dataEntity.data) {
    return null;
  }

  return (
    <>
      <Box>
        <SideBars />
        <Container maxWidth="xl" sx={{ marginBottom: "150px" }}>
          <h3 className="title-page-entity">Master Entity</h3>
          <Box
            sx={{
              padding: "30px",
              borderRadius: "10px",
              border: "3px solid #e9e7e7",
            }}
          >
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={2}
            >
              <Grid>
                <Typography
                  sx={{ fontSize: "16px", paddingTop: 1, marginRight: "10px" }}
                >
                  Search by Entity Name
                </Typography>
              </Grid>
              <Grid>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  size="small"
                  sx={{ width: "300px" }}
                  onChange={handleChangeSearch}
                />
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={2}
              sx={{ marginTop: "10px" }}
            >
              <Grid>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "#6EA8FE",
                    minWidth: "200px",
                    fontSize: "16px",
                    textTransform: "none",
                  }}
                  size="small"
                  onClick={() => handleSearch(searchName)}
                >
                  Find
                </Button>
              </Grid>
            </Grid>
          </Box>

          <Box align="right" className="box-new-entity">
            <Button
              variant="contained"
              size="small"
              sx={{
                backgroundColor: "#084298",
                minWidth: "200px",
                marginBottom: "10px",
                fontSize: "16px",
                textTransform: "none",
              }}
              onClick={() => navigation("/form-master-entity")}
            >
              New Entity
            </Button>
          </Box>

          <Box>
            <TableEntity
              deleteEntity={deleteEntity}
              dataEntity={dataEntity}
              takePage={takePage}
              page={page}
            />
          </Box>

          <Box sx={{ display: "flex", flexWrap: "wrap", marginTop: "20px" }}>
            <PaginationEntity
              handleChangeTakePage={handleChangeTakePage}
              handleChangePage={handleChangePage}
              dataEntity={dataEntity}
              takePage={takePage}
              page={page}
            />
          </Box>
        </Container>
      </Box>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    dataEntity: state.rGetAllEntity.GetEntity.dataEntity,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    GetEntity: (result) => dispatch({ type: "GET_ALL_ENTITY", data: result }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Entity);
