import React, { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import SideBars from "../../components/SideBars";
import "../../styles/rolePermission/RolePermissionStyle.css";
import {
  Container,
  Box,
  Button,
} from "@mui/material";
import {
  getAllRole,
} from "../../services/apiService/apiRolePermission/ServiceRolePermission";
// import PaginationRolePermission from "./components/PaginationRolePermission";
import TableRolePermission from "./components/TableRolePermission";

const RolePermission = () => {

  const navigation = useNavigate();

  const takePage = 10;
  const [page, setPage] = useState(1);
  const [dataRole, setDataRole] = useState([]);

  const loadData = useCallback(() => {
    getAllRole(takePage, page)
      .then((res) => {
        const result = res.data;
        setDataRole(result);
        if (res.data.length === 0) {
          setPage(1);
        }
      })
      .catch((res) => {
        // if (res.request.status === 401) {
        //   navigation("/");
        // }
      });
  }, [takePage, page]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const deleteUser = (id) => {
    // Swal.fire({
    //   title: "Are you sure to deleted ?",
    //   icon: "warning",
    //   showCancelButton: true,
    //   confirmButtonColor: "#084298",
    //   cancelButtonColor: "#d33",
    //   confirmButtonText: "Delete",
    // }).then((result) => {
    //   if (result.isConfirmed) {
    //     deleteUserService(id)
    //       .then(() => {
    //         Swal.fire("", "Data successfully deleted", "success");
    //         loadData();
    //       })
    //       .catch(() => {
    //         Swal.fire({
    //           icon: "error",
    //           title: "",
    //           text: "Data was not successfully deleted",
    //         });
    //       });
    //   }
    // });
  };

  if (!dataRole) {
    return null;
  }

  return (
    <>
      <Box>
        <SideBars />
        <Container maxWidth="xl" sx={{ marginBottom: "150px" }}>
          <h3 className="title-page-username">Role & Permissions</h3>
        
          <Box align="right" className="box-new-username">
            <Button
              variant="contained"
              size="small"
              sx={{
                backgroundColor: "#084298",
                minWidth: "200px",
                marginBottom: "10px",
                fontSize: "16px",
                textTransform: "none",
              }}
              onClick={() => navigation("/form-role")}
            >
              New Role
            </Button>
          </Box>

          <Box>
            <TableRolePermission
              deleteUser={deleteUser}
              dataRole={dataRole}
              takePage={takePage}
              page={page}
            />
          </Box>

          <br />

          <Box sx={{ display: "flex", flexWrap: "wrap" }}>
            {/* <PaginationRolePermission
              handleChangeTakePage={handleChangeTakePage}
              handleChangePage={handleChangePage}
              dataAllUsers={dataAllUsers}
              takePage={takePage}
              page={page}
            /> */}
          </Box>
        </Container>
      </Box>
    </>
  );
};


export default RolePermission
