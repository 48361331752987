import axios from "axios";

const getAllLocation = async (take = 10, page = 1, lokasiNama = "") => {
  const storedData = localStorage.getItem("userData");
  const userData = JSON.parse(storedData);
  const token = !userData ? "" : userData.token;

  const location = await axios({
    method: "get",
    url: `${process.env.REACT_APP_URL_TCM}/api/lokasi?take=${take}&page=${page}&lokasiNama=${lokasiNama}`,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return location;
};

const newLocationService = async (form) => {
  const json = JSON.stringify(form);
  const storedData = localStorage.getItem("userData");
  const userData = JSON.parse(storedData);
  const token = !userData ? "" : userData.token;

  const location = await axios.post(
    `${process.env.REACT_APP_URL_TCM}/api/lokasi/new`,
    json,
    {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return location;
};

const deleteLocationService = async (id) => {
  const storedData = localStorage.getItem("userData");
  const userData = JSON.parse(storedData);
  const token = !userData ? "" : userData.token;

  const location = await axios.delete(
    `${process.env.REACT_APP_URL_TCM}/api/lokasi/${id}`,
    {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return location;
};

const updateLocationService = async (form, id) => {
  const json = JSON.stringify(form);
  const storedData = localStorage.getItem("userData");
  const userData = JSON.parse(storedData);
  const token = !userData ? "" : userData.token;

  const location = await axios.put(
    `${process.env.REACT_APP_URL_TCM}/api/lokasi/update/${id}`,
    json,
    {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return location;
};

export {
  getAllLocation,
  newLocationService,
  deleteLocationService,
  updateLocationService,
};
