import axios from "axios";

const getAllValidation = async (limit = 10, page = 1, trxNum = "") => {
  const storedData = localStorage.getItem("userData");
  const userData = JSON.parse(storedData);
  const token = !userData ? "" : userData.token;

  const request = await axios({
    method: "get",
    url: `${process.env.REACT_APP_URL_TCM}/api/ebbm-request/dataFilters?limit=${limit}&page=${page}&TrxNum=${trxNum}&isApproved=false&isDone=false`,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return request;
};

export {
  getAllValidation
};
