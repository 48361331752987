const initialState = {
  allDataProviders: [],
};

const GetProviders = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ALL_PROVIDERS":
      return { ...state, allDataProviders: action.data };

    default:
      return state;
  }
};

export default GetProviders;
