import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import {
  getVehicleAllSection,
  getVehicleBySectionService,
} from "../../../../services/apiService/apiSetup/masterVehicle/ServiceApiVehicle";
import {
  getCostCenterBySectionUserService,
  getDeptByDivUserService,
  getDivByEntityUserService,
  getEntityUserService,
  getSectionByDeptUserService,
  newRequestService,
  sendNotifReqService,
} from "../../../../services/apiService/apiTransaction/fuelingRequest/ServiceApiFuelingRequest";

export const FuelingReqCreateLogic = () => {
  const navigation = useNavigate();

  const storedData = localStorage.getItem("userData");
  const userData = JSON.parse(storedData);

  const [dataVehicle, setDataVehicle] = useState([]);
  const [dataCostCenter, setDataCostCenter] = useState([]);
  const [dataEntity, setDataEntity] = useState([]);
  const [dataDivision, setDataDivision] = useState([]);
  const [dataDepartment, setDataDepartment] = useState([]);
  const [dataSection, setDataSection] = useState([]);
  const [isError, setIsError] = useState("");
  const [hideAlert, setHideAlert] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [allUnit, setAllUnit] = useState(false);
  const [unitNum, setUnitNum] = useState("");
  const [open, setOpen] = useState(true);
  const [dataTransaction, setDataTransaction] = useState({
    entityId: "",
    divId: "",
    deptId: "",
    sectionId: "",
    vehicleId: "",
    costCenterId: "",
    requesterName: "",
    requestQty: 0,
    requestMileage: 0,
    remark: "",
    createdBy: userData.id,
  });

  const handleChange = (event) => {
    setDataTransaction({
      ...dataTransaction,
      [event.target.name]: event.target.value,
    });
  };

  const handleChangeCheckbox = (event) => {
    setAllUnit(event.target.checked);
  };

  const getVehicleBySection = useCallback(
    (id) => {
      if (!allUnit && id) {
        getVehicleBySectionService(id)
          .then((res) => {
            const result = res.data;
            if (result.length > 0) {
              const defaultVehicle = result[0];
              setUnitNum(defaultVehicle.unitNum);
              setDataTransaction((prevData) => ({
                ...prevData,
                vehicleId: defaultVehicle.id,
              }));
            }
            setDataVehicle(result);
            setOpen(false);
          })
          .catch(() => {
            toast.error("There is an error", {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            setOpen(false);
          });
      }
    },
    [allUnit]
  );

  useEffect(() => {
    if (allUnit) {
      getVehicleAllSection()
        .then((res) => {
          const result = res.data;
          if (result.length > 0) {
            const defaultVehicle = result[0];
            setUnitNum(defaultVehicle.unitNum);
            setDataTransaction((prevData) => ({
              ...prevData,
              vehicleId: defaultVehicle.id,
            }));
          }
          setDataVehicle(result);
          setOpen(false);
        })
        .catch(() => {
          toast.error("There is an error", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          setOpen(false);
        });
    } else {
      getVehicleBySection(dataTransaction.sectionId);
    }
  }, [allUnit, dataTransaction.sectionId, getVehicleBySection]);

  const getCostCenterBySection = useCallback((id) => {
    getCostCenterBySectionUserService(id)
      .then((res) => {
        const result = res.data;
        if (result.length > 0) {
          const defaultCostCenter = result[0];
          setDataCostCenter(defaultCostCenter.id);
          setDataTransaction((prevData) => ({
            ...prevData,
            costCenterId: defaultCostCenter.id,
          }));
        }
        setDataCostCenter(result);
        setOpen(false);
      })
      .catch(() => {
        toast.error("There is an error", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setOpen(false);
      });
  }, []);

  const getSectionByDeptUser = useCallback(
    (id) => {
      getSectionByDeptUserService(id)
        .then((res) => {
          const result = res.data;
          if (result.length > 0) {
            const defaultSection = result[0];
            setDataTransaction((prevData) => ({
              ...prevData,
              sectionId: defaultSection.section_id,
            }));
            getCostCenterBySection(defaultSection.section_id);
            getVehicleBySection(defaultSection.section_id);
          } else {
            setDataVehicle([]);
          }
          setDataSection(result);
        })
        .catch(() => {
          toast.error("There is an error", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          setOpen(false);
        });
    },
    [getCostCenterBySection, getVehicleBySection]
  );

  const getDeptByDivUser = useCallback(
    (id) => {
      getDeptByDivUserService(id)
        .then((res) => {
          const result = res.data;
          if (result.length > 0) {
            const defaultDept = result[0];
            setDataTransaction((prevData) => ({
              ...prevData,
              deptId: defaultDept.departemen_id,
            }));
            getSectionByDeptUser(defaultDept.departemen_id);
          } else {
            setDataVehicle([]);
          }
          setDataDepartment(result);
        })
        .catch(() => {
          toast.error("There is an error", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          setOpen(false);
        });
    },
    [getSectionByDeptUser]
  );

  const getDivByEntityUser = useCallback(
    (id) => {
      getDivByEntityUserService(id)
        .then((res) => {
          const result = res.data;

          if (result.length > 0) {
            const defaultDiv = result[0];

            setDataTransaction((prevData) => ({
              ...prevData,
              divId: defaultDiv.id,
            }));
            getDeptByDivUser(defaultDiv.id);
          } else {
            setDataDepartment([]);
            setDataVehicle([]);
          }

          setDataDivision(result);
        })
        .catch(() => {
          toast.error("There is an error", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          setOpen(false);
        });
    },
    [getDeptByDivUser]
  );

  useEffect(() => {
    getEntityUserService()
      .then((res) => {
        const result = res.data;
        if (result.length > 0) {
          const defaultEntity = result[0];
          if (dataTransaction.entityId === "") {
            setDataTransaction((prevData) => ({
              ...prevData,
              entityId: defaultEntity.id,
            }));
            getDivByEntityUser(defaultEntity.id);
          }
        }
        setDataEntity(result);
      })
      .catch(() => {
        toast.error("There is an error", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setOpen(false);
      });

    if (
      dataTransaction.entityId === "" ||
      dataTransaction.divId === "" ||
      dataTransaction.deptId === "" ||
      dataTransaction.sectionId === "" ||
      dataTransaction.vehicleId === "" ||
      dataTransaction.costCenterId === "" ||
      dataTransaction.requesterName === "" ||
      dataTransaction.requestQty === 0 ||
      dataTransaction.requestMileage === 0
    ) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [dataTransaction, userData.id, getDivByEntityUser]);

  const createNewRequest = () => {
    newRequestService({
      entityId: dataTransaction.entityId,
      divId: dataTransaction.divId,
      deptId: dataTransaction.deptId,
      sectionId: dataTransaction.sectionId,
      vehicleId: dataTransaction.vehicleId,
      costCenterId: dataTransaction.costCenterId,
      requesterName: dataTransaction.requesterName,
      requestQty: dataTransaction.requestQty,
      remark: dataTransaction.remark,
      requestMileage: dataTransaction.requestMileage,
      createdBy: dataTransaction.createdBy,
    })
      .then((res) => {
        sendNotifReqService(res.data.trxCode, unitNum, res.data.sectionId)
          .then(() => {
            Swal.fire("Data added successfully", "", "success");
          })
          .catch(() => {
            Swal.fire({
              icon: "error",
              title: "",
              text: "Failed to send notification",
            });
          });

        navigation("/fueling-request");
      })
      .catch((res) => {
        if (res.response.data.statusCode === 422) {
          setIsError(res.response.data.message);
          setHideAlert(true);
        } else {
          setIsError("Data was not added successfully");
          setHideAlert(true);
        }
      });
  };

  const handleEntityChange = (value) => {
    if (value !== null) {
      getDivByEntityUser(value.id);
      setDataTransaction((prevData) => ({
        ...prevData,
        entityId: value.id,
      }));
    } else {
      setDataTransaction((prevData) => ({
        ...prevData,
        entityId: "",
      }));
      setDataDivision([]);
      setDataDepartment([]);
      setDataSection([]);
      setDataCostCenter([]);
      setDataVehicle([]);
    }
  };

  const handleDivChange = (value) => {
    if (value !== null) {
      getDeptByDivUser(value.id);
      setDataTransaction((dataTransaction) => ({
        ...dataTransaction,
        divId: value.id,
      }));
    } else {
      setDataTransaction((dataTransaction) => ({
        ...dataTransaction,
        divId: "",
      }));
      setDataDepartment([]);
      setDataSection([]);
      setDataCostCenter([]);
      setDataVehicle([]);
    }
  };

  const handleDeptChange = (value) => {
    if (value !== null) {
      getSectionByDeptUser(value.departemen_id);
      setDataTransaction((dataTransaction) => ({
        ...dataTransaction,
        deptId: value.departemen_id,
      }));
    } else {
      setDataTransaction((dataTransaction) => ({
        ...dataTransaction,
        deptId: "",
      }));
      setDataSection([]);
      setDataCostCenter([]);
      setDataVehicle([]);
    }
  };

  const handleSectionChange = (value) => {
    if (value !== null) {
      getVehicleBySection(value.section_id);
      getCostCenterBySection(value.section_id);
      setDataTransaction((dataTransaction) => ({
        ...dataTransaction,
        sectionId: value.section_id,
      }));
    } else {
      setDataTransaction((dataTransaction) => ({
        ...dataTransaction,
        sectionId: "",
      }));
      setDataCostCenter([]);
      setDataVehicle([]);
    }
  };

  const handleCostCenterChange = (value) => {
    if (value !== null) {
      setDataTransaction((dataTransaction) => ({
        ...dataTransaction,
        costCenterId: value.id,
      }));
    } else {
      setDataTransaction((dataTransaction) => ({
        ...dataTransaction,
        costCenterId: "",
      }));
      setDataVehicle([]);
    }
  };

  const handleVehicleChange = (value) => {
    if (value !== null) {
      setDataTransaction((dataTransaction) => ({
        ...dataTransaction,
        vehicleId: value.id,
      }));
      setUnitNum(value.unitNum);
    } else {
      setDataTransaction((dataTransaction) => ({
        ...dataTransaction,
        vehicleId: "",
      }));
    }
  };

  return {
    open,
    isError,
    hideAlert,
    disabled,
    dataCostCenter,
    dataDepartment,
    dataDivision,
    dataEntity,
    dataSection,
    dataTransaction,
    dataVehicle,
    createNewRequest,
    navigation,
    handleChange,
    handleChangeCheckbox,
    handleCostCenterChange,
    handleDeptChange,
    handleDivChange,
    handleEntityChange,
    handleSectionChange,
    handleVehicleChange,
  };
};
