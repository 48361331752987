import { useCallback, useEffect, useState } from "react";
import { getAllLogMasterDataService } from "../../../../services/apiService/apiReport/logMasterData/ServiceApiLogMasterData";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";

export const LogMasterDataLogic = () => {
  const navigation = useNavigate();
  const theme = useTheme();

  const fmt = new Intl.DateTimeFormat("fr-CA", {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  });

  const [currentDate, setCurrentDate] = useState(new Date());
  const [filterApi, setFilterApi] = useState({
    startDate: fmt.format(new Date(currentDate)),
    endDate: fmt.format(new Date(currentDate)),
    action: "",
    createdBy: "",
  });
  const [takePage, setTakePage] = useState(10);
  const [page, setPage] = useState(1);
  const [dataLogMasterData, setLogMasterData] = useState([]);

  const handleChange = (event) => {
    setFilterApi({
      ...filterApi,
      [event.target.name]: event.target.value,
    });
  };

  const handleChangePage = (_, v) => {
    setPage(v);
  };

  const handleChangeTakePage = (e) => {
    setTakePage(e.target.value);
  };

  const loadData = useCallback(() => {
    getAllLogMasterDataService(
      takePage,
      page,
      filterApi.startDate,
      filterApi.endDate,
      filterApi.action,
      filterApi.createdBy
    )
      .then((res) => {
        const result = res.data;
        setLogMasterData(result);
        if (res.data.data.length === 0) {
          setPage(1);
        }
      })
      .catch((res) => {
        if (res.request.status === 401) {
          navigation("/");
        }
      });
  }, [filterApi, takePage, page, navigation]);

  useEffect(() => {
    loadData();
    setCurrentDate(new Date());
  }, [loadData]);

  const handleSearch = (startDate, endDate, action, createdBy) => {
    setFilterApi((filterApi) => ({
      ...filterApi,
      startDate: startDate,
      endDate: endDate,
      action: action,
      createdBy: createdBy,
    }));
  };

  return {
    handleChange,
    handleChangePage,
    handleChangeTakePage,
    handleSearch,
    dataLogMasterData,
    takePage,
    page,
    theme,
    filterApi,
  };
};
