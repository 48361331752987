import React from "react";
import {
  styled,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#8FD0EF",
    color: "#484848",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: "14",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: "#F0F0F0",
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function TableLogMasterData(props) {
  const { dataLogMasterData, takePage, page } = props;

  const fmtDate = new Intl.DateTimeFormat("en-GB", {
    year: "2-digit",
    month: "numeric",
    day: "numeric",
  });

  const fmtTime = new Intl.DateTimeFormat("en-GB", {
    hour: "numeric",
    minute: "numeric",
  });

  return (
    <div>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 900 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell sx={{ fontWeight: "bold" }}>#</StyledTableCell>
              <StyledTableCell sx={{ fontWeight: "bold" }}>
                User
              </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: "bold" }}>
                Role
              </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: "bold" }}>
                Date
              </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: "bold" }}>
                Time
              </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: "bold" }}>
                Action
              </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: "bold" }}>
                Before
              </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: "bold" }}>
                After
              </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: "bold" }}>
                Information
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataLogMasterData.data.map((item, index) => (
              <StyledTableRow key={item.id}>
                <StyledTableCell>
                  {(page - 1) * takePage + index + 1}
                </StyledTableCell>
                <StyledTableCell>{item.createBy.nama}</StyledTableCell>
                <StyledTableCell>{item.createBy.role.roleName}</StyledTableCell>
                <StyledTableCell>
                  {fmtDate.format(new Date(item.created_at))}
                </StyledTableCell>
                <StyledTableCell>
                  {fmtTime.format(new Date(item.created_at))}
                </StyledTableCell>
                <StyledTableCell>{item.perubahan.jenis_data}</StyledTableCell>
                <StyledTableCell>
                  {/* {item.perubahan.changes ? item.perubahan.changes.before : ""} */}
                </StyledTableCell>
                <StyledTableCell>
                  {item.perubahan.changes ? item.perubahan.changes.after : ""}
                </StyledTableCell>
                <StyledTableCell>{item.action}</StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
